import React, { useMemo, useRef, useState } from 'react'
import { useGetHistoryQuery } from '~/api/generated/graphql'
import { Link } from 'react-router-dom'
import { exportHistoryCsv } from '~/api/ServerApi'
import { asString, htmlToText, HtmlWithMentionsValue, UTCtoLocal } from '~/utils'
import ToastComponent from '~/common/ToastComponent'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { Form } from 'react-bootstrap'

const AdminTable = () => {
  const columns = [
    'Object ID',
    'Object type',
    'Name/Title',
    'Community',
    'Modified date',
    'Modified by',
    'Action',
    'Field',
    'Old value',
    'New value',
  ]

  type HistoryRow = {
    table: string
    user: {
      email: string
      firstName: string
      lastName: string
      company: {
        name: string
        companyId: string
      }
      userId: string
      hidden: boolean
    }
    company: {
      name: string
      companyId: string
    }
    community: {
      name: string
      communityId: string
      isHomepage: boolean
    }
    comment: {
      commentId: string
      post: {
        title: HtmlWithMentionsValue
        postId: string
        community: {
          name: string
          communityId: string
        }
      }
    }
    event: {
      title: string
      isRepost: boolean
      community: {
        name: string
        communityId: string
      }
      eventId: string
    }
    post: {
      isRepost: boolean
      title: HtmlWithMentionsValue
      community: {
        name: string
        communityId: string
      }
      postId: string
    }
    release: {
      releaseId: string
      title: string
      isRelease: boolean
    }
    modifiedTime: string
    modifiedBy: {
      firstName: string
      lastName: string
      userId: string
      email: string
      hidden: boolean
    }
    action: string
    field: string
    oldValue: string
    newValue: string
  }

  const date = new Date()
  const [endDT, setEndDT] = useState<string>(date.toISOString())
  const displayEnd = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString()
  // Converts local time w/ UTC offset to get local datetime in ISO format (double conversion)
  // datetime-local input requires a default value in the form YYYY-MM-DDThh:mm:ss

  date.setDate(date.getDate() - 7)
  const [startDT, setStartDT] = useState<string>(date.toISOString())
  const displayStart = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString()
  const [filename, setFileName] = useState<string>('history.xlsx')
  const startRef = useRef<HTMLInputElement>(null)
  const endRef = useRef<HTMLInputElement>(null)
  const filenameRef = useRef<HTMLInputElement>(null)
  const [toast, setToast] = useState('')
  const [showToast, setShowToast] = useState(false)

  const { isCondensed, isCondensedPortrait } = useWindowSize()

  const updateDT = () => {
    setStartDT(new Date(startRef?.current?.value ?? '').toISOString())
    setEndDT(new Date(endRef?.current?.value ?? '').toISOString())
  }
  const filenameChange = () => {
    setFileName(filenameRef?.current?.value ?? '')
  }

  const { data, loading, fetchMore } = useGetHistoryQuery({
    variables: { startDateTime: startDT, endDateTime: endDT },
  })

  const hasMoreRows = data?.history?.pageInfo.hasNextPage ?? false

  const loadMoreRows = () => {
    fetchMore({ variables: { cursor: data?.history?.pageInfo.endCursor } }).then()
  }

  const historyData = useMemo(() => {
    if (!loading && data) {
      return (
        (data?.history?.edges?.map(edge => {
          const node = edge?.node
          return {
            table: node?.table,
            user: {
              firstName: node?.user?.firstName,
              lastName: node?.user?.lastName,
              email: node?.user?.email,
              company: {
                name: node?.user?.company?.name,
                companyId: node?.user?.company?.companyId,
              },
              userId: node?.user?.userId,
              hidden: node?.user?.hidden,
            },
            company: {
              name: node?.company?.name,
              companyId: node?.company?.companyId,
            },
            community: {
              name: node?.community?.name,
              communityId: node?.community?.communityId,
              isHomepage: node?.community?.isHomepage,
            },
            comment: {
              commentId: node?.comment?.commentId,
              post: {
                title: node?.comment?.post?.title,
                postId: node?.comment?.post?.postId,
                community: {
                  name: node?.comment?.post?.community?.name,
                  communityId: node?.comment?.post?.community?.communityId,
                },
              },
            },
            event: {
              title: node?.event?.title,
              isRepost: node?.event?.isRepost,
              community: {
                name: node?.event?.community?.name,
                communityId: node?.event?.community?.communityId,
              },
              eventId: node?.event?.eventId,
            },
            post: {
              isRepost: node?.post?.isRepost,
              title: node?.post?.title,
              community: {
                name: node?.post?.community?.name,
                communityId: node?.post?.community?.communityId,
              },
              postId: node?.post?.postId,
            },
            release: {
              releaseId: node?.release?.releaseId,
              title: node?.release?.title,
              isRelease: node?.release?.isRelease,
            },
            modifiedTime: node?.modifiedTime,
            modifiedBy: {
              firstName: node?.modifiedBy?.firstName,
              lastName: node?.modifiedBy?.lastName,
              userId: node?.modifiedBy?.userId,
              email: node?.modifiedBy?.email,
              hidden: node?.modifiedBy?.hidden,
            },
            action: node?.action,
            field: node?.field,
            oldValue: node?.oldValue,
            newValue: node?.newValue,
          }
        }) as HistoryRow[]) ?? []
      )
    }
  }, [data, loading])

  const fullName = ({ firstName, lastName, email }: { firstName: string; lastName: string; email: string }) =>
    firstName && lastName ? `${firstName} ${lastName}` : email

  const getTableInfo = (row: HistoryRow) => {
    const communityName =
      row?.community.name ??
      row?.comment?.post.community.name ??
      row?.event?.community.name ??
      row?.post?.community.name
    const communityId =
      row?.community.communityId ??
      row.comment.post.community.communityId ??
      row?.event?.community.communityId ??
      row?.post?.community.communityId
    const communityLink = <Link to={`/communities/${communityId}`}>{communityName}</Link>
    const user = row.user
    const companyName = row.company.name ?? user?.company.name
    const companyId = row.company.companyId ?? user?.company.companyId
    const companyLink = <Link to={`/companies/${companyId}/home`}>{companyName}</Link>
    const homepageLink = <Link to={`/companies/${companyId}/home`}>{companyName} Home</Link>
    const post = row?.post?.title ? row.post : row.comment.post
    const userLink = user ? (
      user.firstName && !user.hidden ? (
        <Link to={`/profiles/${user.userId}`}>{fullName(user)}</Link>
      ) : (
        fullName(user)
      )
    ) : (
      ''
    )
    const postLink = post ? (
      <Link to={`/communities/${post.community.communityId}/posts/${post.postId}`}>
        {htmlToText(asString(post.title) ?? '')}
      </Link>
    ) : (
      ''
    )
    const releaseLink = <Link to={`/releases/${row.release.releaseId}`}>{row.release.title}</Link>
    switch (row.table) {
      case 'COMMENT':
        return [<td key={'name'}>{postLink}</td>, <td key={'comm'}>{communityLink}</td>]
      case 'COMMUNITY':
        return [<td key={'name'}>{communityLink}</td>, <td key={'comm'}>{communityLink}</td>]
      case 'COMPANY':
        return [<td key={'name'}>{companyLink}</td>, <td key={'comm'}>{companyLink}</td>]
      case 'EVENT':
        return [
          <td key={'name'}>
            <Link to={`/communities/${communityId}/events/${row.event.eventId}`}>{row.event.title}</Link>
          </td>,
          <td key={'comm'}>{communityLink}</td>,
        ]
      case 'MEMBERSHIP':
        return [<td key={'name'}>{userLink}</td>, <td key={'comm'}>{communityLink}</td>]
      case 'POST_FOLLOW':
        return [<td key={'name'}>{postLink}</td>, <td key={'comm'}>{communityLink}</td>]
      case 'POST':
        return [<td key={'name'}>{postLink}</td>, <td key={'comm'}>{communityLink}</td>]
      case 'USER':
        return [<td key={'name'}>{userLink}</td>, <td key={'comm'}>{companyLink}</td>]
      case 'ROLE':
        return [<td key={'name'}>{userLink}</td>, <td key={'comm'}>{companyLink}</td>]
      case 'DOMAIN':
        return [<td key={'name'}>{homepageLink}</td>, <td key={'comm'}>{homepageLink}</td>]
      case 'ALIAS':
        return [<td key={'name'}>{communityLink}</td>, <td key={'comm'}>{communityLink}</td>]
      case 'RELEASE':
        return [<td key={'name'}>{releaseLink}</td>, <td key={'comm'}></td>]
      case 'CATEGORY':
        return [<td key={'name'}>{releaseLink}</td>, <td key={'comm'}></td>]
      default:
        return [<td key={'name'}></td>, <td key={'comm'}></td>]
    }
  }

  const getValueInfo = (row: HistoryRow) => {
    if (row.table == 'MEMBERSHIP') {
      if (row.action == 'CREATE') {
        return [
          <td key={'field'}>User added</td>,
          <td key={'oldVal'}>{htmlToText(row.oldValue)}</td>,
          <td key={'newVal'}>
            {row.user.firstName && !row.user.hidden ? (
              <Link to={`/profiles/${row.user.userId}`}>{fullName(row.user)}</Link>
            ) : (
              fullName(row.user)
            )}
          </td>,
        ]
      } else if (row.action == 'DELETE') {
        return [
          <td key={'field'}>User removed</td>,
          <td key={'oldVal'}>
            {row.user.firstName && !row.user.hidden ? (
              <Link to={`/profiles/${row.user.userId}`}>{fullName(row.user)}</Link>
            ) : (
              fullName(row.user)
            )}
          </td>,
          <td key={'newVal'}>{htmlToText(row.newValue)}</td>,
        ]
      } else if (row.action == 'UPDATE') {
        if (row.newValue == '1') {
          return [
            row.community.isHomepage ? <td key={'field'}>Account Partner</td> : <td key={'field'}>Leader</td>,
            <td key={'oldVal'}></td>,
            <td key={'newVal'}>
              {!row.user.hidden ? (
                <Link to={`/profiles/${row.user.userId}`}>{fullName(row.user)}</Link>
              ) : (
                fullName(row.user)
              )}
            </td>,
          ]
        } else {
          return [
            row.community.isHomepage ? <td key={'field'}>Account Partner</td> : <td key={'field'}>Leader</td>,
            <td key={'oldVal'}>
              {!row.user.hidden ? (
                <Link to={`/profiles/${row.user.userId}`}>{fullName(row.user)}</Link>
              ) : (
                fullName(row.user)
              )}
            </td>,
            <td key={'newVal'}></td>,
          ]
        }
      }
    } else if (row.table === 'POST_FOLLOW') {
      if (row.action == 'CREATE') {
        return [
          <td key={'field'}>Post followed</td>,
          <td key={'oldVal'}>{htmlToText(row.oldValue)}</td>,
          <td key={'newVal'}>
            {row.user.firstName && !row.user.hidden ? (
              <Link to={`/profiles/${row.user.userId}`}>{fullName(row.user)}</Link>
            ) : (
              fullName(row.user)
            )}
          </td>,
        ]
      } else if (row.action == 'DELETE') {
        return [
          <td key={'field'}>Post unfollowed</td>,
          <td key={'oldVal'}>
            {row.user.firstName && !row.user.hidden ? (
              <Link to={`/profiles/${row.user.userId}`}>{fullName(row.user)}</Link>
            ) : (
              fullName(row.user)
            )}
          </td>,
          <td key={'newVal'}>{htmlToText(row.newValue)}</td>,
        ]
      }
    } else if (row.table === 'POST') {
      if (row.action == 'UPDATE' && row.field == 'community_id') {
        return [
          <td key={'field'}>{row.field}</td>,
          <td key={'oldVal'}>{row.oldValue}</td>,
          <td key={'newVal'}>
            <Link to={`/communities/${row.post.community.communityId}/posts/${row.post.postId}`}>{row.newValue}</Link>
          </td>,
        ]
      }
    }
    return [
      row.field == 'description' ? (
        <td key={'field'}>Role description</td>
      ) : row.table == 'DOMAIN' ? (
        <td key={'field'}>Domain</td>
      ) : row.table == 'ALIAS' ? (
        <td key={'field'}>Alias</td>
      ) : (
        <td key={'field'}>{row.field}</td>
      ),
      <td key={'oldVal'}>{htmlToText(row.oldValue)}</td>,
      <td key={'newVal'}>{htmlToText(row.newValue)}</td>,
    ]
  }

  const getIdOfRow = (row: HistoryRow) => {
    if (row.table === 'POST_FOLLOW') {
      return row?.post?.postId
    }

    return (
      row?.community?.communityId ??
      row?.user?.userId ??
      row?.company?.companyId ??
      row?.post?.postId ??
      row?.comment?.post.postId ??
      row?.event?.eventId ??
      row?.release?.releaseId
    )
  }

  const validateExcelFileName = (filename: string) => {
    return new RegExp('^.+\\.xlsx$').test(filename) ? filename : filename + '.xlsx'
  }

  const clickExport = async () => {
    if (filename) {
      exportHistoryCsv(startDT, endDT, validateExcelFileName(filename))
        .then(r => {
          if (r) {
            setToast('The file will be emailed to you. You may navigate away from this page.\n')
            setShowToast(true)
          }
        })
        .catch(error => {
          setToast(error.json)
          setShowToast(true)
        })
    } else {
      setToast('Please enter a file name.\n')
      setShowToast(true)
    }
  }

  const showRow = (row: HistoryRow) => {
    return !(
      row.table == 'POST' &&
      row.action == 'UPDATE' &&
      row.field != 'repost_id' &&
      row.field != 'post_type' &&
      row.post.isRepost
    )
  }

  return (
    <div className={`table-container${isCondensed ? ' condensed' : ''}`}>
      <div className={`table-input-container${isCondensedPortrait ? ' condensed-portrait' : ''}`}>
        <div className={'date-time-container'}>
          <div className={'date-time'}>
            <p>Start:</p>
            <input
              type={'datetime-local'}
              className={'start-date-time'}
              id={'start'}
              defaultValue={displayStart.split('.')[0]}
              ref={startRef}
            />
          </div>
          <div className={'date-time'}>
            <p>End:</p>
            <input
              type={'datetime-local'}
              className={'end-date-time'}
              id={'end'}
              defaultValue={displayEnd.split('.')[0]}
              ref={endRef}
            />
          </div>
          <button onClick={updateDT} className={'enter'}>
            Enter
          </button>
        </div>
        <div className={'export-container'}>
          <div className={'export'}>
            <p>File name: </p>
            <Form.Control
              className={'filename'}
              id={'filename'}
              ref={filenameRef}
              value={filename}
              onChange={() => {
                filenameChange()
              }}
            />
          </div>
          <button onClick={clickExport} className={'export-history'}>
            Export History
          </button>
        </div>
      </div>
      <div className={'table-wrapper'}>
        <table>
          <thead>
            <tr>
              {columns.map(col => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {historyData?.map(
              (row, index) =>
                showRow(row) && (
                  <tr key={index}>
                    <td>{getIdOfRow(row)}</td>
                    <td>
                      {row.post.isRepost
                        ? 'REPOST'
                        : row.event.isRepost
                          ? 'EVENT REPOST'
                          : row.table == 'DOMAIN' || row.table == 'ALIAS'
                            ? 'COMMUNITY'
                            : row.table == 'RELEASE' && !row.release.isRelease
                              ? 'SUMMIT'
                              : row.table}
                    </td>
                    {getTableInfo(row)}
                    <td>{UTCtoLocal(row.modifiedTime)}</td>
                    <td>
                      {!row.modifiedBy.hidden ? (
                        <Link to={`/profiles/${row.modifiedBy.userId}`}>{fullName(row.modifiedBy)}</Link>
                      ) : (
                        fullName(row.modifiedBy)
                      )}
                    </td>
                    <td>{row.action}</td>
                    {getValueInfo(row)}
                  </tr>
                )
            )}
          </tbody>
        </table>
        <div>
          {hasMoreRows && (
            <button onClick={loadMoreRows} className={'show-more-button'}>
              Show more rows
            </button>
          )}
        </div>
      </div>
      <ToastComponent show={showToast} onClose={() => setShowToast(false)}>
        {toast ? toast : ''}
      </ToastComponent>
    </div>
  )
}

export default AdminTable
