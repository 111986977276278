import React, { useState } from 'react'
import sectionReorderIcon from '@web/images/posts/DragDropContent.svg'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Maybe } from '@graphql-tools/utils'
import { Button, Dropdown } from 'react-bootstrap'
import PlainTextInput from '~/common/PlainTextInput'
import { SectionRow } from '~/pages/page/SectionMenu'

type SectionMenuRowProps = {
  section: SectionRow
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  draggableProps?: Maybe<DraggableProvidedDraggableProps>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  draggableInnerRef?: (element?: Maybe<HTMLElement>) => any
  addingRow?: boolean
  setAddingRow?: (b: boolean) => void
  onRowEdit?: (s: string, i: string, c: boolean) => Promise<null | undefined | boolean>
  onRowAdd?: (s: string, c: boolean) => Promise<null | undefined | boolean>
}
const SectionMenuRow = ({
  section,
  dragHandleProps,
  draggableInnerRef,
  draggableProps,
  addingRow,
  setAddingRow,
  onRowEdit,
  onRowAdd,
}: SectionMenuRowProps) => {
  const [editing, setEditing] = useState<boolean>(addingRow ?? false)
  const [sectionName, setSectionName] = useState(section.name)
  const [collapsed, setCollapsed] = useState(section.collapseSection)

  const saveSection = async () => {
    const success = addingRow
      ? await onRowAdd?.(sectionName, collapsed)
      : await onRowEdit?.(sectionName, section.sectionId, collapsed)
    if (success) {
      setAddingRow?.(false)
      setEditing(false)
    }
  }

  return (
    <div ref={draggableInnerRef} {...draggableProps} className={`section-row`}>
      <div className={`section-column drag-drop-handle-cell ${addingRow && 'hidden'}`}>
        <img
          alt={'row-drag-drop-handle'}
          src={sectionReorderIcon}
          {...dragHandleProps}
          className={'drag-drop-image'}
          title={'Click and drag to reorder'}
          data-testid={'dnd-handle'}
        />
      </div>
      <div className={'section-column section-name-cell'}>
        {editing ? (
          <PlainTextInput
            placeholder={'Add a Section Name'}
            onChange={setSectionName}
            value={sectionName}
            maxLength={200}
          />
        ) : (
          sectionName
        )}
      </div>
      <div className={'section-column section-collapsed-cell'}>
        {editing ? (
          <Dropdown>
            <Dropdown.Toggle id="section-dropdown-toggle" role={'dropdown'} className={'section-dropdown-toggle'}>
              <span>{collapsed ? 'Collapsed' : 'Expanded'}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setCollapsed(false)
                }}
                eventKey="1"
              >
                Expanded
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setCollapsed(true)
                }}
                eventKey="1"
              >
                Collapsed
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : collapsed ? (
          'Collapsed'
        ) : (
          'Expanded'
        )}
      </div>
      <div className={'section-column cell-actions'}>
        {editing ? (
          <>
            <Button
              variant="light"
              onClick={() => {
                setEditing(false)
                setAddingRow?.(false)
                setSectionName(section.name)
                setCollapsed(section.collapseSection)
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={saveSection} disabled={!sectionName}>
              Save
            </Button>
          </>
        ) : (
          <img
            className="edit-button"
            src={pencilIcon}
            alt="Edit"
            role={'button'}
            tabIndex={0}
            onClick={() => setEditing(true)}
            data-testid={'edit-pencil'}
          />
        )}
      </div>
    </div>
  )
}

export default SectionMenuRow
