import React from 'react'
import { Outlet, useParams } from 'react-router'
import { CommunityProvider } from '~/contexts/CommunityContext'
import CommunitySidebar from '~/pages/community/CommunitySidebar'
import { SizeBreakpoint } from '~/common/hooks/useWindowSize'
import { MediaUploadProvider } from '~/contexts/MediaUploadContext'
import ColumnLayout from '~/common/ColumnLayout'
import { DraftingCommentPostProvider } from '~/contexts/DraftingCommentPostContext'
import { NewActivityProvider } from '~/contexts/NewActivityContext'

const CompanyLayout = () => {
  const { companyId } = useParams<{ companyId: string | undefined }>()

  return (
    <CommunityProvider companyId={companyId}>
      <DraftingCommentPostProvider>
        <NewActivityProvider>
          <MediaUploadProvider>
            <ColumnLayout threshold={SizeBreakpoint.md} additionalColumnClass={'community-layout'} currentPage={'comm'}>
              <CommunitySidebar isCompany={true} />
              <Outlet />
            </ColumnLayout>
          </MediaUploadProvider>
        </NewActivityProvider>
      </DraftingCommentPostProvider>
    </CommunityProvider>
  )
}

export default CompanyLayout
