import '@css/pages/company/CommunityMembershipRow.scss'
import { ActivityType, CommunityActivity, CommunityMembershipPreview } from '~/pages/company/CustomerActivity'
import React, { useMemo } from 'react'
import MoreArrow from '@web/images/community/MoreArrow'
import LessArrow from '@web/images/community/LessArrow'
import { GQLCommunityType, useGetCommunityMemberDataByCompanyIdQuery } from '~/api/generated/graphql'
import { useCommunity } from '~/contexts/CommunityContext'
import UserMembershipRow from '~/pages/company/UserMembershipRow'
import ActivityOverlay from '~/pages/company/ActivityOverlay'
import PrivateCommunityIcon from '@web/images/community/PrivateCommunityIcon'
import { Link } from 'react-router-dom'
import { useStickyState } from '~/common/hooks/useStickyState'

type CommunityMembershipRowProps = {
  communityInfo: CommunityMembershipPreview
}

const CommunityMembershipRow = ({ communityInfo }: CommunityMembershipRowProps) => {
  const { companyId } = useCommunity()
  const expandedRowKey = `community-membership-${communityInfo.id}-${companyId}-expanded`
  const [isExpanded, setIsExpanded] = useStickyState('', expandedRowKey)
  const { name, photo, commentCount, likeCount, lastActivity, postCount, memberCount } = communityInfo
  const { data, loading: membersLoading } = useGetCommunityMemberDataByCompanyIdQuery({
    variables: { companyId: companyId ?? '', communityId: communityInfo.id },
    skip: !companyId || !isExpanded,
  })
  const isPrivate = communityInfo.type === GQLCommunityType.Private

  const createMapData = (
    map: Map<string, CommunityActivity>,
    userId: string,
    type: ActivityType,
    createdTime?: string
  ) => {
    if (userId) {
      const entry = map.get(userId)
      if (entry) {
        if (createdTime) {
          const createdDatetime = new Date(createdTime)
          if ((createdDatetime.getTime() ?? 0) > (entry.lastActivity?.getTime() ?? 0)) {
            entry.lastActivity = createdDatetime
          }
        }

        switch (type) {
          case ActivityType.comment:
            entry.commentCount += 1
            break
          case ActivityType.post:
            entry.postCount += 1
            break
          case ActivityType.like:
            entry.likeCount += 1
            break
        }
      } else {
        map.set(userId, {
          postCount: type === ActivityType.post ? 1 : 0,
          commentCount: type === ActivityType.comment ? 1 : 0,
          likeCount: type === ActivityType.like ? 1 : 0,
          lastActivity: createdTime ? new Date(createdTime) : undefined,
        })
      }
    }
  }

  const userDataMap = useMemo(() => {
    const map = new Map<string, CommunityActivity>()
    data?.community?.members?.edges.forEach(e => {
      if (e?.node?.userId) {
        const entry: CommunityActivity = {
          postCount: 0,
          commentCount: 0,
          likeCount: 0,
          joinedDate: e?.node?.created ? new Date(e?.node?.created) : undefined,
        }
        map.set(e?.node?.userId, entry)
      }
    })
    data?.community?.comments?.edges.forEach(e => {
      createMapData(map, e?.node?.createdById ?? '', ActivityType.comment, e?.node?.createdTime)
    })
    data?.community?.posts?.edges.forEach(e => {
      createMapData(map, e?.node?.createdById ?? '', ActivityType.post, e?.node?.createdTime)
    })
    data?.community?.postLikes?.edges.forEach(e => {
      createMapData(map, e?.node?.userId ?? '', ActivityType.like, e?.node?.created)
    })
    data?.community?.commentLikes?.forEach(e => {
      createMapData(map, e?.userId ?? '', ActivityType.like, e?.created)
    })
    return map
  }, [data])

  const sortedUsers = useMemo(() => {
    const keys = Array.from(userDataMap.keys())
    keys.sort((a, b) => {
      const a_last_activity = userDataMap?.get(a)?.lastActivity
      const b_last_activity = userDataMap?.get(b)?.lastActivity
      if (a_last_activity && b_last_activity) {
        return b_last_activity.getTime() - a_last_activity.getTime()
      } else if (b_last_activity) {
        return 1
      } else if (a_last_activity) {
        return -1
      }
      return 0
    })

    return keys
  }, [userDataMap])

  const handleExpandCollapseClick = () => {
    if (isExpanded) setIsExpanded('')
    else setIsExpanded('true')
  }

  return (
    <>
      <div className={'community-row-header'}>
        <div className={'community-info'}>
          <div
            className="profile-photo community-photo"
            style={photo ? { backgroundImage: `url(${photo})` } : {}}
            role={'img'}
          />
          <Link className={'community-name'} to={`/communities/${communityInfo.id}`}>
            {name}
            {isPrivate && <PrivateCommunityIcon />}
          </Link>
        </div>
        <div className={'community-stats'}>
          <div>{memberCount}</div>
          <div>
            <ActivityOverlay postCount={postCount} commentCount={commentCount} likeCount={likeCount} />
          </div>
        </div>
        <div className={'last-column'}>{lastActivity?.toLocaleDateString()}</div>
        <a
          className={'expand-collapse-button'}
          onClick={handleExpandCollapseClick}
          tabIndex={0}
          data-testid={'expand-button'}
        >
          {isExpanded ? (
            <span className="show-more">
              <LessArrow fill="#565656" width={'15'} height={'15'} />
            </span>
          ) : (
            <span className="show-more">
              <MoreArrow fill="#565656" width={'15'} height={'15'} />
            </span>
          )}
        </a>
      </div>
      {isExpanded &&
        (membersLoading ? (
          <>Loading...</>
        ) : (
          <div className={'user-activity'}>
            {sortedUsers.map(userId => {
              const activity = userDataMap.get(userId)
              return <UserMembershipRow key={userId} userId={userId} activity={activity} />
            })}
          </div>
        ))}
    </>
  )
}

export default CommunityMembershipRow
