import React, { useState } from 'react'
import { Company, Role, User } from '~/types'
import { ProfileEditableText } from '~/pages/profile/ProfileEditableText'
import { GQLHtml, Maybe } from '~/api/generated/graphql'
import ProfileEditHeader from '~/pages/profile/ProfileEditHeader'
import PlainTextInput from '~/common/PlainTextInput'
import { useAuth } from '~/auth/Auth'
import { useProfile } from '~/contexts/ProfileContext'

type Props = {
  user: User
  role: Role
  canEdit: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveRole: (newContent: Maybe<string>) => Promise<any>
  isOwnProfile?: boolean
  rich?: boolean
}

export const ProfileRole = ({
  user,
  role: { company, description, modifiedTime, userId, member },
  canEdit,
  saveRole,
  isOwnProfile,
}: Props) => {
  const { isVeevan } = useAuth()
  const [editing, setEditing] = useState<boolean>(false)
  const [pendingRole, setPendingRole] = useState<Maybe<string>>(description)
  const { updateEditingRoles } = useProfile()

  const clickSave = async () => {
    await saveRole(pendingRole)
    setEditing(false)
    updateEditingRoles?.(company.companyId, false)
  }

  const clickCancel = () => {
    setEditing(false)
    updateEditingRoles?.(company.companyId, false)
  }

  const placeholder = `Please describe a bit about your role at ${company.name}`
  const emptyMessage = isOwnProfile ? placeholder : ''
  return (
    <div className={'editable-text'}>
      <ProfileEditHeader
        userId={userId}
        editing={editing}
        headerText={isVeevan ? '' : `${user.firstName}'s Role at ${company.name}`}
        clickCancel={clickCancel}
        clickSave={clickSave}
        canEdit={canEdit}
        setEditing={setEditing}
        modifiedTime={modifiedTime}
        isRole={true}
        isGlobalLead={member?.accountLead || member?.commercialLead || member?.rdqLead}
        member={member}
        profileName={user.firstName}
        companyName={company.name}
        companyId={company.companyId}
      />
      {editing ? (
        <PlainTextInput placeholder={placeholder} onChange={setPendingRole} value={pendingRole} className={'quill'} />
      ) : (
        <div className={'content-section'}>
          {description ? description : <span className={'empty-content'}>{emptyMessage}</span>}
        </div>
      )}
    </div>
  )
}

type HtmlProps = {
  user: User
  company: Company
  roleAtCompany?: Maybe<GQLHtml>
  modifiedTime?: Date
  canEdit: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveRole: (newContent: Maybe<GQLHtml>) => Promise<any>
  isOwnProfile?: boolean
}

export const ProfileRoleAtCompany = ({
  user,
  company,
  roleAtCompany,
  modifiedTime,
  canEdit,
  saveRole,
  isOwnProfile,
}: HtmlProps) => {
  return (
    <ProfileEditableText
      user={user}
      headerText={`${user.firstName}'s Role at ${company.name}`}
      modifiedTime={modifiedTime}
      content={roleAtCompany}
      canEdit={canEdit}
      saveContent={saveRole}
      isOwnProfile={isOwnProfile}
      placeHolder={`Please describe a bit about your role at ${company.name}`}
      companyId={company.companyId}
    />
  )
}
