import React from 'react'
import '@css/common/EventCalendarIconComponent.scss'
import { Scalars } from '~/api/generated/graphql'
import { asDate } from '~/utils'

interface EventCalendarIconProps {
  eventStart: Scalars['DateTime']
  className?: string
}

export const EventCalendarIconComponent = ({ eventStart, className }: EventCalendarIconProps) => {
  return (
    <div className={`event-calendar-icon ${className}`}>
      <div className={'month'}>{asDate(eventStart)?.toLocaleString([], { month: 'short' }).toUpperCase()}</div>
      <div className={'date'}>{asDate(eventStart)?.toLocaleString([], { day: 'numeric' })}</div>
      {className != 'small-card' && (
        <div className={'day'}>{asDate(eventStart)?.toLocaleString([], { weekday: 'short' }).toUpperCase()}</div>
      )}
    </div>
  )
}
