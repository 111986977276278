import React, { useCallback, useMemo, useState } from 'react'
import { PostList } from '~/pages/posts/PostList'
import { useCommunity } from '~/contexts/CommunityContext'
import {
  GetContentsDocument,
  GetContentsFullDocument,
  GetContentSortOrderDocument,
  GQLGetContentsQuery,
  GQLGetContentsQueryVariables,
  GQLSubscriptionInfo,
  useGetNewPostActivityLazyQuery,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { useBackgroundFetch } from '~/common/hooks/useBackgroundFetch'
import { NewActivityButton } from '~/common/NewActivityButton'
import { useApolloClient } from '@apollo/client'
import { updatePostsCache } from '~/utils'

const CommunityContent = () => {
  const { communityId, newActivity, setNewActivity } = useCommunity()
  const { authUserId } = useAuth()
  const [postExpanded, setPostExpanded] = useState<boolean>(false)

  const {
    loading,
    previewData: data,
    error,
  } = useBackgroundFetch<GQLGetContentsQuery, GQLGetContentsQueryVariables>(
    GetContentsDocument,
    GetContentsFullDocument,
    {
      variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
      skip: !communityId,
    },
    !postExpanded
  )

  const [getPostActivity] = useGetNewPostActivityLazyQuery()
  const client = useApolloClient()
  const showNewActivity = useCallback(async () => {
    const oldData = client.readQuery({
      query: GetContentSortOrderDocument,
      variables: { id: communityId ?? '' },
    })

    const newContentOrder = (JSON.parse(oldData?.community?.contentOrder) as string[]) ?? []
    newActivity.forEach(a => {
      if (a.isContent) {
        newContentOrder.unshift(a.objId)
      }
    })

    const newData = {
      ...oldData,
      community: {
        ...oldData.community,
        contentOrder: JSON.stringify(newContentOrder),
      },
    }

    client.writeQuery({
      query: GetContentSortOrderDocument,
      variables: { communityId: communityId },
      data: newData,
    })
    await updatePostsCache(
      newActivity,
      getPostActivity,
      client,
      communityId ?? '',
      authUserId ?? '',
      setNewActivity,
      GetContentsDocument
    )
  }, [authUserId, client, communityId, getPostActivity, newActivity, setNewActivity])

  const postIds = useMemo(() => {
    return (data?.posts?.edges?.map(e => e?.node?.postId)?.filter(Boolean) as string[]) || []
  }, [data])

  if (error) {
    return <div>Unable to find community</div>
  }

  return (
    <div className={'content-container'}>
      <div className={'top-row-controls'}>
        <h3 className={'comm-title'}>Content</h3>
        {/* Content tab does not need to care about new comments because that does not change the order of the content list */}
        <NewActivityButton
          newActivity={newActivity.filter(x => x.isContent) as GQLSubscriptionInfo[]}
          loading={loading}
          showNewActivity={showNewActivity}
        />
      </div>
      <PostList
        showHasMore={false}
        postIds={postIds}
        fromContentPage={true}
        loading={loading}
        setExpanded={setPostExpanded}
        showEditor={true}
      />
    </div>
  )
}

export default CommunityContent
