import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import {
  GQLCommunityType,
  GQLSubscriptionInfo,
  useGetCommunityQuery,
  useGetCompanyCommunityQuery,
  useNewCommunityActivityAlertSubscription,
  useViewCommunityMutation,
} from '~/api/generated/graphql'
import { ApolloError } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { getSafeLocationState } from '~/utils'
import { useAuth } from '~/auth/Auth'

export type CommunityContextType = {
  loading: boolean
  communityId?: string | null
  companyId?: string
  error?: ApolloError
  companyName?: string
  refetch: () => void
  isPrivate?: boolean
  isVeeva: boolean
  newActivity: GQLSubscriptionInfo[]
  setNewActivity?: React.Dispatch<React.SetStateAction<GQLSubscriptionInfo[]>>
}

export const CommunityContext = React.createContext<CommunityContextType>({
  loading: false,
  refetch: () => {
    return
  },
  isVeeva: false,
  newActivity: [],
})

type CommunityProviderProps = {
  children: ReactNode
  communityId?: string
  companyId?: string
}

export const CommunityProvider = ({ children, communityId, companyId }: CommunityProviderProps) => {
  const {
    data,
    loading,
    error,
    refetch: communityRefetch,
  } = useGetCommunityQuery({
    variables: { id: communityId ?? '' },
    skip: !communityId,
    fetchPolicy: 'cache-and-network',
  })
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
    refetch: companyRefetch,
  } = useGetCompanyCommunityQuery({
    variables: { id: companyId ?? '' },
    skip: !companyId,
    fetchPolicy: 'cache-and-network',
  })

  const [viewCommunity] = useViewCommunityMutation()
  const { isVeevan, authUserId } = useAuth()
  const companyName = companyData?.company?.name
  const isVeeva = companyData?.company?.isVeeva ?? false
  const isPrivate = data?.community?.isPrivate
  const location = useLocation()

  const community = useMemo(() => {
    if (data || companyData) {
      return companyData?.company?.homepage || data?.community
    }
  }, [companyData, data])

  useEffect(() => {
    const communityId = community?.communityId
    const state = getSafeLocationState<{ fromLoginRedirect?: boolean }>(location.state)
    const fromLogin = state?.fromLoginRedirect
    if (communityId) {
      // if viewing this from a redirect after login, tell the mutation, so it knows not to add the default interest
      viewCommunity({ variables: { communityId, fromLogin } }).then()
    }
  }, [community?.communityId, viewCommunity, location.state])

  const refetch = () => {
    if (community?.type === GQLCommunityType.Homepage) {
      companyRefetch({ id: companyId }).then()
    } else {
      communityRefetch({ id: communityId }).then()
    }
  }

  // use a map in the form [communityId]:[new posts] to prevent posts from persisting between communities
  const [newActivity, setNewActivity] = useState<GQLSubscriptionInfo[]>([])
  const id = communityId || community?.communityId || ''

  // when we switch between tabs or communities it grabs the latest posts/comment activity, so we need to clear any "pending" new activity
  useEffect(() => {
    setNewActivity([])
  }, [location])

  useNewCommunityActivityAlertSubscription({
    variables: { communityIds: [id] },
    onSubscriptionData: async ({ subscriptionData }) => {
      if (
        subscriptionData.data &&
        subscriptionData.data.newCommunityActivityAlert &&
        authUserId != subscriptionData.data.newCommunityActivityAlert.authorId
      ) {
        const p = subscriptionData.data.newCommunityActivityAlert
        const activityObjs = [...(newActivity ?? [])]
        if (p.parentId) {
          activityObjs.filter(a => a.objId != p.parentId)
        }
        if (!activityObjs.find(activity => activity.objId === p.objId)) {
          activityObjs.unshift(p)
        }
        setNewActivity(activityObjs)
      }
    },
    skip: !isVeevan || !id,
  })

  return (
    <CommunityContext.Provider
      value={{
        communityId: communityId ?? community?.communityId,
        companyId: companyId,
        companyName,
        error: companyError || error,
        isPrivate,
        isVeeva,
        loading: companyLoading || loading,
        refetch,
        newActivity,
        setNewActivity,
      }}
    >
      {children}
    </CommunityContext.Provider>
  )
}

export const useCommunity = () => useContext(CommunityContext)
