import React from 'react'
import { AppHeader } from '~/common/AppHeader'
import { Footer } from '~/common/Footer'
import '@css/pages/company/AddCustomer.scss'
import CommunitySettingsEdit from '~/pages/community/CommunitySettingsEdit'
import { useAuth } from '~/auth/Auth'
import { useWindowSize } from '~/common/hooks/useWindowSize'

const AddCustomer = () => {
  const { isVeevan, profileVisible, loading: authLoading } = useAuth()
  const { isCondensed } = useWindowSize()
  const condensedClass = isCondensed ? ' condensed' : ''

  if (authLoading) return null

  if (!isVeevan) return <div data-testid={'not-a-veevan'} />

  return (
    <>
      <AppHeader />
      <div className={'add-customer-body' + condensedClass}>
        <CommunitySettingsEdit isCompany={true} profileVisible={profileVisible} creatingCompany={true} />
      </div>
      <Footer />
    </>
  )
}

export default AddCustomer
