import React, { useEffect, useState } from 'react'
import { MediaComponent } from '~/pages/posts/MediaComponent'
import { CommentLikes } from './Likes'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { GQLMeetup, useGetCommentBodyQuery } from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import PostStory from '~/pages/posts/PostStory'

type Props = {
  commentId: string
  scrollToComment?: boolean
  headerRef?: React.RefObject<HTMLDivElement>
  hidden?: boolean
  postId: string
  lastLoadedComment?: string
  setLastLoadedComment?: (s: string) => void
}

export const CommentBody = ({
  commentId,
  scrollToComment,
  headerRef,
  postId,
  hidden = false,
  lastLoadedComment,
  setLastLoadedComment,
}: Props) => {
  const { breakpoint } = useWindowSize()
  const isMediumOrSmaller = breakpoint <= SizeBreakpoint.md
  const { profileVisible } = useAuth()
  const { data: commentData, loading } = useGetCommentBodyQuery({ variables: { id: commentId } })
  const comment = commentData?.comment
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (scrollToComment && !loading && headerRef && !scrolled) {
      const y = (headerRef.current?.getBoundingClientRect()?.top ?? 0) - 60 // account for app header height
      document.body.children[0].scrollTo({ top: y, behavior: 'smooth' })
      setScrolled(true) // adding a new comment causes re-scroll
    }
  }, [scrollToComment, loading, headerRef, scrolled])

  useEffect(() => {
    if (lastLoadedComment == commentId && !loading) {
      headerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      setLastLoadedComment?.('')
    }
  }, [commentId, headerRef, lastLoadedComment, loading, setLastLoadedComment])

  if (loading) return null

  return (
    <div className={`comment-body${isMediumOrSmaller ? ' condensed' : ''}`}>
      <PostStory
        media={comment?.media ?? []}
        story={comment?.story}
        loading={loading ?? true}
        postId={postId}
        commentId={commentId}
        hasPrimaryMedia={!!comment?.mediaUrl}
        meetup={commentData?.comment?.meetup as GQLMeetup}
      />
      {comment?.mediaType && (
        <div className={'media-holder'}>
          <div className={'media-item'}>
            <MediaComponent
              media_type={comment?.mediaType}
              url={comment?.mediaUrl ?? undefined}
              content_title={comment?.mediaName ?? undefined}
              post_id={postId}
              comment_id={commentId}
              index={-1}
            />
          </div>
        </div>
      )}
      {profileVisible && <CommentLikes id={commentId} hidden={hidden} />}
    </div>
  )
}
