import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Modal, useAccordionButton } from 'react-bootstrap'
import { PostHeader } from '~/pages/posts/PostHeader'
import { PostBody } from '~/pages/posts/PostBody'
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useGetPostRowQuery } from '~/api/generated/graphql'
import { elementClicked } from '~/common/EventLogger'
import { Maybe } from '@graphql-tools/utils'
import { useCommunity } from '~/contexts/CommunityContext'
import { useAuth } from '~/auth/Auth'
import { PostEdit } from '~/pages/posts/PostEdit'
import { useShortcuts } from '~/contexts/ShortcutContext'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import { UnsavedWarningModal } from '~/common/UnsavedWarningModal'
import { isMacOs } from 'react-device-detect'
import { getPostPath } from '~/utils'

type PostRowProps = {
  postId: string
  eventKey: string
  onDelete?: (isDraft: boolean) => void
  onClickEdit?: () => void
  resetScroll?: () => void
  isHomeFeed: boolean
  fromContentPage: boolean
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  isEditingPost?: boolean
  draggableProps?: Maybe<DraggableProvidedDraggableProps>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  draggableInnerRef?: (element?: Maybe<HTMLElement>) => any
  rowExpanded?: (rowId: string, expanded: boolean) => void
  showEditor?: boolean
  isNewDraft?: boolean
  showDrag?: boolean
}

export const PostRow = ({
  postId,
  eventKey,
  onDelete,
  onClickEdit,
  resetScroll,
  isHomeFeed,
  fromContentPage,
  dragHandleProps,
  isEditingPost,
  draggableProps,
  draggableInnerRef,
  rowExpanded,
  showEditor,
  showDrag,
  isNewDraft,
}: PostRowProps) => {
  const { loading: communityLoading, communityId, companyId, setNewActivity } = useCommunity()
  const { actingSysAdmin } = useAuth()
  const { data: postData, loading: postLoading } = useGetPostRowQuery({
    variables: { id: postId },
    skip: communityLoading,
  })
  const [readPost, setReadPost] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [shouldExpandDraft, setShouldExpandDraft] = useState(isNewDraft)
  const [showEditRepostModal, setShowEditRepostModal] = useState(false)
  const { setSelectedPost } = useShortcuts()

  const headerRef = useRef<HTMLDivElement>(null)
  const expand = useAccordionButton(eventKey, (e: SyntheticEvent) => {
    setReadPost(true)
    elementClicked(e, 'click-post-expanded', { postId: postData?.post?.postId })
    setSelectedPost?.({ postId: postData?.post?.postId || '', expanded: true })
  })

  useEffect(() => {
    setReadPost(postData?.post?.viewed ?? false)
  }, [setReadPost, postData?.post?.viewed])

  useEffect(() => {
    if (rowExpanded) {
      rowExpanded(postId, isExpanded)
    }
  }, [rowExpanded, postId, isExpanded])

  const editing = isEditing ?? isEditingPost

  const { checkDraftingComment, resetPostComments, setDraftingPost } = useDraftingCommentPost()
  const [showWarningModal, setShowWarningModal] = useState(false)

  const confirmEditPost = () => {
    setShouldExpandDraft(false)
    setIsEditing(true)
    onClickEdit?.()
    setDraftingPost?.(postId, false, true)
    resetPostComments?.(postId)
    setShowWarningModal(false)
  }

  const handleClickEdit = (isRepost = false) => {
    if (isRepost) {
      setShowEditRepostModal(true)
    } else {
      if (checkDraftingComment?.(postId)) {
        setShowWarningModal(true)
      } else {
        confirmEditPost()
      }
    }
  }

  const handlePostClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isMacOs ? e.metaKey : e.ctrlKey) {
      window.open(getPostPath({ communityId, companyId }, postData?.post))
    } else {
      expand(e)
    }
  }

  const handleDelete = (isDraft: boolean, id: string) => {
    onDelete?.(isDraft)
    setNewActivity?.(activityObjs => {
      return [...activityObjs].filter(o => o.objId != id)
    })
  }

  const accordionRef = useRef<HTMLDivElement>(null)

  if (!postData && !(communityLoading || postLoading)) {
    return null
  }

  return (
    <>
      <div
        data-testid={'post-row'}
        className={`post-item${readPost || postData?.post?.draft ? ' read' : ' unread'}${
          isExpanded ? ' expanded' : ''
        }${postData?.post?.hidden && actingSysAdmin ? ' hidden' : ''}`}
        ref={draggableInnerRef}
        {...draggableProps}
      >
        {editing && !isExpanded ? (
          <PostEdit
            postId={postId}
            onDone={shouldCollapse => {
              setIsEditing(false)
              if (shouldCollapse) setIsExpanded(false)
            }}
            resetScroll={resetScroll}
          />
        ) : (
          !editing && (
            <>
              <div ref={headerRef} />
              <PostHeader
                postId={postId}
                isExpanded={isExpanded || isHomeFeed}
                onClick={handlePostClick}
                onDelete={handleDelete}
                onClickEdit={handleClickEdit}
                fromContentPage={fromContentPage}
                isHomeFeed={isHomeFeed}
                dragHandleProps={dragHandleProps}
                isEditingPost={editing}
                isRepostPage={false}
                showEditor={showEditor}
                shouldExpandDraft={shouldExpandDraft}
                showDrag={showDrag}
              />
            </>
          )
        )}
        {/*using another Accordion Collapse here makes home feed expansion animation look bad*/}
        {isHomeFeed ? (
          <PostBody
            postId={postId}
            onClickEditDraft={handleClickEdit}
            onClickDeleteDraft={() => onDelete?.(true)}
            collapsePost={() => setIsExpanded(false)}
          />
        ) : (
          <Accordion.Collapse
            eventKey={eventKey}
            className={'post-expand-zone'}
            onEnter={() => setIsExpanded(true)}
            onExited={() => setIsExpanded(false)}
            ref={accordionRef}
          >
            {editing && isExpanded ? (
              <PostEdit
                postId={postId}
                onDone={shouldCollapse => {
                  setIsEditing(false)
                  if (shouldCollapse) setIsExpanded(false)
                }}
                resetScroll={resetScroll}
                onClickDeleteDraft={() => onDelete?.(true)}
              />
            ) : isExpanded ? (
              <PostBody
                postId={postId}
                onClickEditDraft={handleClickEdit}
                onClickDeleteDraft={() => onDelete?.(true)}
                collapsePost={() => setIsExpanded(false)}
              />
            ) : (
              <></>
            )}
          </Accordion.Collapse>
        )}
      </div>
      <Modal show={showEditRepostModal} onHide={() => setShowEditRepostModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>This is a repost. To edit it, you must edit the original.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={() => setShowEditRepostModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <UnsavedWarningModal
        showWarningModal={showWarningModal}
        setShowWarningModal={setShowWarningModal}
        continueAction={confirmEditPost}
        leavingPost={false}
      />
    </>
  )
}
