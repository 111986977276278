import { StoryRow } from '~/pages/posts/PostStory'
import { GQLMediaAlignmentType, GQLMeetup } from '~/api/generated/graphql'
import { MediaComponent } from '~/pages/posts/MediaComponent'
import MentionableText from '~/common/MentionableText'
import { asHtmlWithMentions } from '~/utils'
import { Maybe } from '@graphql-tools/utils'

type PostStoryRowProps = {
  row: StoryRow
  index?: number
  meetup?: GQLMeetup
  commentId?: string
  postId?: string
  repostId?: Maybe<string>
  primaryVideoUrl?: Maybe<string>
}
const PostStoryRow = ({ row, index, meetup, commentId, postId, repostId, primaryVideoUrl }: PostStoryRowProps) => {
  const { alignment, mediaType, mediaUrl, story, filename } = row

  return (
    <div
      className={`post-story-row${alignment === GQLMediaAlignmentType.RightMedia ? ' right-media' : ''}${
        alignment === GQLMediaAlignmentType.FullText ? ' full-text' : ''
      }`}
    >
      {mediaUrl && mediaType && (
        <div className={`row-media-container${alignment === GQLMediaAlignmentType.FullMedia ? ' full' : ''}`}>
          <div className={'media-holder'}>
            <div className={'media-item'}>
              <MediaComponent
                media_type={mediaType}
                url={mediaUrl}
                uploading={false}
                content_title={filename ?? undefined}
                index={index}
                post_id={postId}
                comment_id={commentId}
              />
            </div>
          </div>
        </div>
      )}
      {story && (
        <div className={`row-quill-container${alignment === GQLMediaAlignmentType.FullText ? ' full' : ''}`}>
          <MentionableText
            value={asHtmlWithMentions(story)}
            meetup={meetup}
            commentId={commentId}
            postId={postId}
            repostId={repostId}
            primaryVideoUrl={primaryVideoUrl}
          />
        </div>
      )}
    </div>
  )
}

export default PostStoryRow
