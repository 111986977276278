import React, { SyntheticEvent } from 'react'
import '@css/pages/community/CommunitySidebar.scss'
import { SearchHighlightKey, SearchMatch } from '~/types'
import { getDayMonthYear, getPostPath, getTimeAMPM } from '~/utils'
import {
  useGetPostSearchResultAuthorQuery,
  useGetPostSearchResultCommunityQuery,
  useGetPostSearchResultPostQuery,
} from '~/api/generated/graphql'
import { Maybe } from '@graphql-tools/utils'
import { AuthorFacet, CompanyFacet } from '~/pages/search/AdvancedSearchSidebar'
import AdvancedSearchExtraAuthor from '~/pages/search/AdvancedSearchExtraAuthor'
import { isAuthorSelected } from '~/pages/search/SearchUtilities'
import { Link } from 'react-router-dom'

type AdvancedPostSearchResultProps = {
  result: Maybe<SearchMatch>
  selectedAuthors: AuthorFacet[]
  selectedCompanies: CompanyFacet[]
  onResultClicked: (link: string, postId: string, e: SyntheticEvent) => void
}

const AdvancedSearchResult = ({
  result,
  selectedAuthors,
  selectedCompanies,
  onResultClicked,
}: AdvancedPostSearchResultProps) => {
  const { data: communityData } = useGetPostSearchResultCommunityQuery({
    variables: { id: result?.post.communityId ?? '' },
    skip: !result?.post.communityId,
  })
  const community = communityData?.community

  const { data: postData } = useGetPostSearchResultPostQuery({
    variables: { postId: result?.post.id ?? '' },
    skip: !result?.post.id,
  })
  const post = postData?.post
  const commentAuthorIds = new Set(post?.publicComments?.comments.map(c => c?.createdById) as string[])

  const { data: postAuthorData } = useGetPostSearchResultAuthorQuery({
    variables: { id: post?.createdById ?? '' },
    skip: !post?.createdById,
  })
  const postAuthor = postAuthorData?.user
  const postCreatedTime = post?.createdTime ?? ''
  const link = result?.highlight.some(
    hl =>
      hl?.field === SearchHighlightKey.discussionsStory ||
      hl?.field === SearchHighlightKey.discussionsStoryPhrases ||
      hl?.field === SearchHighlightKey.discussionsFiles
  )
    ? getPostPath(community, post) + '?vd=1'
    : getPostPath(community, post)

  const handleResultClicked = (e: SyntheticEvent) => {
    onResultClicked(link, post?.postId ?? '', e)
  }

  const getTimeStampText = () => {
    return `${postAuthor?.firstName} ${postAuthor?.lastName} (${postAuthor?.company?.name}) posted on ${getDayMonthYear(
      postCreatedTime
    )} at ${getTimeAMPM(postCreatedTime)}`
  }

  const getFormattedKey = (key: string) => {
    switch (key) {
      case SearchHighlightKey.title:
      case SearchHighlightKey.titlePhrases:
        return 'Title'
      case SearchHighlightKey.contentTitle:
      case SearchHighlightKey.contentTitlePhrases:
        return 'Content Title'
      case SearchHighlightKey.story:
      case SearchHighlightKey.storyPhrases:
        return 'Message'
      case SearchHighlightKey.commentsStory:
      case SearchHighlightKey.commentsStoryPhrases:
        return 'Comment'
      case SearchHighlightKey.files:
        return 'Message Attachment'
      case SearchHighlightKey.commentsFiles:
        return 'Comment Attachment'
      case SearchHighlightKey.authors:
      case SearchHighlightKey.allAuthors:
        return 'Post/Comment by'
      case SearchHighlightKey.discussionsStory:
      case SearchHighlightKey.discussionsStoryPhrases:
        return 'Veeva Discussion'
      case SearchHighlightKey.discussionsFiles:
        return 'Veeva Discussion Attachment'
    }
  }

  const getFormattedSnippets = (key: SearchHighlightKey, snippets: (null | undefined | string)[]) => {
    if (key === SearchHighlightKey.commentsStory) {
      let finalString = ``
      snippets.slice(0, 3).forEach(snippet => {
        finalString = finalString.concat(`<span>${snippet}<br></span>`)
      })
      return `<span>${finalString}</span>`
    } else if (key === SearchHighlightKey.files || key === SearchHighlightKey.commentsFiles) {
      const fileAttachmentParts = ((snippets ?? [])[0] ?? '').split(' ')
      const attachment = fileAttachmentParts.splice(-1, 1)[0]
      let finalString = ''
      finalString = fileAttachmentParts.join(' ')
      finalString = finalString.trim().concat(`.${attachment}`)
      return `<span>${finalString}</span>`
    } else {
      return `<span>${snippets[0]}</span>`
    }
  }

  // Sorts by the order defined in SearchHighlightKey enum
  const getOrderedHighlights = () => {
    const highlights = result?.highlight
    return (
      Array.from(highlights || []).sort(
        (a, b) => `${b?.field}`.localeCompare(`${a?.field}`, undefined, { sensitivity: 'base' }) || 0
      ) || []
    )
  }

  return (
    <div>
      <Link
        to={link}
        state={{ fromSearchPage: true }}
        onClick={handleResultClicked}
        onKeyDown={e => {
          if (e.key === 'Enter') handleResultClicked(e)
        }}
        tabIndex={0}
        className={'search-result'}
      >
        <div className={'community-details'} tabIndex={-1}>
          <div
            className={`community-icon community-photo`}
            style={community?.photo ? { backgroundImage: `url(${community?.photo})` } : {}}
          />
          <span className={'community-name'}>{community?.name}</span>
        </div>
        <div className={`post-details`}>
          <span className={'post-title'}>{result?.post.title}</span>
          <span className={'timestamp'}>{getTimeStampText()}</span>
          {result?.highlight &&
            getOrderedHighlights().map((hl, index) => (
              <div key={`hl-${hl?.field}-${index}`} className={'highlight'}>
                <span className={'highlight-key'}>{getFormattedKey(hl?.field ?? '')}: </span>
                <span
                  className={'highlight-text'}
                  dangerouslySetInnerHTML={{
                    __html: getFormattedSnippets(hl?.field as SearchHighlightKey, hl?.highlights || []) ?? '',
                  }}
                ></span>
              </div>
            ))}
          {isAuthorSelected(
            postAuthor?.userId,
            postAuthor?.company?.companyId,
            selectedAuthors.map(a => a.authorId),
            selectedCompanies.map(c => c.companyId)
          ) && (
            <div key={`extra-hl-author-${postAuthor?.userId}`} className={'highlight'}>
              <span className={'highlight-key'}>Post By: </span>
              <span
                className={'highlight-text'}
              >{`${postAuthor?.firstName} ${postAuthor?.lastName} (${postAuthor?.company?.name})`}</span>
            </div>
          )}
          {Array.from(commentAuthorIds).map((authorId, index) => (
            <AdvancedSearchExtraAuthor
              authorId={authorId}
              isCommenter={true}
              selectedAuthorIds={selectedAuthors.map(a => a.authorId)}
              selectedCompanyIds={selectedCompanies.map(c => c.companyId)}
              key={`${authorId}-${index}`}
            />
          ))}
        </div>
      </Link>
    </div>
  )
}

export default AdvancedSearchResult
