import CommentHeader from '~/pages/posts/CommentHeader'
import { CommentBody } from '~/pages/posts/CommentBody'
import React, { useRef } from 'react'
import '@css/pages/posts/CommentRow.scss'
import { useGetCommentRowQuery } from '~/api/generated/graphql'

export const CommentRow = ({
  commentId,
  handleDelete,
  onClickEdit,
  isOnSinglePost,
  isVeevanDiscussion,
  scrollToComment,
  postId,
  postHidden,
  lastLoadedComment,
  setLastLoadedComment,
}: {
  commentId: string
  handleDelete: (id: string) => void
  onClickEdit: () => void
  isOnSinglePost?: boolean
  isVeevanDiscussion?: boolean
  scrollToComment?: boolean
  postId: string
  postHidden: boolean
  lastLoadedComment?: string
  setLastLoadedComment?: (s: string) => void
}) => {
  const { data, loading } = useGetCommentRowQuery({ variables: { id: commentId } })
  const headerRef = useRef<HTMLDivElement>(null)
  if (loading && !data) return null
  const hidden = data?.comment?.postHidden || data?.comment?.hidden || postHidden
  return (
    <div ref={headerRef} className={`comment-item ${data?.comment?.hidden ? 'hidden' : ''}`}>
      <CommentHeader
        commentId={commentId}
        onDelete={handleDelete}
        onClickEdit={onClickEdit}
        isOnSinglePost={isOnSinglePost}
        isVeevanDiscussion={isVeevanDiscussion}
        hidden={hidden}
      />
      <CommentBody
        postId={postId}
        commentId={commentId}
        scrollToComment={scrollToComment}
        headerRef={headerRef}
        hidden={hidden}
        lastLoadedComment={lastLoadedComment}
        setLastLoadedComment={setLastLoadedComment}
      />
    </div>
  )
}
