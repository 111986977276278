import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import '@css/pages/posts/RepostOverlay.scss'
import RepostOverlay, { RepostType } from '~/common/RepostOverlay'
import { useState } from 'react'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { useParams } from 'react-router'
import { useGetAllMyCommunitiesQuery, useGetEventQuery, useGetRepostsByEventQuery } from '~/api/generated/graphql'
import { RepostCommunity } from '~/types'
import { asEvent } from '~/utils'

const EventRepostOverlay = () => {
  const { communityId, eventId } = useParams<{ communityId: string; eventId: string }>()
  const { isCondensed } = useWindowSize()

  const { data: communitiesData } = useGetAllMyCommunitiesQuery()
  const { data: eventData, loading: eventLoading } = useGetEventQuery({
    variables: { id: eventId ?? '' },
  })

  const event = eventData?.event

  const [repostedComms, setRepostedComms] = useState<string[]>([])

  const {
    data: repostData,
    loading: loadingReposts,
    refetch: refetchReposts,
  } = useGetRepostsByEventQuery({
    variables: { eventId: eventId ?? '' },
    skip: !communityId,
  })

  const reposts = useMemo(() => {
    return repostData?.event?.reposts?.map(e => asEvent(e))?.filter(repost => repost && !repost?.hidden)
  }, [repostData])

  const repostCommunities = useMemo(() => {
    const edges = communitiesData?.currentUser?.communities?.edges
    if (!edges) return
    const completedRepostCommunityIds = reposts?.filter(c => !c.hidden)?.map(r => r.communityId)
    const filteredCommunities = edges
      ?.map(e => {
        return {
          communityId: e?.node?.communityId ?? '',
          name: e?.node?.name ?? '',
          description: e?.node?.description ?? '',
          companyId: e?.node?.companyId ?? '',
          photo: e?.node?.photo ?? '',
          type: e?.node?.type,
        }
      })
      .filter(
        c =>
          c?.communityId !== communityId &&
          // if someone reposts to a community, don't hide from the list while they're still on the page
          (!completedRepostCommunityIds?.includes(c.communityId) || repostedComms.includes(c.communityId))
      )
    return filteredCommunities as RepostCommunity[]
  }, [communitiesData, repostedComms, communityId, reposts])

  if (eventLoading || loadingReposts) return <div>Loading ...</div>
  if (!event) return null

  return (
    <>
      {event ? (
        <RepostOverlay
          fromPage={'Events'}
          title={event.title}
          repostType={RepostType.EVENT}
          eventReposts={reposts ?? []}
          repostCommunities={repostCommunities ?? []}
          repostedComms={repostedComms}
          setRepostedComms={setRepostedComms}
          refetchReposts={refetchReposts}
          originalEvent={event}
        />
      ) : (
        <div className={'post-container'}>
          <div className={`nav-simple-zone${isCondensed ? ' condensed' : ''}`}>
            <Link to="../..">&larr; Back to Events</Link>
          </div>
          <div className={'post-item'}>
            <div className={'error type01'}>Event could not be found</div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventRepostOverlay
