import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
  CanAddContentDocument,
  GetContentsDocument,
  GetPostsDocument,
  GQLCanAddContentQuery,
  GQLGetContentsQuery,
  GQLGetPostsQuery,
  GQLHtmlWithMentions,
  GQLMediaAlignmentType,
  GQLMediaType,
  GQLMeetupInput,
  GQLPost,
  GQLPostMedia,
  GQLPostType,
  Maybe,
  useEditContentMutation,
  useEditPostMutation,
  useGetPostEditQuery,
  useSetHidePostMutation,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { AddMediaTarget, UploadZone } from '~/pages/posts/AddMediaTarget'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import {
  calculateFieldLengthSeverity,
  getHtmlLength,
  updateCacheContent,
  updateCachePosts,
  usePermissions,
} from '~/pages/posts/PostUtils'
import { MediaUploadProvider, useMediaUpload } from '~/contexts/MediaUploadContext'
import { asHtmlWithMentions, asString, checkConfidentialWarning, cleanupHTML } from '~/utils'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import PostConfirmationModal from '~/pages/posts/PostConfirmationModal'
import { elementClicked } from '~/common/EventLogger'
import MultipleEditor, { EditorRowData } from '~/common/quill/MultipleEditor'
import { useCommunity } from '~/contexts/CommunityContext'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router'
import PlainTextInput from '~/common/PlainTextInput'
import ToastComponent from '~/common/ToastComponent'
import AddPersonBox from '~/common/addPerson/AddPersonBox'
import { AddPersonBoxProvider, SelectedUser, useAddPersonBox } from '~/common/addPerson/AddPersonBoxContext'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'

type PostEditProps = {
  postId: string
  onDone: (shouldCollapse: boolean) => void
  onClickDeleteDraft?: () => void
  resetScroll?: () => void
  isSinglePost?: boolean
  postListPath?: string
}

type PostEditInnerProps = PostEditProps & {
  post?: {
    title?: Maybe<GQLHtmlWithMentions>
    story?: Maybe<GQLHtmlWithMentions>
    mediaType?: Maybe<GQLMediaType>
    communityId: string
    postType: GQLPostType
    media?: Maybe<Maybe<Partial<GQLPostMedia>>[]>
    contentTitle?: string | null
    draft?: boolean
    onClickDeleteDraft?: () => void
    createdById?: Maybe<string>
    mediaUrl?: Maybe<string>
  }
}

export const PostEdit = ({
  postId,
  onDone,
  onClickDeleteDraft,
  resetScroll,
  isSinglePost,
  postListPath,
}: PostEditProps) => {
  const { data } = useGetPostEditQuery({ variables: { id: postId } })
  const post = data?.post
  const source = {
    media_url: post?.mediaUrl ?? undefined,
    media_type: post?.mediaType ?? undefined,
    content_title: post?.contentTitle ?? undefined,
    media_name: post?.mediaName ?? undefined,
  }
  if (!post) return null
  return (
    <MediaUploadProvider source={source}>
      <AddPersonBoxProvider>
        <PostEditWrapped
          postId={postId}
          post={post}
          onDone={onDone}
          onClickDeleteDraft={onClickDeleteDraft}
          resetScroll={resetScroll}
          isSinglePost={isSinglePost}
          postListPath={postListPath}
        />
      </AddPersonBoxProvider>
    </MediaUploadProvider>
  )
}

const PostEditWrapped = ({
  postId,
  post,
  onDone,
  onClickDeleteDraft,
  resetScroll,
  isSinglePost,
  postListPath,
}: PostEditInnerProps) => {
  const [titleLength, setTitleLength] = useState<number>(getHtmlLength(post?.title))
  const [title, setTitle] = useState(post?.title)
  const [disableEdit, setDisableEdit] = useState<boolean>(false)
  const [editPost] = useEditPostMutation({
    update(cache, { data }) {
      updateCachePosts(cache, client, authUserId, data?.editPost?.post as GQLPost)
      if (data?.editPost?.post?.postType === GQLPostType.Content) {
        updateCacheContent(cache, client, authUserId, communityId, data?.editPost?.post as GQLPost)
      }
    },
  })
  const [editContent] = useEditContentMutation({
    update(cache, { data }) {
      updateCachePosts(cache, client, authUserId, data?.editPost?.post as GQLPost)
      if (data?.editPost?.post?.postType === GQLPostType.Content) {
        updateCacheContent(cache, client, authUserId, communityId, data?.editPost?.post as GQLPost)
      }
    },
  })
  const { authUserId, actingSysAdmin } = useAuth()
  const {
    mediaUrl,
    mediaDeleted,
    uploadId,
    mediaType,
    toastMessage: mediaToast,
    isToastError,
    showToast: showMediaToast,
    setShowToast: setShowMediaToast,
  } = useMediaUpload()
  const [contentTitle, setContentTitle] = useState<string>(post?.contentTitle ?? '')
  const [contentTitleLength, setContentTitleLength] = useState<number>(contentTitle.length)
  const contentInput = useRef<HTMLTextAreaElement>(null)
  const [videoURL, setVideoURL] = useState<string | null>(null)
  const { isVeevan } = useAuth()
  const isContent = post?.postType === GQLPostType.Content
  const [showConfidentialWarning, setShowConfidentialWarning] = useState<boolean>(false)
  const { communityId } = useCommunity()

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [confirmationMessage, setConfirmationMessage] = useState<string>()
  const [showSensitiveWarning, setShowSensitiveWarning] = useState<boolean>(false)
  const [meetup, setMeetup] = useState<GQLMeetupInput>()

  const MAX_TITLE_LENGTH = 100

  const title_severity = calculateFieldLengthSeverity(titleLength, MAX_TITLE_LENGTH)
  const content_title_severity = calculateFieldLengthSeverity(contentTitleLength, MAX_TITLE_LENGTH)

  const { canEdit } = usePermissions(post?.createdById, postId)

  const postDisabled =
    titleLength === 0 ||
    titleLength > MAX_TITLE_LENGTH ||
    (isContent && (contentTitleLength > MAX_TITLE_LENGTH || contentTitleLength === 0)) ||
    disableEdit ||
    !canEdit

  const translateToRowsData = (): EditorRowData[] => {
    const mediaMap = new Map<number, Maybe<Partial<GQLPostMedia>>>()
    post?.media?.forEach(m => mediaMap.set(m?.rowIndex ?? 0, m))

    if (post?.story?.htmlWithMentions) {
      try {
        const rows: EditorRowData[] = []
        const storyRows = JSON.parse(post?.story.htmlWithMentions) as string[]
        storyRows.forEach((story, index) => {
          const row: EditorRowData = { alignment: GQLMediaAlignmentType.FullText, story: asHtmlWithMentions(story) }
          const media = mediaMap.get(index)
          if (media) {
            row.mediaUrl = media.mediaUrl
            row.mediaType = media.type ?? undefined
            row.alignment = media.alignment ?? GQLMediaAlignmentType.FullMedia
            if (media.filename) {
              row.filename = media.filename
            }
          }
          rows.push(row)
        })
        return rows
      } catch {
        return [{ story: post?.story, alignment: GQLMediaAlignmentType.FullText }]
      }
    } else {
      return [{ alignment: GQLMediaAlignmentType.FullText }]
    }
  }

  const [rowsData, setRowsData] = useState<EditorRowData[]>(translateToRowsData())
  const originalStoryRows = translateToRowsData()

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
    } else {
      setMadeChanges(true)
    }
  }, [rowsData])

  useEffect(() => {
    if (uploadId && isContent) {
      setContentTitle(contentInput?.current?.value || '')
    }
  }, [uploadId, setContentTitle, isContent])

  const doSave = async (args: {
    skipWarning?: boolean
    warned?: boolean
    e?: SyntheticEvent
    isDraft?: boolean
    meetup?: GQLMeetupInput
  }) => {
    if (postDisabled) return
    const { skipWarning, warned, isDraft } = args
    setDisableEdit(true)
    if (
      !skipWarning &&
      !isVeevan &&
      !isDraft &&
      (rowsData?.some(row => checkConfidentialWarning(row.story?.htmlWithMentions) || row.mediaType) ||
        mediaUrl ||
        uploadId)
    ) {
      setShowConfidentialWarning(true)
      setDisableEdit(false)
      return
    }
    // if the user input a video url, set video_url as that video url
    // otherwise if the user uploaded a video, set video_url as the url of that upload
    const video_url = videoURL ? videoURL : mediaType === GQLMediaType.Video && !uploadId ? mediaUrl : undefined
    let response
    if (isContent) {
      response = await editContent({
        variables: {
          title: cleanupHTML(asString(title)),
          story: rowsData
            ?.map(row => ({
              story: row.story?.htmlWithMentions,
              uploadId: row.uploadId,
              videoUrl: row.videoUrl,
              alignment: row.alignment,
              mediaType: row.mediaType,
              filename: row.filename,
              mediaUrl: row.uploadId ? undefined : row.mediaUrl,
            }))
            .filter(row => row.story || row.filename || row.uploadId || row.mediaUrl || row.videoUrl),
          post_id: postId,
          upload_id: uploadId,
          content_title: contentTitle,
          delete_media: mediaDeleted && !videoURL,
          draft: isDraft,
          warned: warned,
          video_url,
          author_id: selectedUser ? selectedUser.userId : undefined,
          meetup: meetup,
        },
      })
    } else {
      response = await editPost({
        variables: {
          title: cleanupHTML(asString(title)),
          story: rowsData
            ?.map(row => ({
              story: row.story?.htmlWithMentions,
              uploadId: row.uploadId,
              videoUrl: row.videoUrl,
              alignment: row.alignment,
              mediaType: row.mediaType,
              filename: row.filename,
              mediaUrl: row.uploadId ? undefined : row.mediaUrl,
            }))
            .filter(row => row.story || row.filename || row.uploadId || row.mediaUrl || row.videoUrl),
          post_id: postId,
          upload_id: uploadId,
          delete_media: mediaDeleted && !videoURL,
          draft: isDraft,
          warned: warned,
          meetup: meetup,
          video_url,
        },
      })
    }
    if (response.data?.editPost?.error?.code === 'sensitiveContent') {
      setShowSensitiveWarning(true)
      setConfirmationMessage(response.data?.editPost?.error?.message ?? undefined)
      setDisableEdit(false)
    } else if (response.data?.editPost?.error) {
      setShowToast(true)
      setToastMessage(response.data?.editPost?.error.message ?? '')
    } else {
      setSelectedUser(undefined)
      handleOnDone(!isDraft)
      if (isDraft) resetScroll?.()
    }
    setDisableEdit(false)
  }

  const client = useApolloClient()
  const [hidePost] = useSetHidePostMutation({
    update(cache, { data }) {
      const oldCount = cache.readQuery<GQLCanAddContentQuery>({
        query: CanAddContentDocument,
        variables: { communityId: post?.communityId ?? '' },
      })

      const oldData = oldCount?.canAddContent
      const newCount = (oldData?.count ?? 0) - (data?.setHidePost?.post?.draft ? 0 : 1)

      const newCountData = {
        canAddContent: {
          ok: true,
          count: newCount,
          max: oldData?.max ?? 42,
          __typename: 'ContentValidator',
        },
      }

      client.writeQuery({
        query: CanAddContentDocument,
        variables: { communityId: post?.communityId ?? '' },
        data: newCountData,
      })

      const oldPostsData = cache.readQuery<GQLGetPostsQuery>({
        query: GetPostsDocument,
        variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
      })

      const newPostsData = {
        posts: {
          ...oldPostsData?.posts,
          pageInfo: {
            ...oldPostsData?.posts?.pageInfo,
          },
          edges: [
            ...(oldPostsData?.posts?.edges.filter(
              // hidden posts should still show up for admin
              n => actingSysAdmin || n?.node?.postId != data?.setHidePost?.post?.postId
            ) ?? []),
          ],
        },
      }

      client.writeQuery({
        query: GetPostsDocument,
        variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
        data: newPostsData,
      })

      if (post?.contentTitle) {
        const oldContentData = cache.readQuery<GQLGetContentsQuery>({
          query: GetContentsDocument,
          variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
        })

        const newContentData = {
          posts: {
            ...oldContentData?.posts,
            edges: [
              ...(oldContentData?.posts?.edges.filter(
                // hidden posts should still show up for admin
                n => actingSysAdmin || n?.node?.postId != data?.setHidePost?.post?.postId
              ) ?? []),
            ],
          },
        }

        client.writeQuery({
          query: GetContentsDocument,
          variables: { communityId: post?.communityId ?? '', userId: authUserId ?? '' },
          data: newContentData,
        })
      }
    },
  })

  const [madeChanges, setMadeChanges] = useState(false)
  const { setDraftingPost } = useDraftingCommentPost()

  const handleOnDone = (shouldCollapse: boolean) => {
    setDraftingPost?.(postId, false, true)
    onDone(shouldCollapse)
  }
  const handleCancel = () => {
    if (madeChanges) setShowCancelDialog(true)
    else {
      handleOnDone(true)
    }
  }

  const navigate = useNavigate()
  const handleConfirmDelete = async (e: SyntheticEvent) => {
    await hidePost({ variables: { id: postId, value: true } })
    onClickDeleteDraft?.()
    setShowDeleteDialog(false)
    onDone(true)
    elementClicked(e, 'click-post-draft-delete', {
      postId: postId,
    })
    if (isSinglePost && postListPath) {
      navigate(postListPath)
    }
  }

  const [showChangeAuthor, setShowChangeAuthor] = useState(false)
  const {
    selectedUser: pendingSelectedUser,
    setSelectedUser: setPendingSelectedUser,
    hasPendingUser,
    setHasPendingUser,
  } = useAddPersonBox()
  const [selectedUser, setSelectedUser] = useState<SelectedUser | undefined>(undefined)
  const [addEmailText, setAddEmailText] = useState<string>('')

  const saveAuthorSelection = () => {
    setSelectedUser(pendingSelectedUser)
    setShowChangeAuthor(false)
  }

  const cancelAuthorSelection = () => {
    setPendingSelectedUser?.(selectedUser)
    setShowChangeAuthor(false)
    setHasPendingUser?.(Boolean(selectedUser))
  }

  const onChangeContentTitle = (s: string) => {
    setContentTitle(s)
    setContentTitleLength(s.length)
    setDraftingPost?.(`content_title:${postId ?? ''}`, s != (post?.contentTitle ?? ''), false)
  }

  if (!authUserId) return <></>
  return (
    <>
      <div
        className={`authoring-area post editing expanded ${isVeevan ? 'extra-padding' : ''} ${
          post?.draft ? 'draft' : ''
        }`}
        data-testid={'post-edit'}
      >
        <div className={'author-wrapper'}>
          <ProfilePhoto userId={selectedUser ? selectedUser.userId : post?.createdById} />
          {isContent && actingSysAdmin && (
            <button
              onClick={() => setShowChangeAuthor(true)}
              className={`change-author-button ${showChangeAuthor ? 'clicked' : ''}`}
            >
              <i>Change Author</i>
            </button>
          )}
        </div>
        <div className={'authoring-form'}>
          {isContent ? (
            <div className={'content-title-wrapper adding'}>
              <PlainTextInput
                className="content-title form-control"
                placeholder={'Content Title (this title appears on the content tab, required)'}
                onChange={onChangeContentTitle}
                inputRef={contentInput}
                value={contentTitle}
              />
              <span
                className={'content-character-counter' + content_title_severity}
                id={'content-title-character-counter'}
              >
                {contentTitleLength < 10 ? `0${contentTitleLength}` : contentTitleLength} / {MAX_TITLE_LENGTH}
              </span>
            </div>
          ) : (
            <>
              <div className={'post-title-container'}>
                <QuillEditor<Maybe<GQLHtmlWithMentions>>
                  className="title"
                  toolbar={QuillToolbar.None}
                  placeholder="Post Title (this title appears in the list of posts, required)"
                  initialHtml={title}
                  setHtml={setTitle}
                  setTextLength={setTitleLength}
                  onKeyDown={() => setMadeChanges(true)}
                  allowMentions={true}
                  communityId={post?.communityId}
                  preventLineBreak={true}
                  allowLinks={false}
                  postId={postId}
                  isTitle={true}
                />
              </div>
              <span className={'character-counter' + title_severity} id={'post-title-character-counter'}>
                {titleLength < 10 ? `0${titleLength}` : titleLength} / {MAX_TITLE_LENGTH}
              </span>
            </>
          )}
          {videoURL ? (
            <div className={'upload-zone'}>
              <AddMediaTarget
                directURLUpload={true}
                uploadURL={videoURL}
                uploadMediaType={GQLMediaType.Video}
                clearUpload={() => setVideoURL(null)}
                setMadeChanges={() => setMadeChanges(true)}
              />
            </div>
          ) : (
            <UploadZone
              setDirectUrlUpload={setVideoURL}
              directUrlUpload={videoURL}
              setMadeChanges={() => setMadeChanges(true)}
            />
          )}
          {isContent && (
            <div className={'post-title-wrapper'}>
              <div className={'post-title-container'}>
                <QuillEditor<Maybe<GQLHtmlWithMentions>>
                  className="content-regular-title title"
                  toolbar={QuillToolbar.None}
                  placeholder="Post Title (this title appears in the list of posts, required)"
                  initialHtml={title}
                  setHtml={setTitle}
                  setTextLength={setTitleLength}
                  onKeyDown={() => setMadeChanges(true)}
                  allowMentions={true}
                  communityId={post?.communityId}
                  preventLineBreak={true}
                  allowLinks={false}
                  postId={postId}
                  isTitle={true}
                />
              </div>
              <span className={'post-title-character-counter' + title_severity} id={'post-title-character-counter'}>
                {titleLength < 10 ? `0${titleLength}` : titleLength} / {MAX_TITLE_LENGTH}
              </span>
            </div>
          )}
          {
            <div className={'message-area'}>
              <MultipleEditor
                placeholder={
                  isContent ? 'Add Post Message (optional)' : 'Message: Share your question, idea, or comment'
                }
                rowsData={rowsData}
                originalRowsData={originalStoryRows}
                setRowsData={setRowsData}
                communityId={communityId ?? post?.communityId ?? ''}
                onSubmit={doSave}
                setMeetup={setMeetup}
                postId={postId}
                allowVideoTimestamps={
                  post?.mediaType === GQLMediaType.Video || mediaType === GQLMediaType.Video || !!videoURL
                }
                primaryVideoUrl={post?.mediaType === GQLMediaType.Video ? post?.mediaUrl : null}
              />
            </div>
          }
          {
            <div className={`button-container ${post?.draft ? '' : 'published'}`}>
              {post?.draft && (
                <div className={'button-zone'}>
                  <Button
                    variant="outline-secondary"
                    disabled={postDisabled}
                    className={'save-draft'}
                    onClick={e => {
                      doSave({ skipWarning: true, warned: true, e: e, isDraft: true, meetup: meetup }).then()
                      elementClicked(e, 'click-post-draft-save', { communityId: communityId, postId: postId })
                    }}
                    role="button"
                  >
                    <span>Save Draft</span>
                  </Button>
                  <Button
                    variant="light"
                    disabled={postDisabled}
                    className={'delete-draft'}
                    onClick={e => {
                      e.currentTarget.blur()
                      setShowDeleteDialog(true)
                    }}
                    role="button"
                  >
                    <span>Delete Draft</span>
                  </Button>
                </div>
              )}
              <div className={'button-zone'}>
                <Button
                  variant="light"
                  size="sm"
                  onClick={e => {
                    e.currentTarget.blur()
                    handleCancel()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={postDisabled}
                  size="sm"
                  onClick={e => {
                    doSave({ e: e, isDraft: false, meetup: meetup }).then()
                    const logName = post?.draft ? 'click-post-draft-post' : 'click-post-update'
                    elementClicked(e, logName, { communityId: communityId, postId: postId })
                  }}
                  data-testid={'update-btn'}
                >
                  {post?.draft ? 'Post' : 'Update'}
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
      <Modal show={showCancelDialog} onHide={() => setShowCancelDialog(false)} className={'edit'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Discard changes to {post?.draft ? 'draft' : 'post'}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowCancelDialog(false)}>
            No
          </Button>
          <Button variant="primary" size="sm" onClick={() => handleOnDone(true)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)} className={'delete'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>Are you sure you want to delete this draft?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowDeleteDialog(false)}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastComponent onClose={() => setShowToast(false)} show={showToast} bg={'success'}>
        {toastMessage}
      </ToastComponent>
      <ToastComponent
        onClose={() => setShowMediaToast?.(false)}
        show={showMediaToast ?? false}
        bg={isToastError ? 'danger' : 'success'}
      >
        {mediaToast ?? ''}
      </ToastComponent>
      <PostConfirmationModal
        show={showConfidentialWarning}
        hide={() => {
          setShowConfidentialWarning(false)
          setDisableEdit(false)
        }}
        onSubmit={() => {
          doSave({ skipWarning: true, meetup: meetup }).then()
        }}
        submitText={'Update'}
      />
      <PostConfirmationModal
        show={showSensitiveWarning}
        hide={() => {
          setShowSensitiveWarning(false)
          setDisableEdit(false)
        }}
        onSubmit={e => {
          doSave({ skipWarning: true, warned: true, e: e, meetup: meetup }).then()
        }}
        submitText={'Update'}
        message={confirmationMessage ?? undefined}
      />
      <Modal className={'change-author-modal'} show={showChangeAuthor} onHide={() => setShowChangeAuthor(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <h1>Change Author</h1>
          <h2>Author</h2>
          <p>
            <i>This user will appear as the author of the post</i>
          </p>
          <AddPersonBox
            searchText={addEmailText}
            placeholder={'Enter email address or name'}
            onTextChange={setAddEmailText}
            veevansOnly={true}
            communityId={''}
            leaders={false}
            showExcluded={true}
            disabled={false}
            hideAddButton={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={cancelAuthorSelection}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveAuthorSelection} disabled={!hasPendingUser}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
