import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '~/auth/Auth'
import { useCommunity } from '~/contexts/CommunityContext'
import { Button, Modal } from 'react-bootstrap'
import { useAddLeaderMutation, useAddRoleMutation, useGetUserCommunitiesQuery } from '~/api/generated/graphql'
import { useNavigate } from 'react-router'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { updateLeadersCache } from '~/utils'
import { elementClicked } from '~/common/EventLogger'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'

const AccountPartnerJoin = () => {
  const { authUserId, isVeevan, profileVisible } = useAuth()
  const { communityId, companyId } = useCommunity()
  const { reloadHomepages } = useCommunities()
  const navigate = useNavigate()
  const [showAccountPartnerModal, setShowAccountPartnerModal] = useState<boolean>(false)
  const [addLeader] = useAddLeaderMutation({
    update: (cache, { data: addLeader }) => {
      updateLeadersCache(cache, addLeader?.addLeader?.data)
    },
  })
  const { data } = useGetUserCommunitiesQuery({ variables: { id: authUserId ?? '' }, skip: !authUserId })
  const leadershipIds = useMemo(
    () => data?.user?.memberships?.edges?.filter(e => e?.node?.leader).map(e => e?.node?.communityId) || [],
    [data]
  )
  const isLeader = useMemo(() => leadershipIds.some(c => c === communityId), [leadershipIds, communityId])

  const handleAccountPartnerAdd = async () => {
    const response = await addLeader({
      variables: {
        communityId: communityId ?? '',
        userId: authUserId ?? '',
      },
    })
    if (response?.data?.addLeader?.ok) {
      if (companyId) {
        doAddRole().then()
      }
      reloadHomepages()
      navigate('../partners')
    }
    setShowAccountPartnerModal(false)
  }

  const [addRole] = useAddRoleMutation()
  const doAddRole = async () => {
    await addRole({
      variables: {
        userId: authUserId ?? '',
        companyId: companyId ?? '',
        description: '',
      },
    })
  }

  const accountPartnerRef = useClickOnEnter<HTMLImageElement>()

  if (!profileVisible) return null

  return (
    <div>
      {isVeevan &&
        (isLeader ? (
          <p>
            To manage members, go to the{' '}
            <Link to={'../members'} className={'partner-join-link'}>
              Members tab
            </Link>
          </p>
        ) : (
          <p tabIndex={0}>
            You can{' '}
            <span
              className={'partner-join-link link'}
              onClick={e => {
                setShowAccountPartnerModal(true)
                elementClicked(e, 'click-community-partners-add-self', { communityId: communityId })
              }}
              role={'button'}
              tabIndex={0}
              ref={accountPartnerRef}
            >
              add yourself as an account partner
            </span>
          </p>
        ))}
      <Modal show={showAccountPartnerModal} onHide={() => setShowAccountPartnerModal(false)}>
        <Modal.Body>Are you sure you want to add yourself as an Account Partner?</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowAccountPartnerModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAccountPartnerAdd}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AccountPartnerJoin
