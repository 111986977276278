import React, {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import '@css/common/QuillEditor.scss'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import * as ReactDOM from 'react-dom/client'
import { Root } from 'react-dom/client'
import {
  GQLHtmlWithMentions,
  GQLMeetup,
  GQLMeetupInput,
  Maybe,
  useGetHostnamesQuery,
  useGetMeetupQuery,
  useGetMentionedUserQuery,
  useRegisterMeetupAttendeeMutation,
} from '~/api/generated/graphql'
import { asString, getVideoStartTime, getWistiaId, isSafeLink, updateWistiaLink } from '~/utils'
import { EmployeeBadgeComponent } from '~/common/EmployeeBadgeComponent'
import { ApolloClient, ApolloProvider, useApolloClient } from '@apollo/client'
import '@css/common/MentionEmployeeBadgeComponent.scss'
import { useNavigate } from 'react-router'
import { VideoContent } from '~/pages/posts/MediaComponent'
import { AuthProvider, useAuth } from '~/auth/Auth'
import { WindowProvider } from '~/common/hooks/useWindowSize'
import { elementClicked } from '~/common/EventLogger'
import ToastComponent from '~/common/ToastComponent'

type Props = {
  expandableRef?: React.RefObject<HTMLDivElement>
  className?: string
  value?: Maybe<GQLHtmlWithMentions>
  postHeader?: boolean
  meetup?: GQLMeetup
  commentId?: string
  postId?: string
  primaryVideoUrl?: Maybe<string>
  repostId?: Maybe<string>
}

// The Overlay base types have explicit any in the base definition, so we have to carry them through here and
// disable the lint check

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RenderedTooltip = forwardRef<any, any & { userId: string; clickEmployee: () => void }>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ userId, clickEmployee, ...props }: { userId: string; clickEmployee: () => void } & any, ref) => {
    return (
      <Popover ref={ref} {...props}>
        <Popover.Body className={'mention-tooltip-body'}>
          {<EmployeeBadgeComponent userId={userId} isMention={true} clickEmployee={clickEmployee} />}
        </Popover.Body>
      </Popover>
    )
  }
)

const MentionButton = ({
  dataId,
  dataValue,
  atChar,
  clickMention,
  showHover,
  link,
}: {
  dataValue: string
  dataId: string
  atChar: string
  clickMention: () => void
  showHover: boolean
  link: string
}) => {
  const { data, loading } = useGetMentionedUserQuery({
    variables: {
      id: dataId,
    },
    skip: dataValue === 'All',
  })
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    clickMention()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const overlay = (props: any) => {
    return <RenderedTooltip userId={dataId} clickEmployee={clickMention} {...props} />
  }

  const button = !data?.user?.hidden ? (
    <a href={link} data-testid={'at-mention-link'}>
      <Button onClick={handleClick} variant={'link'} className={'mention-link'}>
        {atChar}
        {dataValue}
      </Button>
    </a>
  ) : (
    <div data-testid={'at-mention-link'}>{`@${dataValue}`}</div>
  )
  if (loading) return <div data-testid={'at-mention-link'}>{`@${dataValue}`}</div>
  // noinspection RequiredAttributes
  return showHover && !data?.user?.hidden ? (
    <OverlayTrigger placement="right" delay={{ show: 250, hide: 800 }} overlay={overlay}>
      {button}
    </OverlayTrigger>
  ) : (
    button
  )
}

type LinkWithSafetyProps = {
  href: string
  text: string
  domain: string
  displayLink: string
  isLongLink: boolean
  veevaDomains: string[]
  isSameHost: boolean
}

const LinkWithSafety = ({
  href,
  text,
  displayLink,
  isLongLink,
  veevaDomains,
  domain,
  isSameHost,
}: LinkWithSafetyProps) => {
  const isUnsafeLink = href != text && !isSafeLink(domain, veevaDomains)
  const showSafetyWarning = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const LinkSafetyTooltip = forwardRef<any, any & { href: string; isSameHost: boolean }>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ href, isSameHost, ...props }: { href: string; isSameHost: boolean } & any, ref) => {
      return (
        <Popover ref={ref} {...props} className={'link-safety-popover'}>
          <Popover.Body className={'link-safety-tooltip-body'}>
            <span>
              <div className={'url'}>Visit URL:&nbsp;</div>
              <a href={href} target={isSameHost ? '' : '_blank'}>
                {displayLink}
                {isLongLink ? '/...' : ''}
              </a>
            </span>
            <div>External site</div>
          </Popover.Body>
        </Popover>
      )
    }
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const safetyOverlay = (props: any) => {
    return <LinkSafetyTooltip href={href} isSameHost={isSameHost} {...props} />
  }

  // noinspection RequiredAttributes
  return isUnsafeLink ? (
    <OverlayTrigger
      placement="bottom-start"
      overlay={safetyOverlay}
      trigger={['click', 'focus']}
      rootClose
      offset={[-1, 2]}
    >
      <span onClick={showSafetyWarning} data-testid={'mentionable-text-link'}>
        {text}
      </span>
    </OverlayTrigger>
  ) : (
    <span data-testid={'mentionable-text-link'}>{text}</span>
  )
}

type LinkWithVideoPopoverProps = {
  client: ApolloClient<object>
  text: string
  wistiaId: string
  startTime: number
  postId?: string
}

const LinkWithVideoPopover = ({ client, text, wistiaId, startTime, postId }: LinkWithVideoPopoverProps) => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <WindowProvider>
          <VideoContent
            url={`https://fast.wistia.com/embed/medias/${wistiaId}.jsonp`}
            post_id={postId ?? ''}
            uploading={false}
            playButton={<span onClick={e => e.preventDefault()}>{text}</span>}
            startTime={startTime}
          />
        </WindowProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

const MeetupButton = ({ meetupId, postId, commentId }: { meetupId?: string; postId?: string; commentId?: string }) => {
  const { authUserId } = useAuth()
  const [registerMeetupAttendee, { loading: isRegistering }] = useRegisterMeetupAttendeeMutation()
  const { data } = useGetMeetupQuery({ variables: { id: meetupId ?? '' } })
  const meetup = data?.meetup
  const [showMeetupError, setShowMeetupError] = useState(false)
  const [showOrganizerModal, setShowOrganizerModal] = useState<boolean>(false)
  const [showInviteSentModal, setShowInviteSentModal] = useState<boolean>(false)

  const handleMeetupClicked: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e?: SyntheticEvent) => {
      if (meetup?.organizerId === authUserId) {
        setShowOrganizerModal(true)
      } else if (!meetup?.currentUserRegistered) {
        if (e) {
          elementClicked(e, 'click-meetup-register', { postId: postId, commentId: commentId })
        }
        registerMeetupAttendee({
          variables: {
            meetup: {
              gid: meetup?.gid,
              startTime: meetup?.startTime,
              link: meetup?.link,
            } as GQLMeetupInput,
            postId: postId,
            commentId: commentId,
          },
        }).then(r => {
          if (r.data?.registerMeetupAttendee?.error) {
            if (r?.data.registerMeetupAttendee?.error?.code === 'notInFuture') {
              setShowMeetupError(true)
            }
          }
        })
      } else {
        setShowInviteSentModal(true)
      }
    },
    [authUserId, commentId, meetup, postId, registerMeetupAttendee]
  )
  return (
    <>
      <button
        data-testid={`mention-btn ${meetup?.organizerId}`}
        className={`btn-plain ${meetup?.currentUserRegistered && 'registered'}`}
        onClick={handleMeetupClicked}
        disabled={isRegistering}
      >
        <div className={`btn-title`} />
        <span>
          {isRegistering ? 'Registering...' : meetup?.currentUserRegistered ? 'Invite sent!' : 'Click to register!'}
        </span>
      </button>
      {meetup?.attendeeCount ? (
        <span className="attendee-counts">
          {meetup?.attendeeCount}
          {` ${meetup?.attendeeCount === 1 ? 'customer' : 'customers'} attending`}
        </span>
      ) : (
        ''
      )}
      <ToastComponent
        bg={'danger'}
        show={showMeetupError}
        onClose={() => {
          setShowMeetupError(false)
        }}
      >
        The meetup has already occurred
      </ToastComponent>
      <Modal className={'organizer-modal'} show={showOrganizerModal} onHide={() => setShowOrganizerModal(false)}>
        <Modal.Header>Since you're the organizer, you can't send yourself an invite!</Modal.Header>
        <Modal.Body>
          <p>When a customer clicks the button, they will get a Google invite sent on your behalf.</p>
          <a href={meetup?.link} target={'_blank'}>
            View meeting in your Google calendar
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setShowOrganizerModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showInviteSentModal} onHide={() => setShowInviteSentModal(false)}>
        <Modal.Body>
          <p>An invitation was sent to your email from the organizer.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={'primary'} onClick={() => setShowInviteSentModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const MentionableText = ({
  expandableRef,
  className = '',
  value,
  postHeader,
  meetup,
  commentId,
  postId,
  primaryVideoUrl,
  repostId,
}: Props) => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const [roots, setRoots] = useState<Root[]>()
  const divRef = useRef<HTMLDivElement>(null)
  const [hasSetInnerHTML, setHasSetInnerHTML] = useState<boolean>(false)

  const { data: hostnamesData, loading: loadingHostnames } = useGetHostnamesQuery()
  const veevaDomains = useMemo(() => {
    if (loadingHostnames) return undefined
    return (hostnamesData?.hostnames?.edges.map(e => e?.node?.name) as string[]) ?? []
  }, [hostnamesData, loadingHostnames])

  useEffect(() => {
    if (divRef.current && hasSetInnerHTML && veevaDomains) {
      const mentions = divRef.current.getElementsByClassName('mention')
      const meetups = divRef.current.getElementsByTagName('meetup')
      const links = Array.from(divRef.current.getElementsByTagName('a'))

      const mentionRoots = Array.from(mentions).map(m => {
        const dataLink = m.getAttribute('data-link') || ''
        const dataId = m.getAttribute('data-id') || ''
        const dataValue = m.getAttribute('data-value') || ''
        const atChar = m.getAttribute('data-denotation-char') || ''

        const nav = () => {
          navigate(dataLink)
        }
        const container = (
          <ApolloProvider client={client}>
            <MentionButton
              link={dataLink}
              clickMention={nav}
              dataId={dataId}
              dataValue={dataValue}
              atChar={atChar}
              showHover={dataLink.includes('/profiles')}
            />
          </ApolloProvider>
        )

        const root = ReactDOM.createRoot(m)

        root.render(container)
        return root
      })

      const meetupRoots = Array.from(meetups).map(m => {
        const container = (
          <ApolloProvider client={client}>
            <AuthProvider>
              <MeetupButton meetupId={meetup?.meetupId ?? ''} postId={postId} commentId={commentId} />
            </AuthProvider>
          </ApolloProvider>
        )
        const root = ReactDOM.createRoot(m)

        root.render(container)
        return root
      })

      const linkRoots = links.map(link => {
        const href = link.getAttribute('href') ?? ''
        const text = link.text ?? href
        const displayLink = `${link.protocol ? link.protocol + '//' : ''}${link.hostname}` ?? href
        // determines whether or not we have an ellipsis after the display link
        const isLongLink = Boolean(link.port || link.pathname != '/' || link.search || link.hash)
        // if the link is within the current hostname we want to open it in the same tab
        const isSameHost = window.location.host == `${link.hostname}${link.port ? ':' + link.port : ''}`
        link.target = isSameHost ? '' : '_blank'

        const linkPath = link.pathname + link.search
        const videoScanMatches = linkPath.match(
          /\/communities\/[\w-]+\/posts\/(?<postId>[\w-]+)\?([\S]+)?t=(?<timestamp>\d\d:\d\d:\d\d)/
        )
        let container: JSX.Element

        const updatedUrl = updateWistiaLink(primaryVideoUrl)
        const wistiaId = getWistiaId(updatedUrl)
        if (
          videoScanMatches?.groups?.timestamp &&
          wistiaId &&
          (videoScanMatches.groups.postId === postId || videoScanMatches.groups.postId === repostId)
        ) {
          container = (
            <LinkWithVideoPopover
              client={client}
              text={text}
              wistiaId={wistiaId}
              startTime={getVideoStartTime(videoScanMatches.groups.timestamp) ?? 0}
              postId={postId}
            />
          )
        } else {
          container = (
            <LinkWithSafety
              href={href}
              text={text}
              displayLink={displayLink}
              isLongLink={isLongLink}
              veevaDomains={veevaDomains}
              domain={link.hostname}
              isSameHost={isSameHost}
            />
          )
        }

        const root = ReactDOM.createRoot(link)
        root.render(container)

        return root
      })

      setRoots(mentionRoots.concat(linkRoots).concat(meetupRoots))
    }
  }, [
    setRoots,
    client,
    navigate,
    hasSetInnerHTML,
    veevaDomains,
    postId,
    commentId,
    meetup?.meetupId,
    primaryVideoUrl,
    repostId,
  ])

  // Called when layout changes
  useLayoutEffect(() => {
    return () => {
      if (roots) {
        roots.forEach(r => {
          // unmount the root if the parent isn't attached to the document, but defer it after we finish the layout
          // changes that do the detachment
          window.setTimeout(() => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (!divRef.current?.parentElement) {
              r.unmount()
            }
          }, 0)
        })
      }
    }
  })

  const savedExpandTitle = expandableRef?.current?.title ?? ''
  // Workaround for tooltip behavior on Safari
  const toggleParentHoverText = (hide: boolean) => {
    if (expandableRef?.current && hide) {
      expandableRef.current.title = ''
    }
    if (expandableRef?.current && !hide) {
      expandableRef.current.title = savedExpandTitle
    }
  }

  useEffect(() => {
    if (divRef.current && !hasSetInnerHTML) {
      divRef.current.innerHTML = asString(value) ?? ''
      // without hasSetInnerHTML check, creation of roots is overridden by calling this every time
      setHasSetInnerHTML(true)
    }
  }, [value, hasSetInnerHTML])
  return (
    <div
      className={`${className} mentionable-text ${postHeader ? 'feed-post-header' : ''}`}
      ref={divRef}
      title={''} // Don't inherit parent's title value
      onMouseEnter={() => toggleParentHoverText(true)}
      onMouseLeave={() => toggleParentHoverText(false)}
      dangerouslySetInnerHTML={{ __html: asString(value) ?? '' }}
    />
  )
}
export default MentionableText
