import React, { useState } from 'react'
import '@css/common/AddMultipleResults.scss'
import { GQLErrorPartsFragment } from '~/api/generated/graphql'
import { useCommunity } from '~/contexts/CommunityContext'
import { Button } from 'react-bootstrap'

enum ResultType {
  failed = 'failed',
  skipped = 'skipped',
  succeeded = 'succeeded',
}

export type AddEmailsResult = { email: string; error: GQLErrorPartsFragment; isInvite: boolean }

type AddMultipleResultsProps = {
  results: AddEmailsResult[]
  newMemberCount: number
}

const AddMultipleResults = ({ results, newMemberCount }: AddMultipleResultsProps) => {
  const { companyName } = useCommunity()
  const [copied, setCopied] = useState<boolean>(false)

  const getResultType = (result: AddEmailsResult) => {
    if (result.error) {
      switch (result.error.code) {
        case 'alreadyAMember':
        case 'alreadyInvited':
        case 'alreadyInvitedByOther':
          return ResultType.skipped
        default:
          return ResultType.failed
      }
    }
    return ResultType.succeeded
  }

  const getResultText = (result: AddEmailsResult) => {
    const domain = result.email.split('@')[1]
    if (result.error) {
      switch (result.error.code) {
        case 'notACustomer':
          return `Skipped: The domain ${domain} is not recognized as a customer or prospective customer.`
        case 'ineligibleMember':
          return `Skipped: The domain ${domain} does not belong to Veeva or ${companyName}.`
        case 'badEmail':
          return 'Skipped: Invalid email address.'
        case 'noOrgwikiUser':
          return 'Skipped: This email address does not match anyone in OrgWiki.'
        case 'partnerDomain':
          return 'Skipped: This is an email address for a partner company. Partners do not have access to Veeva Connect.'
        case 'publicDomain':
          return "Skipped: This is a non-work email address. Please enter the person's work email."
        case 'blockedSubdomain':
          return `Skipped: The domain ${domain} is an email sub-domain that is not authorized.`
        case 'competitorDomain':
          return `Skipped: The domain ${domain} is an email domain that is associated with a Veeva competitor.`
        case 'hiddenUser':
          return 'Skipped: This user cannot be added to the community.'
        case 'alreadyAMember':
          return 'Skipped: This user is already a member.'
        case 'alreadyInvited':
          return 'Skipped: You have already invited this user to the community.'
        case 'alreadyInvitedByOther':
          return 'Skipped: This user was invited to this community within the last seven days.'
        default:
          // fallback
          return 'Skipped: There was an issue adding this user to the community.'
      }
    }
    if (result.isInvite) {
      return 'Email sent to new user. You will be notified when this user joins Veeva Connect.'
    } else {
      return 'Email sent and this existing user has been added to the community.'
    }
  }

  const copyResults = () => {
    const excelData = results.map(r => `${r.email}\t${getResultText(r)}`).join('\n')
    navigator.clipboard.writeText(excelData).then(() => setCopied(true))
  }

  return (
    <>
      <div className={'add-multiple-results'}>
        {!!newMemberCount && <h3>Congrats! You've added {newMemberCount} new members!</h3>}
        <div className={'results-summary'}>
          {results.map(r => (
            <div key={r.email} className={`email-result-row ${getResultType(r)}`}>
              <div className={'email'}>{r.email}</div>
              <div className={'result'}>{getResultText(r)}</div>
            </div>
          ))}
        </div>
        <Button className={`copy-results${copied ? ' checked' : ''}`} variant={'light'} onClick={copyResults}>
          Copy Results
        </Button>
      </div>
    </>
  )
}

export default AddMultipleResults
