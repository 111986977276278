import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import '@css/pages/posts/RepostOverlay.scss'
import { Link } from 'react-router-dom'
import CommunityPost from '~/pages/community/CommunityPost'
import { RepostSelectionRow } from '~/pages/posts/RepostSelectionRow'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { Event, Post, RepostCommunity } from '~/types'
import { asDate, getEventPath, getPostPath, getStartOfCurrentDay } from '~/utils'
import CommunityEventRow from '~/pages/community/CommunityEventRow'
import {
  GQLHtmlWithMentions,
  useGetEventCommunityQuery,
  useGetEventQuery,
  usePostLinkQuery,
} from '~/api/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import MentionableText from '~/common/MentionableText'
import { useAuth } from '~/auth/Auth'

export enum RepostType {
  POST,
  EVENT,
}

export const EventRepostLink = ({ eventId }: { eventId: Maybe<string> }) => {
  const { data: eventData } = useGetEventQuery({ variables: { id: eventId ?? '' }, skip: !eventId })
  const eventItem = eventData?.event
  const { data: repostCommunity } = useGetEventCommunityQuery({
    variables: { id: eventItem?.communityId ?? '' },
    skip: !eventItem?.communityId,
  })
  return (
    <Link key={eventItem?.eventId} to={getEventPath(repostCommunity?.community, eventId ?? '')} className={'link'}>
      {repostCommunity?.community?.name}
    </Link>
  )
}

type RepostOverlayProps = {
  fromPage: string
  title?: string
  postTitle?: GQLHtmlWithMentions
  postReposts?: Post[]
  eventReposts?: Event[]
  repostType: RepostType
  repostCommunities: RepostCommunity[]
  originalPostId?: string
  isContent?: boolean
  refetchReposts?: () => void
  repostedComms: string[]
  setRepostedComms: Dispatch<SetStateAction<string[]>>
  originalEvent?: Maybe<{ eventId?: string; communityId?: Maybe<string>; eventEnd?: Maybe<string> }>
}

const PostLink = ({ post }: { post: Post }) => {
  const { data } = usePostLinkQuery({ variables: { id: post.communityId } })
  if (!data) return null
  return (
    <Link key={post.postId} to={getPostPath(data.community, post)} className={'link'}>
      {data.community?.name}
    </Link>
  )
}

const RepostOverlay = ({
  fromPage,
  title,
  postTitle,
  postReposts,
  eventReposts,
  repostType,
  repostCommunities,
  originalPostId,
  isContent,
  refetchReposts,
  repostedComms,
  setRepostedComms,
  originalEvent,
}: RepostOverlayProps) => {
  const { isCondensed } = useWindowSize()
  const { isVeevan } = useAuth()

  const repostObject = repostType == RepostType.POST ? 'post' : 'event'

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false)

  const handleExpansionClick = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const repostedDisplayRef = useCallback(
    (node: HTMLDivElement | null) => {
      if ((node?.scrollHeight ?? 0) > (node?.offsetHeight ?? 0) || (node?.clientHeight && node?.clientHeight > 72)) {
        setShowMoreButton(true)
      } else {
        setShowMoreButton(false)
      }
    },
    [setShowMoreButton]
  )

  const getReposts = () => {
    switch (repostType) {
      case RepostType.POST:
        return postReposts ?? []
      case RepostType.EVENT:
        return eventReposts ?? []
      default:
        return []
    }
  }

  const reposts = getReposts()
  const getRepostLink = (index: number) => {
    if (repostType == RepostType.POST && postReposts?.[index]) {
      const post = postReposts[index]
      return <PostLink post={post} />
    } else if (repostType == RepostType.EVENT && eventReposts?.[index]) {
      const eventItem = eventReposts[index]
      return <EventRepostLink eventId={eventItem.eventId} />
    }
  }

  const repostedList = () => {
    if (reposts.length === 1) {
      return (
        <>
          This {repostObject} has been reposted in {getRepostLink(0)}.
        </>
      )
    } else if (reposts.length === 2) {
      return (
        <>
          This {repostObject} has been reposted in {getRepostLink(0)} and {getRepostLink(1)}.
        </>
      )
    }

    const repostLinks: Maybe<JSX.Element>[] = []
    for (let i = 0; i < reposts.length; i++) {
      const id = repostType == RepostType.POST ? postReposts?.[i]?.postId : eventReposts?.[i]?.eventId
      if (i === reposts.length - 1) {
        repostLinks.push(<span key={`and-${id}`}>and </span>)
        repostLinks.push(getRepostLink(i))
        repostLinks.push(<span key={`period-${id}`}>.</span>)
      } else {
        repostLinks.push(getRepostLink(i))
        repostLinks.push(<span key={`comma-${id}`}>, </span>)
      }
    }
    return (
      <>
        This {repostObject} has been reposted in {repostLinks}
      </>
    )
  }

  const handleRepostChange = (commId: string) => {
    const newComms = repostedComms
    newComms.push(commId)
    setRepostedComms(newComms)
    refetchReposts?.()
  }

  const backLink = '../../' + (repostType == RepostType.EVENT ? '../' : '')
  const isUpcomingEvent =
    (asDate(originalEvent?.eventEnd ?? undefined) ?? new Date()) > (asDate(getStartOfCurrentDay()) ?? new Date())

  if (!isVeevan) {
    return null
  }

  return (
    <div className={'post-container'}>
      <div className={`nav-simple-zone${isCondensed ? ' condensed' : ''}`}>
        <Link to={backLink}>&larr; Back to {fromPage}</Link>
      </div>
      <div className={`repost-container`}>
        {title ? (
          <h1 className={'reposting-header'}>
            Reposting {repostType === RepostType.EVENT ? 'event ' : ''}"{title}"
          </h1>
        ) : (
          <h1 className={'reposting-header'}>
            Reposting {repostType === RepostType.EVENT ? 'event ' : ''}"<MentionableText value={postTitle} />"
          </h1>
        )}
        <span className={'preview-tip'}>
          <i>This is how your {repostType == RepostType.EVENT && 'event'} repost will appear:</i>
        </span>
        <div className={'repost-preview'}>
          {repostType == RepostType.POST ? (
            <CommunityPost fromContentPage={false} fromRepostPage={true} />
          ) : (
            originalEvent && (
              <CommunityEventRow
                eventId={originalEvent.eventId}
                canViewInfo={true}
                canEdit={false}
                communityId={originalEvent.communityId ?? ''}
                fromRepostPage={true}
                canRepost={false}
                canDelete={false}
                isVeevan={true}
              />
            )
          )}
        </div>
        <div className={'repost-section'}>
          {reposts.length ? (
            <>
              <div className={`reposted-list${isExpanded ? '' : ' collapsed'}`} ref={repostedDisplayRef}>
                <i>{repostedList()}</i>
              </div>
              {showMoreButton && (
                <button className={'repost-more-button'} onClick={handleExpansionClick}>
                  <span>
                    <i>{isExpanded ? 'Show less' : 'Show more'}</i>
                  </span>
                </button>
              )}
            </>
          ) : (
            <></>
          )}
          {(repostType == RepostType.POST || isUpcomingEvent) && (
            <div className={'repost-in-communities'} data-testid={'repost-in-communities'}>
              <h3 className={'repost-communities-h3'}>Where do you want to repost this {repostObject}?</h3>
              <div className={'header-border'} />
              <>
                {repostCommunities?.map(community => (
                  <div key={community.communityId}>
                    <RepostSelectionRow
                      community={community}
                      postId={originalPostId}
                      eventId={originalEvent?.eventId}
                      isContent={isContent}
                      onRepost={handleRepostChange}
                      repostType={repostType}
                    />
                  </div>
                ))}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RepostOverlay
