import React from 'react'
import { Button, Modal } from 'react-bootstrap'

type UnsavedWarningModalProps = {
  showWarningModal: boolean
  setShowWarningModal: (b: boolean) => void
  continueAction: () => void
  leavingPost: boolean
}

export const UnsavedWarningModal = ({
  showWarningModal,
  setShowWarningModal,
  continueAction,
  leavingPost,
}: UnsavedWarningModalProps) => {
  const warningMessage = leavingPost
    ? 'You have changes in progress. Do you still want to leave this post?'
    : 'You have other changes in progress.'
  const cancelText = leavingPost ? 'Cancel' : 'Go back'
  return (
    <Modal show={showWarningModal} onHide={() => setShowWarningModal(false)} data-testid={'unsaved-warning-modal'}>
      <Modal.Body>
        <p>{warningMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => setShowWarningModal(false)} data-testid={'modal-cancel'}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={continueAction} data-testid={'modal-continue'}>
          Discard Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
