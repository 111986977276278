import { getFullName, getNormalizedTokens, peopleSearchSort, userByNameSort, UserNameFields } from '~/utils'
import {
  GetMentionUserDocument,
  GQLCacheUserFragment,
  GQLCommunityType,
  GQLGetMentionUserQuery,
  GQLUser,
} from '~/api/generated/graphql'
import { Suggestion } from '~/common/quill/QuillEditor'
import { ApolloCache } from '@apollo/client'
import { Maybe } from '@graphql-tools/utils'

const NUM_SUGGESTIONS = 7

const MentionsService = {
  getMentions: (
    searchRemoteUsers: (
      query: string,
      numSuggestions: number,
      veevanOnly?: boolean
    ) => Promise<Promise<Partial<GQLCacheUserFragment>[] | undefined>>,
    search: string,
    renderList: (suggestions: Suggestion[], searchTerm: string) => void,
    cache: ApolloCache<object>,
    basicCommunity?: Maybe<{
      communityId: Maybe<string>
      type: Maybe<GQLCommunityType>
      canEdit?: Maybe<boolean>
      photo?: Maybe<string>
      name?: string
    }>,
    memberIds?: string[],
    veevanOnly?: boolean
  ): void => {
    const names = getNormalizedTokens(search)
    // Ordered list of permitted matches.  Will be supplemented with public matches if community is public
    const prefixCache = window.prefixCache
    const memberIdSet: Set<string> = new Set(memberIds)

    const resultDict = new Map<string, number>()
    names.forEach(tok => {
      if (prefixCache?.has(tok)) {
        prefixCache?.get(tok)?.forEach((match: string) => {
          resultDict.set(match, (resultDict.get(match) || 0) + 1)
        })
      }
    })
    const filteredResults = Array.from(resultDict.keys())
      .filter(k => resultDict.get(k) === names.length)
      .filter(k => k.startsWith('User'))
    // Matched userId to user object
    const result = new Map<string, GQLGetMentionUserQuery['user']>()
    filteredResults.forEach(k => {
      const id = k.split(':')[1]
      const user = cache.readQuery<GQLGetMentionUserQuery>({
        query: GetMentionUserDocument,
        variables: { id },
      })?.user
      if (user) {
        result.set(id, user)
      }
    })
    // If public, add all matches to the permitted match list (after sorting)
    if (basicCommunity?.type === GQLCommunityType.Public) {
      const resultList = Array.from(result.values()) as UserNameFields[]
      resultList.sort(userByNameSort).forEach(u => memberIdSet.add(u.userId))
      // If homepage, add all Veevan matches to the permitted match list
    } else if (basicCommunity?.type === GQLCommunityType.Homepage) {
      const resultList = Array.from(result.values()).filter(u => u?.isVeevan) as UserNameFields[]
      resultList.sort(userByNameSort).forEach(u => memberIdSet.add(u.userId))
    }
    // Drive the suggesting list off of the ordered permitted match list, so it retains priority order (and filters out
    // non-public matches if needed)
    let matchedPeople: Suggestion[] = Array.from(memberIdSet.values())
      .filter(p => result.has(p))
      .map(p => result.get(p))
      .map((u: GQLUser) => {
        return {
          id: u.userId ?? '',
          user: u,
          value: getFullName(u),
          link: `/profiles/${u.userId}`,
          photo: u.photo ?? undefined,
        }
      })
    // Maybe add @Group or @All
    if (basicCommunity && basicCommunity?.canEdit && !veevanOnly) {
      const group = ['All', 'Group'].find(e => e.toLowerCase().startsWith(search.toLowerCase()))
      if (group) {
        matchedPeople.push({
          id: basicCommunity.communityId || '',
          community: basicCommunity,
          link: `/communities/${basicCommunity.communityId}`,
          value: group,
          photo: basicCommunity.photo ?? '',
        })
      }
    }

    matchedPeople = veevanOnly ? matchedPeople.filter(suggestion => suggestion.user?.isVeevan) : matchedPeople
    matchedPeople = matchedPeople
      .filter(
        suggestion => !suggestion.user?.hidden || suggestion?.community?.communityId === basicCommunity?.communityId
      )
      .sort((a, b) => {
        return peopleSearchSort(a, b, search, names, basicCommunity?.communityId || undefined, memberIdSet)
      })
      .slice(0, NUM_SUGGESTIONS)
    renderList(matchedPeople, search)

    if (matchedPeople.length < NUM_SUGGESTIONS) {
      searchRemoteUsers(search, NUM_SUGGESTIONS - matchedPeople.length, veevanOnly)
        .then(users => users)
        .then(users => {
          const remoteMatches: Suggestion[] =
            users
              ?.filter(u => !memberIdSet.has(u.userId ?? ''))
              .map((u: GQLUser) => ({
                id: u.userId ?? '',
                user: u,
                value: getFullName(u),
                link: `/profiles/${u.userId}`,
                photo: u.photo ?? undefined,
              })) ?? []
          matchedPeople.push(...remoteMatches)
          renderList(matchedPeople, search)
        })
    }
  },
}

export default MentionsService
