import { CommunityListView } from '~/types'
import JoinCommunityButton from '~/common/JoinCommunityButton'
import React from 'react'
import { useNavigate } from 'react-router'
import PrivateCommunityIcon from '@web/images/community/PrivateCommunityIcon'

interface CommunityListRowProps {
  community: CommunityListView
  refetch?: () => void
  showPrivateCommIcon?: boolean
  companyId?: string
}

const MobileCommunityListItem = ({ community, refetch, showPrivateCommIcon, companyId }: CommunityListRowProps) => {
  const navigate = useNavigate()
  const navToCommunity = () => {
    const link = community.companyId ? `/companies/${community.companyId}` : `/communities/${community.communityId}`
    navigate(link)
  }

  return (
    <div className={'condensed-community'}>
      <div className={'community-card'}>
        <div className={`condensed-image-with-details`} onClick={navToCommunity} role={'button'}>
          <div className={`profile-photo community-photo ${community.photo ? 'has-photo' : ''}`}>
            <div
              className={`community-photo photo`}
              style={community.photo ? { backgroundImage: `url(${community.photo})` } : {}}
              role={'img'}
              title={community.name}
            />
          </div>
          <div className="community-details" tabIndex={0}>
            <span className="community-name" tabIndex={-1}>
              {community.name}
              {showPrivateCommIcon && community.type === 'Private' && <PrivateCommunityIcon />}
            </span>
            <span className="community-description" tabIndex={-1}>
              {community.description}
            </span>
          </div>
        </div>
        <div className={'button-row'}>
          <JoinCommunityButton
            communityName={community.name}
            communityId={community.communityId}
            onAction={refetch}
            fillFullWidth={true}
            disableLeave={community.companyId == companyId}
          />
        </div>
      </div>
    </div>
  )
}

export default MobileCommunityListItem
