import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { asUser, debounce, getFullName } from '~/utils'
import AddIcon from '@web/images/community/AddIcon'
import { Suggestion } from '~/common/quill/QuillEditor'
import { useSearchUsersQuery } from '~/api/generated/graphql'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { useAddPersonBox } from '~/common/addPerson/AddPersonBoxContext'

type AddPersonBoxProps = {
  searchText: string
  placeholder: string
  onTextChange: (inputText: string) => void
  onSubmit?: (userId?: string, e?: SyntheticEvent) => void
  veevansOnly: boolean
  communityId?: string
  leaders: boolean
  showExcluded?: boolean
  excludedMessage?: string
  disabled?: boolean
  hideAddButton?: boolean
}

const AddPersonBox = ({
  searchText,
  placeholder,
  onSubmit,
  onTextChange,
  veevansOnly,
  communityId,
  leaders,
  showExcluded,
  excludedMessage,
  disabled,
  hideAddButton,
}: AddPersonBoxProps) => {
  const { isCondensed } = useWindowSize()

  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState(0)
  const { selectedUser, setSelectedUser, setHasPendingUser } = useAddPersonBox()
  const [selectedUserWasReset, setSelectedUserWasReset] = useState(false)
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>()

  const debouncedSetSearchText = useMemo(() => debounce(setDebouncedSearchText, 300), [setDebouncedSearchText])
  const { data, loading } = useSearchUsersQuery({
    variables: {
      query: debouncedSearchText ?? '',
      pageSize: 7,
      // passing false to isVeevan will result in only customers being returned, whereas in this
      // context, veevansOnly being false means return all users
      isVeevan: veevansOnly ? true : undefined,
      showExcluded,
      excludedCommunityId: communityId,
    },
    skip: !debouncedSearchText,
  })
  const excludeIds = new Set(data?.userSearch?.excludedUserIds)

  const matchedPeople: Suggestion[] = useMemo(
    () =>
      data?.userSearch?.users?.filter(Boolean).map(u => ({
        id: u?.userId,
        user: asUser(u),
        value: getFullName(u),
        link: `/profiles/${u?.userId}`,
        photo: u?.photo,
        isVeevan: u?.isVeevan,
      })) ?? [],
    [data]
  )

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (selectedUserWasReset) {
      inputRef.current?.focus()
      setSelectedUserWasReset(false)
    }
  }, [selectedUserWasReset])

  const addButtonEnabled = searchText.length > 0 || Boolean(selectedUser)

  const handleAddMemberTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value
    debouncedSetSearchText(search).then()
    onTextChange(search)
  }

  const getSuggestionList = () => {
    return matchedPeople?.map((s, i) => {
      const { value, photo, user } = s
      const photoStyle = photo ? { backgroundImage: `url(${photo})` } : {}
      const isExcluded = excludeIds.has(user?.userId ?? '')

      return (
        <li key={s.id} className={i === selectedSuggestion ? 'selected' : ''} onMouseDown={() => selectSuggestion(s)}>
          <div className={`user-badge${isExcluded ? ' excluded' : ''}`}>
            <div className="badge-body">
              <div className="profile-photo" style={photoStyle}>
                <div title={user?.title || ''} data-company={user?.company?.name} />
              </div>
              <div className="profile-info">
                <h6 className="name">{value}</h6>
                {communityId ? (
                  <p className="title">{isExcluded && excludedMessage ? excludedMessage : user?.title}</p>
                ) : (
                  <p className={'user-title'}>{user?.title}</p>
                )}
                <p className="company">{user?.company?.name}</p>
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  const handleInputKeyPresses = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && matchedPeople.length) {
      selectSuggestion(matchedPeople[selectedSuggestion])
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSelectedSuggestion(Math.min(selectedSuggestion + 1, matchedPeople.length - 1))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSelectedSuggestion(Math.max(selectedSuggestion - 1, 0))
    }
  }

  const selectSuggestion = (suggestion: Suggestion) => {
    onTextChange('')
    setShowSuggestions(false)

    if (!excludeIds.has(suggestion.user?.userId ?? '')) {
      setSelectedUser?.(suggestion.user)
      setHasPendingUser?.(true)
    }
  }

  const handleClickAdd = (e: SyntheticEvent) => {
    const user = selectedUser
    // if we aren't doing a membership add we don't want to clear the selected user
    if (communityId) {
      setSelectedUser?.(undefined)
    }
    setShowSuggestions(false)
    onSubmit?.(user?.userId || '', e)
  }

  const handleBlur = () => {
    setShowSuggestions(false)
  }

  const handleFocus = () => {
    setShowSuggestions(true)
  }

  const handleClear = () => {
    setSelectedUser?.(undefined)
    setSelectedUserWasReset(true)
    setHasPendingUser?.(false)
  }

  return (
    <div className={`add-person ${isCondensed ? 'condensed' : ''}`}>
      <div className="input-container">
        {!selectedUser ? (
          <Form.Control
            type="email"
            placeholder={placeholder}
            onChange={handleAddMemberTextChanged}
            value={searchText}
            onKeyDown={handleInputKeyPresses}
            role="email-input"
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={inputRef}
            bsPrefix={' '}
          />
        ) : (
          <div className="pill">
            <span className="name-badge" data-testid={'selected-user-pill'}>
              {getFullName(selectedUser)}
              <img
                className={'search-clear'}
                // This is an inlined version of @web/images/community/search-clear-light.svg to ensure that the
                // clear button will load at the same time as the rest of the badge
                src='data:image/svg+xml;UTF8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve" fill="%23ccc" opacity="0.75"> <g><path d="M500,10C229.4,10,10,229.4,10,500s219.4,490,490,490s490-219.4,490-490S770.6,10,500,10z M730.9,653.9c21.3,21.3,20.7,56.5,0.1,77.1c-21.3,21.3-56,20.9-77.1-0.1L500,577L346.1,730.9c-21,21-55.8,21.4-77.1,0.1c-20.6-20.6-21.2-55.8,0.1-77.1L423,500L269.1,346.1c-21.3-21.3-20.7-56.5-0.1-77.1c21.3-21.3,56-20.9,77.1,0.1L500,423l153.9-153.9c21.1-21,55.8-21.4,77.1-0.1c20.6,20.6,21.2,55.8-0.1,77.1L577,500L730.9,653.9z"/></g></svg>'
                alt="Clear"
                title="Clear"
                onClick={handleClear}
              />
            </span>
          </div>
        )}
        {showSuggestions && !selectedUser && searchText && (
          <div className="suggestion-list">
            {loading || searchText !== debouncedSearchText ? (
              <div className={'p-2'}>Loading... </div>
            ) : (
              <ul>{getSuggestionList()}</ul>
            )}
          </div>
        )}
      </div>
      {!hideAddButton && (
        <Button
          onClick={handleClickAdd}
          className={`btn-add ${disabled && 'disabled'}`}
          role="add-button"
          disabled={leaders ? !addButtonEnabled : disabled}
        >
          <AddIcon fill="#FAFAFA" />
          Add
        </Button>
      )}
    </div>
  )
}

export default AddPersonBox
