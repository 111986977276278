import React, { useMemo } from 'react'
import { GQLRelease, useGetSummitsDropdownQuery } from '~/api/generated/graphql'
import '@css/common/ReleasesDropdownItems.scss'
import { Link } from 'react-router-dom'
import { Maybe } from '@graphql-tools/utils'
import { useAuth } from '~/auth/Auth'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'

const SummitsDropdownItems = ({ hide }: { hide: () => void }) => {
  const { data, loading } = useGetSummitsDropdownQuery()
  const { actingSysAdmin, actingSummitAdmin } = useAuth()
  const { breakpoint } = useWindowSize()
  const condensed = breakpoint <= SizeBreakpoint.lg
  const sections = useMemo(() => {
    return (
      data?.sections?.edges
        ?.filter(
          s =>
            Boolean(s?.node?.releases?.edges.length) &&
            (s?.node?.releases?.edges.some(s => s?.node?.hasPublishedPosts) || actingSummitAdmin || actingSysAdmin)
        )
        .sort((a, b) => {
          return (a?.node?.index ?? 0) - (b?.node?.index ?? 0)
        }) ?? []
    )
  }, [actingSummitAdmin, actingSysAdmin, data?.sections])

  const sortReleases = (releaseOrderRaw: Maybe<string>, releases: Partial<Maybe<GQLRelease>>[] | undefined) => {
    if (!releaseOrderRaw) return releases
    const releaseOrder = JSON.parse(releaseOrderRaw) as string[]
    const releasesToSort = [...(releases ?? [])]
    return (
      releasesToSort?.sort((a, b) => {
        const aIndex = releaseOrder.indexOf(a?.releaseId ?? '')
        const bIndex = releaseOrder.indexOf(b?.releaseId ?? '')
        if (aIndex === -1) return 1
        if (bIndex === -1) return -1
        return aIndex - bIndex
      }) ?? []
    )
  }

  if (!data && loading) return <>Loading...</>
  return (
    <>
      {sections?.map(s => (
        <div key={`summits_dropdown_section_${s?.node?.sectionId}`}>
          <div className={`summit section-name${s?.node?.visible ? '' : ' hidden'}`}>
            <span>{s?.node?.name}</span>
          </div>
          {sortReleases(
            s?.node?.releaseOrder,
            s?.node?.releases?.edges
              ?.filter(r => r?.node?.hasPublishedPosts || actingSummitAdmin || actingSysAdmin)
              .map(r => r?.node)
          )?.map(r => (
            <Link
              className={`release-name${r?.visible ? '' : ' hidden'}`}
              to={`/summits/${r?.releaseId}`}
              key={`summits_link_${r?.releaseId}`}
              onClick={hide}
              tabIndex={0}
            >
              <div className={'name-text'}>
                {r?.name} {r?.draft ? '(draft)' : ''}
              </div>
              {!condensed && <div className={'arrow-image'} />}
            </Link>
          ))}
        </div>
      ))}
    </>
  )
}

export default SummitsDropdownItems
