import React, { SyntheticEvent, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { usePermissions } from '~/pages/posts/PostUtils'
import {
  GetPostHeaderDocument,
  useGetDropdownItemsCommentParentCommunityQuery,
  useGetDropdownItemsCommentParentPostQuery,
  useGetDropdownItemsCommentQuery,
  useSetHideCommentMutation,
} from '~/api/generated/graphql'
import { useApolloClient } from '@apollo/client'
import { elementClicked } from '~/common/EventLogger'
import ToastComponent from '~/common/ToastComponent'
import { getPostPath } from '~/utils'

type CommentDropdownItemsProps = {
  commentId: string
  onDelete?: (id: string) => void
  onClickEdit?: () => void
  isVeevaDiscussion?: boolean
}

export const CommentDropdownItems = ({
  commentId,
  onDelete,
  onClickEdit,
  isVeevaDiscussion,
}: CommentDropdownItemsProps) => {
  const { data: commentData } = useGetDropdownItemsCommentQuery({ variables: { id: commentId } })
  const comment = commentData?.comment
  const { data: postData } = useGetDropdownItemsCommentParentPostQuery({
    variables: { id: comment?.postId ?? '' },
    skip: !comment?.postId,
  })
  const post = postData?.post
  const { data: communityData } = useGetDropdownItemsCommentParentCommunityQuery({
    variables: { id: post?.communityId ?? '' },
    skip: !post?.communityId,
  })
  const authorId = comment?.createdById
  const client = useApolloClient()
  const [toastMessage, setToastMessage] = useState<string | null>(null)

  const { canEdit, canDelete } = usePermissions(authorId)

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false)

  const [hideComment] = useSetHideCommentMutation({
    update() {
      client.writeQuery({
        query: GetPostHeaderDocument,
        variables: { id: post?.postId },
        data: { post: { commentCount: post?.commentCount } },
      })
    },
  })

  const handleCancelDelete = () => setShowDeleteDialog(false)
  const handleConfirmDelete = async () => {
    setDeleteDisabled(true)
    await hideComment({ variables: { id: commentId, value: true } })
    onDelete?.(commentId)
    setDeleteDisabled(false)
    setShowDeleteDialog(false)
  }

  const singleCommentPath = `${getPostPath(communityData?.community, post, undefined, commentId, isVeevaDiscussion)}`

  const handleClickCopy = (e: SyntheticEvent) => {
    const permalink = `${document.location.origin}${singleCommentPath}`
    navigator.clipboard.writeText(permalink).then(() => setToastMessage('Link to comment copied to clipboard.'))
    elementClicked(e, 'click-comment-copy-link', {
      commentId,
    })
  }
  const handleCloseLinkCopiedToast = () => setToastMessage(null)
  const handleClickDelete = () => setShowDeleteDialog(true)

  return (
    <>
      <Dropdown.Menu className={'comment-dropdown-menu'}>
        <Dropdown.Item className={'copy-link'} onClick={handleClickCopy}>
          Copy link to comment
        </Dropdown.Item>
        {canEdit && (
          <Dropdown.Item className={'edit'} onClick={onClickEdit}>
            Edit comment
          </Dropdown.Item>
        )}
        {canDelete && (
          <Dropdown.Item className={'delete'} onClick={handleClickDelete}>
            Delete comment
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
      <Modal show={showDeleteDialog} onHide={handleCancelDelete} className={'delete-post'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>Are you sure you want to delete this comment?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete} disabled={deleteDisabled}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastComponent onClose={handleCloseLinkCopiedToast} show={!!toastMessage}>
        {toastMessage ?? ''}
      </ToastComponent>
    </>
  )
}
