import { Button, Col, Dropdown, Form, Spinner } from 'react-bootstrap'
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { GQLCreateMediaMutation, useCreateMediaMutation } from '~/api/generated/graphql'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { ModalCropper } from '~/common/ModalCropper'
import { getCroppedUploadHandler, getLinkedinImage } from '~/utils'
import { PreUser } from '~/types'
import { AuthRegistrationRequest } from '~/api/types'
import { elementClicked, flushEvents } from '~/common/EventLogger'
import { useSearchParams } from 'react-router-dom'
import ToastComponent from '~/common/ToastComponent'
import { CustomToggle } from '~/common/CustomToggle'
import PhotoUploadOptions from '~/common/PhotoUploadOptions'

interface CompleteProfileProps {
  user?: PreUser
  onSavedProfile: (request: AuthRegistrationRequest) => void
}

const PRE_USER_PHOTO_URL = '/media/pre_user_photo'

const CompleteProfile = ({ user, onSavedProfile }: CompleteProfileProps) => {
  const { isCondensedPortrait } = useWindowSize()
  const [firstName, setFirstName] = useState(user?.firstName ?? '')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(user?.lastName ?? '')
  const [lastNameError, setLastNameError] = useState('')
  const [nickName, setNickName] = useState(user?.nickName ?? '')
  const [title, setTitle] = useState(user?.title ?? '')
  const [titleError, setTitleError] = useState('')
  const [cropImage, setCropImage] = useState<File | null>()
  const [showPhotoEdit, setShowPhotoEdit] = useState<boolean>(false)
  const [mediaUrl, setMediaUrl] = useState<string | undefined>(user?.photo ? PRE_USER_PHOTO_URL : undefined)
  const [uploading, setUploading] = useState(false)
  const [createMedia, { loading: creatingMedia }] = useCreateMediaMutation()
  const [createMediaResponse, setCreateMediaResponse] = useState<GQLCreateMediaMutation>()
  const [addedProfilePhoto, setAddedProfilePhoto] = useState<boolean>(!!user?.photo)
  const fileUploaderRef = useRef<HTMLInputElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [toast, setToast] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [showLinkedinLoading, setShowLinkedinLoading] = useState(false)
  const [email, setEmail] = useState<string>(user?.email ?? '')
  const [companyName, setCompanyName] = useState<string>(user?.companyName ?? '')

  const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG', 'JFIF']

  const handleFileFileCrop = getCroppedUploadHandler(setUploading, createMedia, setCreateMediaResponse, setMediaUrl)
  useEffect(() => {
    if (searchParams.get('firstName')) {
      setFirstName(searchParams.get('firstName') ?? '')
      searchParams.delete('firstName')
    }
    if (searchParams.get('lastName')) {
      setLastName(searchParams.get('lastName') ?? '')
      searchParams.delete('lastName')
    }
    if (searchParams.get('title')) {
      setTitle(searchParams.get('title') ?? '')
      searchParams.delete('title')
    }
    if (searchParams.get('nickName')) {
      setNickName(searchParams.get('nickName') ?? '')
      searchParams.delete('nickName')
    }
    if (searchParams.get('email')) {
      setEmail(searchParams.get('email') ?? '')
      searchParams.delete('email')
    }
    if (searchParams.get('companyName')) {
      setCompanyName(searchParams.get('companyName') ?? '')
      searchParams.delete('companyName')
    }
    getLinkedinImage(
      searchParams,
      setSearchParams,
      setToast,
      setShowToast,
      setShowLinkedinLoading,
      setShowPhotoEdit,
      setCropImage
    )
  }, [searchParams, setSearchParams])
  const onChangeFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      const e = ev.target.files[0]
      if (e) {
        setCropImage(e)
        setShowPhotoEdit(true)
      }
      ev.target.value = ''
    }
  }

  const clickLinkedIn = (ev: SyntheticEvent) => {
    ev.stopPropagation()
    elementClicked(ev, 'click-register-photo-use-linkedin')
    // make sure click event gets logged before redirect
    flushEvents()

    window.location.href =
      '/linkedin/auth_code?' +
      `${firstName ? `firstName=${encodeURIComponent(firstName)}` : ''}${
        lastName ? `&lastName=${encodeURIComponent(lastName)}` : ''
      }
      ${nickName ? `&nickName=${encodeURIComponent(nickName)}` : ''}${
        email ? `&email=${encodeURIComponent(email)}` : ''
      }${title ? `&title=${encodeURIComponent(title)}` : ''}
      ${companyName ? `&companyName=${encodeURIComponent(companyName)}` : ''}`
  }

  const clickUploadPhoto = (ev: SyntheticEvent) => {
    elementClicked(ev, 'click-register-photo-upload')
    ev.stopPropagation()
    fileUploaderRef.current?.click()
  }

  const clickRemovePhoto = (e: SyntheticEvent) => {
    elementClicked(e, 'click-register-photo-remove')
    setMediaUrl('')
    setAddedProfilePhoto(false)
  }
  const handleSave = (e: SyntheticEvent) => {
    elementClicked(e, 'click-register-save-continue')
    if (!firstName?.trim()) setFirstNameError('Required')
    else {
      setFirstNameError('')
    }
    if (!lastName?.trim()) setLastNameError('Required')
    else {
      setLastNameError('')
    }
    if (!title?.trim()) setTitleError('Required')
    else {
      setTitleError('')
    }
    if (!firstName?.trim() || !lastName?.trim() || !title?.trim()) {
      return false
    } else {
      const request = {
        email: user?.email ?? '',
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        nickName: nickName?.trim(),
        title: title.trim(),
        photo: createMediaResponse?.createMedia?.ok && mediaUrl,
        profileVisible: true,
      }
      onSavedProfile(request)
    }
  }

  const emailForm = () => {
    return (
      <Form.Group controlId={'email'}>
        <Form.Label>Email</Form.Label>
        <Form.Control defaultValue={email} disabled />
      </Form.Group>
    )
  }

  const titleForm = () => {
    return (
      <Form.Group as={Col} controlId={'title'} className={titleError ? 'error' : ''}>
        <Form.Label>
          <span>
            Job Title<strong>*</strong>
          </span>
          {titleError ? <span className={'value-message'}>{titleError}</span> : ''}
        </Form.Label>
        <Form.Control
          defaultValue={title}
          onChange={titleChangeHandler}
          maxLength={100}
          placeholder={'Job Title'}
          data-testid="title"
        />
      </Form.Group>
    )
  }

  const companyForm = () => {
    return (
      <Form.Group as={Col} controlId={'company'}>
        <Form.Label>Company</Form.Label>
        <Form.Control defaultValue={companyName} disabled={true} />
      </Form.Group>
    )
  }

  const fnameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
    setFirstName(e.target.value)
    setFirstNameError('')
  }

  const lnameChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
    setLastName(e.target.value)
    setLastNameError('')
  }

  const titleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value)
    setTitleError('')
  }

  return (
    <div className={'profile-creation-zone'}>
      <p className={'dialog-heading'}>Let's get your profile set up:</p>
      <div className={`user-header ${isCondensedPortrait ? 'condensed' : ''}`}>
        <div className={'photo-container'}>
          <div
            className={`profile-photo registration-photo ${user?.photo ? 'has-photo' : ''} ${
              uploading || creatingMedia ? 'uploading' : ''
            }`}
          >
            {
              <>
                {showPhotoEdit && !!cropImage && (
                  <ModalCropper
                    file={cropImage}
                    handleBlobUpload={(blob, contentType, fileName) => {
                      handleFileFileCrop(blob, contentType, fileName)
                      setAddedProfilePhoto(true)
                    }}
                    onClose={() => {
                      setCropImage(null)
                      setShowPhotoEdit(false)
                    }}
                    isCommunity={false}
                    loading={showLinkedinLoading}
                  />
                )}
                {uploading || creatingMedia ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    {mediaUrl && (
                      <Dropdown className={'photo-dd'} drop="down">
                        <Dropdown.Toggle as={CustomToggle} id={'profile-edit-photo'}>
                          <div
                            className={'photo'}
                            style={{ backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover' }}
                            title={`${user?.firstName} ${user?.lastName}`}
                          >
                            <div className={'edit-photo'} title={'Edit Photo'} data-testid={'edit-photo'} />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={clickUploadPhoto}>Upload Photo</Dropdown.Item>
                          <Dropdown.Item onClick={clickLinkedIn}>Use LinkedIn Photo</Dropdown.Item>
                          {addedProfilePhoto && <Dropdown.Item onClick={clickRemovePhoto}>Remove Photo</Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </>
                )}
              </>
            }
          </div>
          {!mediaUrl && (
            <PhotoUploadOptions
              firstName={user?.firstName ?? ''}
              lastName={user?.lastName ?? ''}
              onClickLinkedin={clickLinkedIn}
              onClickUpload={clickUploadPhoto}
              extraRightPadding={true}
            />
          )}
        </div>
        <Form className="dialog-form">
          {' '}
          <>
            <div className={'text-end text-muted'}>
              <div className={'value-message'}>
                <strong>*</strong> = Required
              </div>
            </div>
            <div className={'mb-1'}>
              <Form.Group as={Col} controlId={'firstName'} className={firstNameError ? 'error' : ''}>
                <Form.Label>
                  <span>
                    First Name<strong>*</strong>
                  </span>
                  {firstNameError ? <span className={'value-message'}>{firstNameError}</span> : ''}
                </Form.Label>
                <Form.Control
                  defaultValue={firstName}
                  maxLength={100}
                  onChange={fnameChangeHandler}
                  placeholder={'First Name'}
                  data-testid="first-name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId={'lastName'} className={lastNameError ? 'error' : ''}>
                <Form.Label>
                  <span>
                    Last Name<strong>*</strong>
                  </span>
                  {lastNameError ? <span className={'value-message'}>{lastNameError}</span> : ''}
                </Form.Label>
                <Form.Control
                  defaultValue={lastName}
                  maxLength={100}
                  onChange={lnameChangeHandler}
                  placeholder={'Last Name'}
                  data-testid="last-name"
                />
              </Form.Group>
            </div>
            <div className={'mb-1'}>
              <Form.Group as={Col} controlId={'nickName'}>
                <Form.Label>Nick Name</Form.Label>
                <Form.Control
                  defaultValue={nickName}
                  maxLength={15}
                  onChange={e => setNickName(e.target.value)}
                  placeholder={'Nick Name'}
                />
              </Form.Group>
            </div>
            <>
              {titleForm()}
              {companyForm()}
              {emailForm()}
            </>
            <div className={'button-zone'}>
              <a
                className={'btn btn-link'}
                onClick={e => {
                  elementClicked(e, 'click-register-cancel')
                  // make sure click event gets logged before redirect
                  flushEvents()
                }}
                href={'/auth/logout'}
              >
                Cancel
              </a>
              <Button variant={'primary'} onClick={handleSave} disabled={uploading || creatingMedia}>
                Save &amp; Continue
              </Button>
            </div>
          </>
        </Form>
        <input
          type={'file'}
          name={'file'}
          ref={fileUploaderRef}
          onChange={onChangeFile}
          accept={fileTypes.map(t => `.${t.toLowerCase()}`).join(',')}
          style={{ display: 'none' }}
        />
      </div>
      <ToastComponent show={showToast} onClose={() => setShowToast(false)}>
        {toast ? toast : ''}
      </ToastComponent>
    </div>
  )
}

export default CompleteProfile
