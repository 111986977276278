import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import type { History } from 'history'

/** @description Blocks all navigation attempts.
 *  @param when {boolean}  Whether to start intercepting navigation.
 *  @example
 *  const [flag, setFlag, next] = usePrompt(false);
 *  useEffect(() => {
 *     if (flag) {
 *  //     do something like show a dialog etc;
 *  //     at the right time resume blocked navigate
 *         next();
 *     }
 *   }, [flag]);

 */
export const usePrompt = (when = false) => {
  const [flag, setFlag] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const confirm = useRef<any>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const context = useRef<any>(null)
  const { navigator } = useContext(NavigationContext)
  const blockNavigator = navigator as History

  const next = useCallback(() => {
    confirm.current()
    context.current?.retry?.()
  }, [])

  useEffect(() => {
    if (!when) return
    const unblock = blockNavigator.block(tx => {
      setFlag(true)
      context.current = tx
    })
    confirm.current = unblock
    return unblock
  }, [blockNavigator, when])

  return [flag, setFlag, next] as const
}
