import React, { useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  GQLCommentMedia,
  GQLHtmlWithMentions,
  GQLMediaAlignmentType,
  GQLMeetupInput,
  Maybe,
  useEditCommentMutation,
  useGetCommentEditQuery,
  useGetCommentPostQuery,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { asHtmlWithMentions, checkConfidentialWarning, removeTags } from '~/utils'
import '@css/pages/posts/CommentRow.scss'
import FormatOptions from '@web/images/posts/FormatOptions.svg'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import PostConfirmationModal from '~/pages/posts/PostConfirmationModal'
import { elementClicked } from '~/common/EventLogger'
import MultipleEditor, { EditorRowData } from '~/common/quill/MultipleEditor'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'

export const CommentEdit = ({
  commentId,
  onDone,
  communityId,
}: {
  commentId: string
  onDone: () => void
  communityId: string
}) => {
  const { data: commentData } = useGetCommentEditQuery({ variables: { id: commentId } })
  const comment = commentData?.comment
  if (!comment) return null
  return <CommentEditWrapped comment={comment} onDone={onDone} communityId={communityId} />
}
const CommentEditWrapped = ({
  comment,
  onDone,
  communityId,
}: {
  comment: {
    mediaUrl?: Maybe<string>
    story?: Maybe<GQLHtmlWithMentions>
    postId: string
    commentId: string
    isVeevanDiscussion: boolean
    media?: Maybe<Maybe<Partial<GQLCommentMedia>>[]>
  }
  onDone: () => void
  communityId: string
}) => {
  const [showToolbar, setShowToolbar] = useState<boolean>(!!comment.mediaUrl)
  const [disableEdit, setDisableEdit] = useState<boolean>(false)
  const [showConfidentialWarning, setShowConfidentialWarning] = useState<boolean>(false)
  const [confirmationMessage, setConfirmationMessage] = useState<string>()
  const [showSensitiveWarning, setShowSensitiveWarning] = useState<boolean>(false)
  const [meetup, setMeetup] = useState<GQLMeetupInput>()

  const translateToRowsData = (): EditorRowData[] => {
    const mediaMap = new Map<number, Maybe<Partial<GQLCommentMedia>>>()
    comment?.media?.forEach(m => mediaMap.set(m?.rowIndex ?? 0, m))

    if (comment?.story?.htmlWithMentions) {
      try {
        const rows: EditorRowData[] = []
        const storyRows = JSON.parse(comment?.story.htmlWithMentions) as string[]
        if (storyRows?.length) {
          storyRows.forEach((story, index) => {
            const row: EditorRowData = { alignment: GQLMediaAlignmentType.FullText, story: asHtmlWithMentions(story) }
            const media = mediaMap.get(index)
            if (media) {
              row.mediaUrl = media.mediaUrl
              row.mediaType = media.type ?? undefined
              row.alignment = media.alignment ?? GQLMediaAlignmentType.FullMedia
              if (media.filename) {
                row.filename = media.filename
              }
            }
            rows.push(row)
          })
        } else {
          return [{ alignment: GQLMediaAlignmentType.FullText }]
        }
        return rows
      } catch {
        return [{ story: comment?.story, alignment: GQLMediaAlignmentType.FullText }]
      }
    } else {
      return [{ alignment: GQLMediaAlignmentType.FullText }]
    }
  }

  const [storyRows, setStoryRows] = useState<EditorRowData[]>(translateToRowsData())
  const [originalStoryRows] = useState(translateToRowsData())
  const hasStory = storyRows?.some(row => removeTags(row.story) || row?.mediaType)

  const { authUserId, isVeevan } = useAuth()
  const { setDraftingComment } = useDraftingCommentPost()
  const handleCancel = useCallback(() => {
    setShowToolbar(false)
    setDraftingComment?.(comment.isVeevanDiscussion ? `vd:${comment.commentId}` : comment.commentId, false)
    onDone()
  }, [setDraftingComment, comment.isVeevanDiscussion, comment.commentId, onDone])

  const [editComment] = useEditCommentMutation()
  const { data: postData } = useGetCommentPostQuery({ variables: { id: comment.postId } })

  const doSave = async (args: { skipWarning?: boolean; warned?: boolean; meetup?: GQLMeetupInput }) => {
    setDisableEdit(true)
    const { skipWarning, warned } = args
    if (
      !skipWarning &&
      !isVeevan &&
      storyRows.some(row => checkConfidentialWarning(row.story?.htmlWithMentions) || row.mediaType)
    ) {
      setShowConfidentialWarning(true)
      setDisableEdit(false)
      return
    }
    const response = await editComment({
      variables: {
        story: storyRows
          .map(row => ({
            story: row?.story?.htmlWithMentions,
            uploadId: row?.uploadId,
            videoUrl: row.videoUrl,
            alignment: row.alignment,
            mediaType: row.mediaType,
            filename: row.filename,
            mediaUrl: row.uploadId ? undefined : row.mediaUrl,
          }))
          .filter(row => row.story || row.filename || row.uploadId || row.mediaUrl || row.videoUrl),
        comment_id: comment.commentId,
        warned: warned,
        meetup: meetup,
      },
    })
    if (response.data?.editComment?.error?.code === 'sensitiveContent') {
      setShowSensitiveWarning(true)
      setConfirmationMessage(response.data?.editComment?.error?.message ?? undefined)
      setDisableEdit(false)
    } else {
      setDraftingComment?.(comment.isVeevanDiscussion ? `vd:${comment.commentId}` : comment.commentId, false)
      onDone()
    }
  }

  return (
    <>
      <div className={'authoring-area comment editing'}>
        <ProfilePhoto userId={authUserId} />
        <div className={'authoring-form'}>
          {
            <div className={'add-comment-row-wrapper'}>
              <div className={`add-comment-row ${showToolbar ? 'show-toolbar' : ' hide-toolbar'}`}>
                <MultipleEditor
                  originalRowsData={originalStoryRows}
                  rowsData={storyRows}
                  setRowsData={setStoryRows}
                  placeholder={'Add a Comment'}
                  className={'story'}
                  communityId={communityId}
                  postId={comment.postId}
                  veevanOnlyMentions={comment.isVeevanDiscussion}
                  noToolbar={!showToolbar}
                  onSubmit={doSave}
                  setMeetup={setMeetup}
                  isComment={true}
                  commentId={comment.commentId}
                />
              </div>
              <button
                className="toggle-upload"
                onClick={e => {
                  elementClicked(e, 'click-comment-format', { postId: postData?.post?.postId })
                  setShowToolbar(!showToolbar)
                }}
                data-testid={'toggle-upload'}
              >
                <img src={FormatOptions} alt={'comment-format-toggle'} />
              </button>
            </div>
          }
          {
            <div className={'button-zone'}>
              <Button
                variant="light"
                size="sm"
                onClick={() => {
                  handleCancel()
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!hasStory || disableEdit}
                onClick={e => {
                  elementClicked(e, 'click-comment-save', {
                    postId: postData?.post?.postId,
                    is_veeva_discussion: comment?.isVeevanDiscussion,
                  })
                  doSave({ meetup: meetup })
                }}
                size="sm"
              >
                Save
              </Button>
            </div>
          }
        </div>
      </div>
      <PostConfirmationModal
        show={showConfidentialWarning}
        hide={() => setShowConfidentialWarning(false)}
        onSubmit={() => doSave({ skipWarning: true, meetup: meetup })}
        submitText={'Save'}
      />
      <PostConfirmationModal
        show={showSensitiveWarning}
        hide={() => {
          setShowSensitiveWarning(false)
          setConfirmationMessage('')
        }}
        onSubmit={() => doSave({ skipWarning: true, warned: true, meetup: meetup })}
        submitText={'Save'}
        message={confirmationMessage}
      />
    </>
  )
}
