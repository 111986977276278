import React from 'react'
import { getFullName } from '~/utils'
import { Maybe, useProfilePhotoQuery } from '~/api/generated/graphql'

type ProfilePhotoProps = {
  userId: Maybe<string>
  inactive?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  noBadge?: boolean
  adminBadge?: boolean
}

export const ProfilePhoto = ({ userId, inactive = false, onClick, noBadge, adminBadge }: ProfilePhotoProps) => {
  const { data: userData } = useProfilePhotoQuery({ variables: { id: userId ?? '' }, skip: !userId })
  const fullName = userData ? getFullName(userData.user) : ''
  const userPhoto = userData?.user?.photo
  const profilePhoto = userPhoto ? { backgroundImage: 'url(' + userData?.user?.photo + ')' } : {}
  const companyName = userData?.user?.company?.name
  return (
    <div className={`profile-photo ${userPhoto && !inactive ? 'has-photo' : ''}`} onClick={e => onClick?.(e)}>
      <div className="photo" style={profilePhoto} title={fullName} data-company={noBadge ? '' : companyName} />
      {adminBadge && <div className={'admin-badge'}>Admin</div>}
    </div>
  )
}
