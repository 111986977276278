import {
  GQLCommentMedia,
  GQLHtmlWithMentions,
  GQLMediaAlignmentType,
  GQLMediaType,
  GQLMeetup,
  GQLPostMedia,
} from '~/api/generated/graphql'
import React, { useEffect, useMemo } from 'react'
import { Maybe } from '@graphql-tools/utils'
import PostStoryRow from '~/pages/posts/PostStoryRow'
import '@css/common/MultipleEditor.scss'
import { useCommentLoadingMedia } from '~/contexts/CommentLoadingMediaContext'

type PostStoryProps = {
  media: Maybe<Partial<GQLPostMedia | GQLCommentMedia>>[]
  story: Maybe<GQLHtmlWithMentions>
  loading?: boolean
  commentId?: string
  hasPrimaryMedia?: boolean
  meetup?: GQLMeetup
  postId?: string
  repostId?: Maybe<string>
  primaryVideoUrl?: Maybe<string>
}

export type StoryRow = {
  story?: string
  mediaUrl?: string
  mediaType?: Maybe<GQLMediaType>
  alignment?: Maybe<GQLMediaAlignmentType>
  filename?: Maybe<string>
}

const PostStory = ({
  media,
  story,
  loading,
  commentId,
  hasPrimaryMedia,
  meetup,
  postId,
  repostId,
  primaryVideoUrl,
}: PostStoryProps) => {
  // List of indices of rows that have finished loading its media
  const { mediaLoaded, onMediaLoaded, onAllCommentMediaLoaded } = useCommentLoadingMedia()
  const mediaMap: Map<number, Maybe<Partial<GQLPostMedia | GQLCommentMedia>>> = useMemo(() => {
    // media is stored with an index value indicated which row it should be on. This creates a map so we can easily
    // get the media for any given row
    const map = new Map<number, Maybe<Partial<GQLPostMedia | GQLCommentMedia>>>()
    media.forEach(m => {
      // for old left/right media from before the multiple editors change, rowIndex will be undefined.
      // Do not default it to 0 otherwise it will show that media on the first row
      if (m?.rowIndex !== undefined && m?.rowIndex !== null) map.set(m.rowIndex, m)
    })

    return map
  }, [media])

  const storyRows: StoryRow[] = useMemo(() => {
    const storyString = story?.htmlWithMentions
    const rows: StoryRow[] = []
    if (storyString) {
      // try to decode json and add media to create rows. if this fails (which it will for any posts where story
      // is a regular string), just have a single row with the story
      try {
        const storyRows = JSON.parse(storyString) as string[]
        storyRows.forEach((story, index) => {
          const row: StoryRow = { story }
          const media = mediaMap.get(index)
          if (media) {
            row.mediaUrl = media.mediaUrl
            row.mediaType = media.type
            row.alignment = media.alignment
            row.filename = media.filename
          } else {
            row.alignment = GQLMediaAlignmentType.FullText
          }
          rows.push(row)
        })
      } catch {
        rows.push({ story: storyString, alignment: GQLMediaAlignmentType.FullText })
      }
    }

    return rows
  }, [mediaMap, story])

  useEffect(() => {
    if (story?.htmlWithMentions) {
      // try to decode json and see which rows have media. if this fails (which it will for any comments/posts where story
      // is a regular string), just skip
      try {
        const storyString = story?.htmlWithMentions
        const storyRows = JSON.parse(storyString) as string[]
        storyRows.forEach((story, index) => {
          const media = mediaMap.get(index)
          if (!media) {
            onMediaLoaded?.(index)
          }
        })
      } catch {
        // this means we are parsing an old comment/post object before we added the functionality for multiple story rows
        onMediaLoaded?.(0)
      }
    }
  }, [media, story?.htmlWithMentions, onMediaLoaded, mediaMap, hasPrimaryMedia, loading])

  const totalRows = storyRows.length

  useEffect(() => {
    if (mediaLoaded?.size == totalRows && !loading && commentId) {
      onAllCommentMediaLoaded?.(commentId)
    }
  }, [mediaLoaded, totalRows, loading, commentId, onAllCommentMediaLoaded])

  return (
    <div className={'post-text quill-editor-elements multiple-editor-container no-border'}>
      {storyRows.map((row, index) => (
        <PostStoryRow
          row={row}
          key={index}
          index={index}
          meetup={meetup}
          commentId={commentId ?? ''}
          postId={postId}
          repostId={repostId}
          primaryVideoUrl={primaryVideoUrl}
        />
      ))}
    </div>
  )
}

export default PostStory
