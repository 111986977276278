import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { usePrompt } from '~/common/hooks/usePrompt'
import { UnsavedWarningModal } from '~/common/UnsavedWarningModal'
import { useNavigate } from 'react-router'

export type DraftingCommentPostContextType = {
  setDraftingComment?: (commentId: string, adding: boolean, postId?: string) => void
  resetPostComments?: (postId: string) => void
  setDraftingPost?: (postId: string, adding: boolean, removeAll?: boolean) => void
  checkDraftingComment?: (postId: string, ignoreAddingComment?: boolean) => boolean
  checkDraftingPost?: (postId: string) => boolean
  setShowWarning?: (b: boolean) => void
  hasDrafts?: boolean
}
export const DraftingCommentPostContext = React.createContext<DraftingCommentPostContextType>({})

export const DraftingCommentPostProvider = ({ children }: { children: ReactNode }) => {
  const [allDraftingComments, setAllDraftingComments] = useState(new Map<string, string>()) // commentId: postId
  const [allDraftingPosts, setAllDraftingPosts] = useState(new Set())

  const setDraftingComment = useCallback((commentId: string, adding: boolean, postId?: string) => {
    if (adding) {
      setAllDraftingComments(comments => {
        const updatedComments = new Map(comments)
        updatedComments.set(commentId, postId ?? '')
        return updatedComments
      })
    } else {
      setAllDraftingComments(comments => {
        const updatedComments = new Map(comments)
        updatedComments.delete(commentId)
        return updatedComments
      })
    }
  }, [])

  const hasAnyCommentDraft = useMemo(() => {
    return allDraftingComments.size > 0
  }, [allDraftingComments])

  // removeAll indicates the entire post (both title/story fields will no longer be marked as in draft mode
  const setDraftingPost = useCallback((postId: string, adding: boolean, removeAll?: boolean) => {
    if (adding) {
      setAllDraftingPosts(posts => {
        const updatedPosts = new Set(posts)
        return updatedPosts.add(postId)
      })
    } else {
      setAllDraftingPosts(posts => {
        const updatedPosts = new Set(posts)
        updatedPosts.delete(postId)
        if (removeAll) {
          updatedPosts.delete(`title:${postId}`)
          updatedPosts.delete(`content_title:${postId}`)
        }
        return updatedPosts
      })
    }
  }, [])

  const hasAnyPostDraft = useMemo(() => {
    return allDraftingPosts.size > 0
  }, [allDraftingPosts])

  const [flag, setFlag, next] = usePrompt(hasAnyCommentDraft || hasAnyPostDraft)
  const [showWarning, setShowWarning] = useState(false)
  const navigate = useNavigate()

  const forceNavigate = () => {
    if (flag) {
      setFlag(false)
      setAllDraftingComments(new Map())
      setAllDraftingPosts(new Set())
      next()
    } else {
      // we are on the home feed and pressed the logo / want to refresh the feed
      navigate(0)
    }
  }

  const resetModal = () => {
    setFlag(false)
    setShowWarning(false)
  }

  // given the postId, check if there are any comments under this post that are being drafted
  const checkDraftingComment = useCallback(
    (s: string, ignoreAddingComment?: boolean) => {
      // If the action is editing a comment on a thread, we don't need to show the warning if the only edit is happening in the add box (since we won't lose the changes)
      if (ignoreAddingComment && allDraftingComments.size === 1) {
        const commentId = Array.from(allDraftingComments.keys())[0]
        const postId = Array.from(allDraftingComments.values())[0]
        if (commentId.split(':').pop() == postId) {
          return false
        }
      }
      return Array.from(allDraftingComments.values()).includes(s)
    },
    [allDraftingComments]
  )

  // check if a post is being drafted
  const checkDraftingPost = useCallback(
    (s: string) => {
      return allDraftingPosts.has(s) || allDraftingPosts.has(`title:${s}`) || allDraftingPosts.has(`content_title:${s}`)
    },
    [allDraftingPosts]
  )

  // after we collapse a post, we want to remove any comments under that post from being marked as drafted
  const resetPostComments = useCallback(
    (s: string) => {
      const updatedComments = new Map(
        [...allDraftingComments]
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, postId]) => postId != s)
      )
      setAllDraftingComments(updatedComments)

      const updatedPosts = new Set(allDraftingPosts)
      updatedPosts.delete(s)

      setAllDraftingPosts(updatedPosts)
    },
    [allDraftingComments, allDraftingPosts]
  )

  const hasDrafts = hasAnyCommentDraft || hasAnyPostDraft

  return (
    <DraftingCommentPostContext.Provider
      value={{
        setDraftingComment,
        setDraftingPost,
        checkDraftingComment,
        resetPostComments,
        checkDraftingPost,
        setShowWarning,
        hasDrafts,
      }}
    >
      {children}
      <UnsavedWarningModal
        showWarningModal={flag || showWarning}
        setShowWarningModal={resetModal}
        continueAction={forceNavigate}
        leavingPost={true}
      />
    </DraftingCommentPostContext.Provider>
  )
}

export const useDraftingCommentPost = () => useContext(DraftingCommentPostContext)
