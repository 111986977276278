import { ApolloCache } from '@apollo/client'
import { SearchObject, SearchObjectType } from '~/common/TypeaheadSearch'
import { contentIconSrc, searchCache } from '~/utils'
import {
  CacheCommunityFragmentDoc,
  GQLCacheCommunityFragment,
  GQLInstantSearchPostFragment,
  GQLInstantSearchReleaseFragment,
  GQLInstantSearchUserFragment,
  GQLPostType,
  InstantSearchPostFragmentDoc,
  InstantSearchReleaseFragmentDoc,
  InstantSearchUserFragmentDoc,
} from '~/api/generated/graphql'
import summitIcon from '@web/images/header/search-summit.svg'
import searchRelease from '@web/images/header/search-releases.svg'
import { FacetResponse } from '~/pages/search/AdvancedSearch'

export const getInstantSearchOptions = (
  cache: ApolloCache<object>,
  searchTerm: string,
  isSysAdmin: boolean,
  recentSearches?: string[]
): SearchObject[] => {
  const results = searchTerm ? searchCache(searchTerm) : recentSearches ?? []
  const filteredRecentSearches = [...(recentSearches ?? [])]
  const filteredResults = results.map(k => {
    const [objectType, id] = k.split(':')

    if (objectType === 'User') {
      const user = cache.readFragment<GQLInstantSearchUserFragment>({
        id: cache.identify({ __typename: 'User', userId: id }),
        fragment: InstantSearchUserFragmentDoc,
      })

      if (!user) {
        const i = filteredRecentSearches.indexOf(k)
        filteredRecentSearches.splice(i, 1)
      }

      if (!user?.hidden) {
        const title = user ? `${user?.firstName} ${user?.lastName}` + (user?.nickName ? ` (${user.nickName})` : '') : ''
        return {
          title,
          type: SearchObjectType.user,
          link: `/profiles/${id}`,
          photo: user?.photo,
          subtitle: `${user?.title}, ${user?.company?.name}`,
          sortKey: `${user?.lastName} ${user?.firstName}`,
          idKey: k,
        }
      }
    }
    if (objectType === 'Community') {
      const community = cache.readFragment<GQLCacheCommunityFragment>({
        id: cache.identify({ __typename: 'Community', communityId: id }),
        fragment: CacheCommunityFragmentDoc,
      })

      if (!community) {
        const i = filteredRecentSearches.indexOf(k)
        filteredRecentSearches.splice(i, 1)
      }

      const title = `${community?.name ?? ''}`
      const isHomepage = !!community?.companyId
      return {
        title,
        type: isHomepage ? SearchObjectType.company : SearchObjectType.community,
        link: isHomepage ? `/companies/${community.companyId}` : `/communities/${id}`,
        photo: community?.photo,
        subtitle: isHomepage ? 'Customer Homepage' : 'Community',
        isHomepage,
        sortKey: title,
        idKey: k,
      }
    }
    if (objectType === 'Post') {
      const post = cache.readFragment<GQLInstantSearchPostFragment>({
        id: cache.identify({ __typename: 'Post', postId: id }),
        fragment: InstantSearchPostFragmentDoc,
      })

      if (!post) {
        const i = filteredRecentSearches.indexOf(k)
        filteredRecentSearches.splice(i, 1)
      }

      if (post?.postType === GQLPostType.Content && !post.draft) {
        const title = `${post?.contentTitle ? post.contentTitle : ''}`
        const createTime = post?.createdTime?.replaceAll(/[+-]\d\d:\d\d$/g, '')

        return {
          title,
          type: SearchObjectType.content,
          link: `/communities/${post?.communityId}/content/${id}`,
          subtitle: `Content in the ${post.community?.name} community`,
          photo: contentIconSrc(post?.mediaType ?? undefined),
          hidden: post?.hidden,
          sortKey: `${99999999999999 - parseInt(createTime?.replaceAll(/[^0-9]/g, '') ?? '0', 10)}${title}`,
          idKey: k,
        }
      }
    }

    if (objectType === 'Release') {
      const release = cache.readFragment<GQLInstantSearchReleaseFragment>({
        id: cache.identify({ __typename: 'Release', releaseId: id }),
        fragment: InstantSearchReleaseFragmentDoc,
      })

      if (!release) {
        const i = filteredRecentSearches.indexOf(k)
        filteredRecentSearches.splice(i, 1)
      }

      if (release?.draft === false || isSysAdmin) {
        if (release?.isRelease) {
          return {
            title: release?.title ?? '',
            type: SearchObjectType.release,
            link: `/releases/${id}`,
            subtitle: 'Release Page',
            photo: searchRelease,
            sortKey: release?.title ?? '',
            idKey: k,
          }
        } else {
          return {
            title: release?.title ?? '',
            type: SearchObjectType.summit,
            link: `/summits/${id}`,
            subtitle: 'Summit Page',
            photo: summitIcon,
            sortKey: release?.title ?? '',
            idKey: `Summit:${id}`,
          }
        }
      }
    }
    return { title: '', type: SearchObjectType.company, link: '', sortKey: '', idKey: '' }
  })

  if (!searchTerm) {
    localStorage.setItem('RECENT_SEARCH_SELECTION', JSON.stringify(filteredRecentSearches))
  }

  return filteredResults
}

export const getSearchResponseFacets = (
  fullFacets: ({ __typename?: 'Facet' | undefined; key: string; count: number } | null | undefined)[] | undefined,
  partialFacets: ({ __typename?: 'Facet' | undefined; key: string; count: number } | null | undefined)[] | undefined,
  showingPartialMatches: boolean
) => {
  if (showingPartialMatches) {
    // Merge and reduce the arrays to get total count for each Facet
    const mergedFacets = ((fullFacets ?? []).concat(partialFacets ?? []) as FacetResponse[]) ?? []
    const reduceFacets = (facets: FacetResponse[]): FacetResponse[] => {
      const reducer = (acc: { [key: string]: number }, obj: FacetResponse) => {
        const key = obj.key
        const count = obj.count
        if (acc[key]) {
          acc[key] += count
        } else {
          acc[key] = count
        }
        return acc
      }
      return Object.entries(facets.reduce(reducer, {})).map(([key, count]) => ({
        key,
        count,
      }))
    }

    return reduceFacets(mergedFacets)
  } else return fullFacets as FacetResponse[]
}

export const isAuthorSelected = (
  authorId: string | null | undefined,
  companyId: string | null | undefined,
  selectedAuthorIds: string[],
  selectedCompanyIds: string[]
) => {
  return selectedAuthorIds.includes(authorId ?? '') || selectedCompanyIds.includes(companyId ?? '')
}
