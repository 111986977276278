import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useAuth } from '~/auth/Auth'
import { AppHeader } from '~/common/AppHeader'
import '@css/pages/admin/AdminPage.scss'
import '@css/common/SettingsLayout.scss'
import { Button, Dropdown } from 'react-bootstrap'
import ToastComponent from '~/common/ToastComponent'
import {
  GQLNotificationEmailFrequency,
  useGetUserSettingsQuery,
  useUpdateEmailFrequencyMutation,
} from '~/api/generated/graphql'
import { emailFrequencyAsString } from '~/utils'
import { Footer } from '~/common/Footer'
import { elementClicked } from '~/common/EventLogger'

const SettingsLayout = () => {
  const { profileVisible, authUserId } = useAuth()
  const { data, loading } = useGetUserSettingsQuery({ variables: { id: authUserId ?? '' } })
  const initialEmailFrequency = useMemo(() => data?.user?.notificationFrequency ?? '', [data])
  const [emailFrequency, setEmailFrequency] = useState<GQLNotificationEmailFrequency>()
  const [emailFrequencyDisplay, setEmailFrequencyDisplay] = useState('')
  const [toast, setToast] = useState<string>('')
  const [showToast, setShowToast] = useState(false)

  useEffect(() => {
    setEmailFrequencyDisplay(emailFrequencyAsString(initialEmailFrequency))
  }, [initialEmailFrequency])

  useEffect(() => {
    if (emailFrequency) {
      setEmailFrequencyDisplay(emailFrequencyAsString(emailFrequency))
    }
  }, [emailFrequency])

  const logEmailFrequencyChange = (e: SyntheticEvent) => {
    elementClicked(e, 'click-settings-changed')
  }

  const [editUser] = useUpdateEmailFrequencyMutation()
  const doUpdatePreferences = async (e: SyntheticEvent) => {
    elementClicked(e, 'click-settings-save')
    const response = await editUser({
      variables: {
        emailFrequency: emailFrequency ?? initialEmailFrequency,
      },
    })
    if (response.data?.updateEmailFrequency?.ok) {
      setToast(`Email notifications updated to be ${emailFrequencyDisplay}`)
      setShowToast(true)
    } else {
      setToast(`There was an error, please try again later.`)
      setShowToast(true)
    }
  }
  if (!data && loading) {
    return <div>Loading...</div>
  }
  return (
    <>
      <div className={'settings'}>
        <div id="banner-container" />
        <AppHeader />
        <div className={`content-zone ${!profileVisible ? 'read-only' : ''}`}>
          <h2>Email Frequency</h2>
          <div>
            <p>As a community member, you will receive updates by email.</p>
          </div>
          <div>
            <span>
              This frequency setting controls whether email for the following items that you have not viewed are sent
              immediately, daily or weekly.
              <ul>
                <li>posts</li>
                <li>comments on followed posts</li>
                <li>events</li>
              </ul>
            </span>
            <span>
              You will always receive emails immediately for the following:
              <ul>
                <li>comments on a post you authored or commented on</li>
                <li>you are mentioned in a post or comment directly</li>
                <li>a post or comment is added that mentions the entire group of members</li>
              </ul>
            </span>
            <Dropdown id="dropdown-frequency-type" className="semi-wide-column row-text">
              <Dropdown.Toggle id="dropdown-basic" role="frequency-dropdown">
                <span>{emailFrequencyDisplay}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item
                  aria-label={`Immediate${emailFrequencyDisplay === 'Immediate' ? ', selected' : ''}`}
                  className={`dropdown-item ${emailFrequencyDisplay === 'Immediate' ? 'selected' : 'unselected'}`}
                  onClick={e => {
                    logEmailFrequencyChange(e)
                    setEmailFrequency(GQLNotificationEmailFrequency.Immediate)
                  }}
                  role="frequency-immediate"
                >
                  Immediate
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label={`Daily${emailFrequencyDisplay === 'Daily' ? ', selected' : ''}`}
                  className={`dropdown-item ${emailFrequencyDisplay === 'Daily' ? 'selected' : 'unselected'}`}
                  onClick={e => {
                    logEmailFrequencyChange(e)
                    setEmailFrequency(GQLNotificationEmailFrequency.Daily)
                  }}
                  role="frequency-daily"
                >
                  Daily
                </Dropdown.Item>
                <Dropdown.Item
                  aria-label={`Weekly${emailFrequencyDisplay === 'Weekly' ? ', selected' : ''}`}
                  className={`dropdown-item ${emailFrequencyDisplay === 'Weekly' ? 'selected' : 'unselected'}`}
                  onClick={e => {
                    logEmailFrequencyChange(e)
                    setEmailFrequency(GQLNotificationEmailFrequency.Weekly)
                  }}
                  role="frequency-weekly"
                >
                  Weekly
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Button onClick={doUpdatePreferences} role="save-button">
            Save Preferences
          </Button>
        </div>
        <ToastComponent show={showToast} onClose={() => setShowToast(false)}>
          {toast ? toast : ''}
        </ToastComponent>
      </div>
      <Footer />
    </>
  )
}

export default SettingsLayout
