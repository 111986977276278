import '@css/pages/company/CommunityActivityRow.scss'
import { CommunityActivityPreview, CustomerActivity } from '~/pages/company/CustomerActivity'
import React, { useMemo } from 'react'
import { asHtmlWithMentions, toTimeAgo } from '~/utils'
import MoreArrow from '@web/images/community/MoreArrow'
import LessArrow from '@web/images/community/LessArrow'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { GQLCommunityType, useGetCommunityActivityByCompanyIdQuery } from '~/api/generated/graphql'
import { useCommunity } from '~/contexts/CommunityContext'
import PrivateCommunityIcon from '@web/images/community/PrivateCommunityIcon'
import MentionableText from '~/common/MentionableText'
import { useStickyState } from '~/common/hooks/useStickyState'

type CommunityActivityRowProps = {
  communityInfo: CommunityActivityPreview
}

const CommunityActivityRow = ({ communityInfo }: CommunityActivityRowProps) => {
  const { companyId } = useCommunity()
  const expandedRowKey = `community-activity-${communityInfo.id}-${companyId}-expanded`
  const [isExpanded, setIsExpanded] = useStickyState('', expandedRowKey)
  const { data: activityData, loading: activityLoading } = useGetCommunityActivityByCompanyIdQuery({
    variables: { companyId: companyId ?? '', communityId: communityInfo.id, pageSize: 10 },
    skip: !companyId || !isExpanded,
  })
  const navigate = useNavigate()
  const isPrivate = communityInfo.type === GQLCommunityType.Private

  const activities: CustomerActivity[] | undefined = useMemo(() => {
    const activityList: CustomerActivity[] = []
    const postData: CustomerActivity[] =
      activityData?.community?.posts?.edges.map(p => ({
        postTitle: p?.node?.title?.htmlWithMentions ?? '',
        actor: {
          userId: p?.node?.createdBy?.userId ?? '',
          title: p?.node?.createdBy?.title ?? '',
          photo: p?.node?.createdBy?.photo ?? '',
          fullName: `${p?.node?.createdBy?.firstName} ${p?.node?.createdBy?.lastName}`,
        },
        postId: p?.node?.postId ?? '',
        time: new Date(p?.node?.createdTime),
      })) ?? []
    const commentData: CustomerActivity[] =
      activityData?.community?.comments?.edges.map(c => ({
        postTitle: c?.node?.post.title?.htmlWithMentions ?? '',
        actor: {
          userId: c?.node?.createdBy?.userId ?? '',
          title: c?.node?.createdBy?.title ?? '',
          photo: c?.node?.createdBy?.photo ?? '',
          fullName: `${c?.node?.createdBy?.firstName} ${c?.node?.createdBy?.lastName}`,
        },
        postId: c?.node?.postId ?? '',
        time: new Date(c?.node?.createdTime),
        poster: `${c?.node?.post?.createdBy?.firstName} ${c?.node?.post?.createdBy?.lastName} (${c?.node?.post?.createdBy?.company?.name})`,
        commentId: c?.node?.commentId,
      })) ?? []
    activityList.push(...postData)
    activityList.push(...commentData)
    activityList.sort((a, b) => b.time.getTime() - a.time.getTime())

    // remove duplicated activity for the same post
    const postIds = new Set<string>()
    activityList.forEach((a, index) => {
      if (postIds.has(a.postId)) {
        activityList.splice(index, 1)
      }
      postIds.add(a.postId)
    })
    return activityList
  }, [activityData])

  const handleExpandCollapseClick = () => {
    if (isExpanded) setIsExpanded('')
    else setIsExpanded('true')
  }

  return (
    <div className={'community-column-container'}>
      <div className={'community-info'}>
        <div className={'community-identity'}>
          <div
            className="profile-photo community-photo"
            style={communityInfo.photo ? { backgroundImage: `url(${communityInfo.photo})` } : {}}
          />
          <Link className={'community-name'} to={`/communities/${communityInfo.id}`} tabIndex={-1}>
            {communityInfo.name}
            {isPrivate && <PrivateCommunityIcon />}
          </Link>
        </div>
        <div className={'last-activity'}>{communityInfo.lastActivity?.toLocaleDateString()}</div>
        <a className={'expand-collapse-button'} onClick={handleExpandCollapseClick} data-testid={'expand-button'}>
          {isExpanded ? (
            <span className="show-less">
              <LessArrow fill="#565656" width={'15'} height={'15'} />
            </span>
          ) : (
            <span className="show-more">
              <MoreArrow fill="#565656" width={'15'} height={'15'} />
            </span>
          )}
        </a>
      </div>
      {!!isExpanded &&
        (activityLoading ? (
          <>Loading...</>
        ) : (
          <div className={'activities-container'}>
            {activities.map(a => (
              <Link
                to={`/communities/${communityInfo.id}/posts/${a.postId}`}
                className={'activity-container'}
                key={a.postId}
              >
                <div
                  className={'poster-info'}
                  onClick={e => {
                    e.preventDefault()
                    navigate(`/profiles/${a.actor.userId}`)
                  }}
                  role={'link'}
                >
                  <div
                    className="profile-photo"
                    style={a.actor.photo ? { backgroundImage: `url(${a.actor.photo})` } : {}}
                  />
                  <div className={'poster-identity'}>
                    <h6>{a.actor.fullName}</h6>
                    <p>{a.actor.title}</p>
                  </div>
                </div>
                <div className={'post-info'}>
                  <div className={'post-title'}>
                    <MentionableText value={asHtmlWithMentions(a.postTitle)} />
                  </div>
                  {!!a.commentId && <div className={'poster-name'}>{a.poster} posted</div>}
                </div>
                <div className={'activity-info'}>
                  <div>{toTimeAgo(a.time)}</div>
                  <div>
                    {a.actor.fullName} {a.commentId ? 'Commented' : 'Posted'}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ))}
    </div>
  )
}

export default CommunityActivityRow
