import { Button, Modal } from 'react-bootstrap'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import '@css/common/AddMultipleModal.scss'
import { useCommunity } from '~/contexts/CommunityContext'
import {
  GetInvitesDocument,
  GQLGetInvitesQuery,
  useAddMultipleMembersByEmailMutation,
  useGetAddMultipleModalCommunityQuery,
  useGetAddMultipleModalUserQuery,
} from '~/api/generated/graphql'
import { calculateFieldLengthSeverity } from '~/pages/posts/PostUtils'
import AddMultipleResults, { AddEmailsResult } from '~/common/addPerson/AddMultipleResults'
import FormError from '~/common/FormError'
import { elementClicked } from '~/common/EventLogger'

type AddMultipleModalProps = {
  show: boolean
  hide: () => void
}

const AddMultipleModal = ({ show, hide }: AddMultipleModalProps) => {
  const { communityId } = useCommunity()

  const [messageText, setMessageText] = useState<string>('')
  const [emailsText, setEmailsText] = useState<string>('')

  const { data: userData, loading: userLoading } = useGetAddMultipleModalUserQuery({ skip: !show })
  const { data: communityData, loading: communityLoading } = useGetAddMultipleModalCommunityQuery({
    variables: { id: communityId ?? '' },
    skip: !show || !communityId,
  })
  const [editing, setEditing] = useState<boolean>(true)
  const [results, setResults] = useState<AddEmailsResult[]>([])
  const [addedCount, setAddedCount] = useState<number>(0)
  const [error, setError] = useState<string | null>(null)
  const [addMultipleMembers] = useAddMultipleMembersByEmailMutation({
    update(cache, { data }) {
      const oldInvites = cache.readQuery<GQLGetInvitesQuery>({ query: GetInvitesDocument, variables: { communityId } })
      const newInvites =
        data?.addMultipleMembersByEmail?.invites?.map(i => ({
          typename: 'InvitationEdge',
          node: i,
        })) ?? []

      const newInviteIds = newInvites.map(i => `${i?.node?.email}_${i?.node?.inviterId}`)
      const filteredOldInvites = oldInvites?.community?.invitations?.edges.filter(
        e => !newInviteIds.includes(`${e?.node?.email}_${e?.node?.inviterId}`)
      )
      const newInviteData = {
        community: {
          ...oldInvites?.community,
          invitations: {
            ...oldInvites?.community?.invitations,
            totalCount: (filteredOldInvites?.length ?? 0) + (data?.addMultipleMembersByEmail?.invites?.length ?? 0),
            edges: [...(filteredOldInvites ?? []), ...newInvites],
          },
        },
      }
      cache.writeQuery({ query: GetInvitesDocument, variables: { communityId }, data: newInviteData })
    },
  })
  const user = userData?.currentUser
  const community = communityData?.community

  const close = () => {
    hide()
    setEditing(true)
    setMessageText(defaultMessageText)
    setEmailsText('')
    setError(null)
  }

  const defaultMessageText = `Please join the ${community?.name} community. It's a way to connect with other customers and with the Veeva team.\n\nThanks—\n\n—${user?.firstName}`

  useEffect(() => {
    setMessageText(defaultMessageText)
  }, [defaultMessageText])

  const messageLength = messageText.length
  const emailsLength = emailsText.length

  const onSubmit = async (e: SyntheticEvent) => {
    elementClicked(e, 'click-community-members-add-multiple', { communityId: communityId })
    if (!emailsText) {
      setError('Please enter a list of email addresses.')
      return
    }
    const emailList = emailsText.split(',')
    const parsedEmails = emailList.map(e => {
      // parse format in which gmail pastes email addresses
      const match = e.match(/<(.*?)>/)
      if (match) {
        return match[1].trim()
      } else {
        return e.trim()
      }
    })
    const response = await addMultipleMembers({
      variables: {
        emails: parsedEmails,
        communityId: communityId ?? '',
        message: messageText,
      },
    })
    setEditing(false)
    const data = response?.data?.addMultipleMembersByEmail
    setResults(data?.results as AddEmailsResult[])
    setAddedCount((data?.memberships?.length ?? 0) + (data?.invites?.length ?? 0))
  }

  if ((userLoading && !userData) || (communityLoading && !communityData)) return <></>

  return (
    <Modal className={'add-multiple-modal'} show={show} onHide={close}>
      <Modal.Header closeButton>Add Members</Modal.Header>
      <Modal.Body>
        {editing ? (
          <>
            <h3>Paste or enter email addresses below</h3>
            <i>Separate email addresses with commas</i>
            <div className={'input-container emails'}>
              <span className={`character-counter ${calculateFieldLengthSeverity(emailsLength, 1200)}`}>
                {emailsLength < 10 ? `0${emailsLength}` : emailsLength} / {1200}
              </span>
              <textarea
                className={'addresses-input'}
                value={emailsText}
                onChange={e => {
                  setError(null)
                  setEmailsText(e.target.value)
                }}
                maxLength={1200}
                data-testid={'addresses-input'}
              />
              {!!error && <FormError message={error} />}
            </div>

            <h3>Add a message</h3>
            <i>Your customers will get an email with the message below</i>
            <div className={'intro-text'}>
              {user?.firstName} {user?.lastName} has invited you to join the {communityData?.community?.name} community
              on Veeva Connect.
            </div>
            <div className={'input-container'}>
              <span className={`character-counter ${calculateFieldLengthSeverity(messageLength, 500)}`}>
                {messageLength < 10 ? `0${messageLength}` : messageLength} / {500}
              </span>
              <textarea
                className={'message-input'}
                value={messageText}
                onChange={e => setMessageText(e.target.value)}
                maxLength={500}
              />
            </div>
          </>
        ) : (
          <AddMultipleResults newMemberCount={addedCount} results={results} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {editing ? (
          <>
            <Button variant="light" onClick={close}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Add members
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={close}>
            Done
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default AddMultipleModal
