import React, { useMemo } from 'react'
import '@css/pages/notifications/NotificationListItem.scss'
import { Notification } from '~/types'
import { getFullName, htmlToText } from '~/utils'
import { useAuth } from '~/auth/Auth'
import {
  GQLCommunityType,
  GQLNotificationType,
  useGetNotificationMessageUserQuery,
  useNotificationMessageCommunityQuery,
  useNotificationMessageUserQuery,
} from '~/api/generated/graphql'

type NotificationMessageProps = {
  notification: Notification
}

const UserLikeDisplay = ({ userId }: { userId: string }) => {
  const { data } = useGetNotificationMessageUserQuery({ variables: { id: userId } })

  return (
    <>
      {data?.user?.firstName} ({data?.user?.company?.name})
    </>
  )
}

const NotificationMessage = ({ notification }: NotificationMessageProps) => {
  const { authUserId } = useAuth()
  const type = notification.notificationType
  const { data: communityData, loading: loadingCommunity } = useNotificationMessageCommunityQuery({
    variables: { id: notification.communityId ?? '' },
    skip: !notification.communityId,
  })
  const { community } = communityData ?? {}
  const { data: userData, loading: loadingUser } = useNotificationMessageUserQuery({
    variables: { id: notification.userId ?? '' },
    skip: !notification.userId,
  })
  const { user } = userData ?? {}
  const { data: actorData, loading: loadingActor } = useNotificationMessageUserQuery({
    variables: { id: notification.actorId ?? '' },
    skip: !notification.actorId,
  })
  const { user: actor } = actorData ?? {}
  const loadingAll = loadingCommunity || loadingUser || loadingActor

  const communityName = community?.name ?? ''
  const deletedCommunityName = notification.extra?.name
  const actorName = getFullName(actor)
  const actorAndCompanyName = actor ? `${actorName} (${actor?.company?.name})` : ''
  const userName = getFullName(user)
  const userAndCompanyName = user ? `${userName} (${user?.company?.name})` : ''
  const postTitle = notification.post
    ? htmlToText(notification.post.title ?? '')
    : htmlToText(notification.comment?.post?.title ?? '')
  const parsedTitle = notification.extra?.contentTitle ?? ''
  const likes = type === GQLNotificationType.PostLikeTellOwnr ? notification.post?.likes : notification.comment?.likes
  const filteredLikes = useMemo(() => likes?.filter(like => like !== authUserId), [likes, authUserId])
  const leaderType = community?.type == GQLCommunityType.Homepage ? 'an Account Partner' : 'a Leader'
  const commentTypeMention = notification.comment?.isVeevanDiscussion ? 'Veeva Discussion comment' : 'comment'
  const commentType = notification.comment?.isVeevanDiscussion ? 'added a Veeva Discussion comment' : 'commented'
  const likeCountText: JSX.Element = useMemo(() => {
    if (filteredLikes) {
      const likesCount = filteredLikes.length
      switch (filteredLikes.length) {
        case 0:
          return <></>
        case 1:
          return <UserLikeDisplay userId={filteredLikes[0]} />
        case 2:
          return (
            <>
              <UserLikeDisplay userId={filteredLikes[0]} /> and <UserLikeDisplay userId={filteredLikes[1]} />
            </>
          )
        case 3:
          return (
            <>
              <UserLikeDisplay userId={filteredLikes[0]} />, <UserLikeDisplay userId={filteredLikes[1]} /> and{' '}
              <UserLikeDisplay userId={filteredLikes[2]} />
            </>
          )
        default: {
          const numOthers = likesCount - 3
          const othersText = `${numOthers} ${numOthers > 1 ? 'others' : 'other'}`
          return (
            <>
              <UserLikeDisplay userId={filteredLikes[0]} />, <UserLikeDisplay userId={filteredLikes[1]} />,{' '}
              <UserLikeDisplay userId={filteredLikes[2]} /> and {othersText}
            </>
          )
        }
      }
    } else {
      return <></>
    }
  }, [filteredLikes])

  let messageSpan: JSX.Element
  switch (type) {
    case GQLNotificationType.CommentLikeTellOwnr:
      messageSpan = (
        <span>
          <b>{likeCountText} liked your comment</b> on "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.CommentMentionTellSubj:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} mentioned you in their {commentTypeMention}
          </b>{' '}
          on "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewCommentTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} commented on the post</b> "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewCommentTellOwnr:
    case GQLNotificationType.NewCommentTellLdrOwnr:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} {commentType} on your post
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewCommentMeetupTellViewers:
    case GQLNotificationType.NewCommentMeetupTellLeaders:
    case GQLNotificationType.NewPostMeetupTellMembers:
    case GQLNotificationType.NewPostMeetupTellLeaders:
      messageSpan = (
        <span>
          <b>A new meetup has been added</b> by {actorAndCompanyName} for "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewCommentTellLdrCommenters:
    case GQLNotificationType.NewCommentTellCommenters:
    case GQLNotificationType.NewCommentTellLdrParticipants:
    case GQLNotificationType.NewCommentTellParticipants:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} {commentType} on a post you participated in
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewCommentTellLdrFollowers:
    case GQLNotificationType.NewCommentTellFollowers:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} {commentType} on a post you are following
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.DelContentTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: Content "{parsedTitle}" was deleted
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.AddMemberTellMmbr:
      messageSpan = (
        <span>
          {actorAndCompanyName} <b>added you to the {communityName} community</b>
        </span>
      )
      break
    case GQLNotificationType.DelCommunityTellLdrs:
      messageSpan = (
        <span>
          <b>{deletedCommunityName} was deleted</b> by {actorName}
        </span>
      )
      break
    case GQLNotificationType.DemotedTellSubj:
      messageSpan = (
        <span>
          <b>
            You were changed from {leaderType} to a Member in the {communityName} community
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.PromotedTellSubj:
      messageSpan = (
        <span>
          <b>
            You were made {leaderType} in the {communityName} community
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.DemotedTellLdrs:
      messageSpan = (
        <span>
          <b>
            {userName} was removed as {leaderType}
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.PromotedTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: {userName} was made {leaderType}
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.MemberRemovedTellLdrs:
      messageSpan = (
        <span>
          <b>{userName} was removed</b> by {actorName}
        </span>
      )
      break
    case GQLNotificationType.MemberRemovedTellMmbr:
      messageSpan = (
        <span>
          <b>You were removed from the {communityName} community</b> by {actorName}
        </span>
      )
      break
    case GQLNotificationType.NewEventTellMmbr:
      messageSpan = (
        <span>
          <b>A new event has been added</b> by {actorAndCompanyName}: {`\n`}
          <b>{notification.event?.title}</b>
        </span>
      )
      break
    case GQLNotificationType.UpdEventTellMmbr:
      messageSpan = (
        <span>
          <b>An event has been updated</b> by {actorAndCompanyName}: {`\n`}
          <b>{notification.event?.title}</b>
        </span>
      )
      break
    case GQLNotificationType.LdrLeftTellLdrs:
      messageSpan = (
        <span>
          <b>
            {actorName} left as {leaderType}
          </b>
        </span>
      )
      break
    case GQLNotificationType.LdrRemovedTellLdrs:
      messageSpan = (
        <span>
          <b>
            {userName} was removed as {leaderType}
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.AddMemberTellLdrs:
      messageSpan = (
        <span>
          <b>{userAndCompanyName} was added</b> by {actorAndCompanyName}
        </span>
      )
      break
    case GQLNotificationType.MemberJoinTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} joined</b>
        </span>
      )
      break
    case GQLNotificationType.MemberLeftTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} left</b>
        </span>
      )
      break
    case GQLNotificationType.PostLikeTellOwnr:
      messageSpan = (
        <span>
          <b>{likeCountText} liked your post</b> "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.PostMentionTellSubj:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} mentioned you in their post</b> "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewPostTellLdrs:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} posted</b> "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewPostTellMmbr:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} posted</b> "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.CommentAllTellMmbrs:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} mentioned the {communityName} community in their comment
          </b>{' '}
          on "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.PostAllTellMmbrs:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} mentioned the {communityName} community in their post
          </b>{' '}
          "{postTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewContentTellMmbr:
      messageSpan = (
        <span>
          <b>{actorAndCompanyName} added content</b> "{parsedTitle}"
        </span>
      )
      break
    case GQLNotificationType.NewContentTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: Content "{parsedTitle}" was added
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.NonVeevanRegistersTellLdrs:
      messageSpan = (
        <span>
          <b>{userAndCompanyName} registered after being a pending member</b>
        </span>
      )
      break
    case GQLNotificationType.InvitationAccepted:
      messageSpan = (
        <span>
          <b>{userAndCompanyName} accepted your invitation to join the community</b>
        </span>
      )
      break
    case GQLNotificationType.VeevanProfileModified:
      messageSpan = (
        <span>
          <b>Your profile was updated by </b>
          {actorAndCompanyName}
        </span>
      )
      break
    case GQLNotificationType.UpdContentTellOwnr:
    case GQLNotificationType.UpdContentTellLdrs:
    case GQLNotificationType.UpdContentTellOldAuthor:
      messageSpan = (
        <span>
          <b>
            {communityName}: Content "{parsedTitle}" was updated
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.ConvPostTellOwnr:
    case GQLNotificationType.ConvPostTellLdrs:
      messageSpan = (
        <span>
          <b>
            {communityName}: Post "{postTitle}" was converted
          </b>{' '}
          by {actorName}
        </span>
      )
      break
    case GQLNotificationType.PostMovedTellLeaders:
      messageSpan = (
        <span>
          <b>
            {actorAndCompanyName} moved "{postTitle}"
          </b>
        </span>
      )
      break
    default:
      messageSpan = <span>There was an error displaying this notification</span>
  }
  return (
    <>
      {!loadingAll && (
        <>
          <div className={'message'}>{messageSpan}</div>
        </>
      )}
    </>
  )
}

export default NotificationMessage
