import {
  GQLCommunityType,
  GQLHtml,
  GQLHtmlWithMentions,
  GQLMediaType,
  GQLNotificationType,
  GQLPostType,
  Maybe,
} from '~/api/generated/graphql'

export type Community = {
  name: string
  photo: Maybe<string>
  about: Maybe<GQLHtml>
  description: Maybe<string>
  events?: Event[]
  communityId: string
  company?: Company
  leaders?: User[]
  leaderCount?: number
  memberCount?: number
  postCount?: number
  lastPost?: Post
  type: string
  isHomepage: boolean
}

export type CommunitySettingsData = {
  communityId: string
  name: string
  photo: Maybe<string>
  description: Maybe<string>
  company?: {
    companyId: string
    name: string
    domains: string[]
  }
  type: GQLCommunityType
  aliases: string[]
}

export type Event = {
  eventId: string
  communityId: string
  title: string
  eventStart: Date
  eventEnd: Date
  description: Maybe<GQLHtml>
  location: string
  hidden: boolean
  createdTime: Date
  createdById: string
  repost: {
    eventId: string
    communityId: string
    hidden: boolean
  }
}

export type CommunityListView = {
  communityId: string
  name: string
  description: Maybe<string>
  companyId?: string
  memberCount?: number
  photo: Maybe<string>
  lastActivityTime?: Date
  type: string
}

export type RepostCommunity = {
  communityId: string
  name: string
  description: Maybe<string>
  companyId?: string
  photo: Maybe<string>
  type: string
}

export type Role = {
  companyId: string
  userId: string
  company: Company
  modifiedTime?: Date
  description?: Maybe<string>
  member?: { accountLead: boolean; commercialLead: boolean; rdqLead: boolean }
}

export type User = {
  userId: string
  email?: string
  firstName: string
  lastName: string
  nickName: string
  title: string
  photo: string
  hidden: boolean
  isVeevan: boolean
  fullName: string
  company: Company
  roles?: Role[]
  profileVisible?: boolean
  communityIds?: string[]
  otherContact?: string
  notificationFrequency: string
  createdTime?: string
}

export type PreUser = {
  email: string
  companyId: string
  companyName: string
  firstName?: string
  lastName?: string
  nickName?: string
  title?: string
  photo?: string
  isVeevan: boolean
  primaryLocation?: Location
  secondaryLocation?: Location
  linkedinUrl?: string
}

export type Notification = {
  notificationId: string
  notificationType: GQLNotificationType
  userId?: string
  actorId?: string
  comment?: Comment & { post: { title: string; postId: string } }
  communityId?: string
  created: Date
  event?: Event
  extra?: NotificationExtra
  post?: Post
  seenAt: Date | null
  viewedAt: Date | null
}

export type NotificationExtra = {
  title: string
  name: string
  contentTitle: string
  leader: boolean
}

export type Location = {
  type?: string
  country?: string
  state?: string
  city?: string
  countryCode?: string
}

export type Company = {
  companyId: string
  name: string
  isVeeva?: boolean
  photo?: string
  homePageName?: string
  memberCount?: number
}

export type Activity = {
  authorId: Maybe<string>
  postId: string
  postTitle: Maybe<GQLHtmlWithMentions>
  postComments: number
  postViews: number
  likeCount: number
  postLastActivityTime: Date
  communityId: string
  activityTime: Date
  isComment: boolean
  commentId?: string
  isHomeFeed?: boolean
  hidden: boolean
  veevanViewCount?: number
  veevanCommentCount?: number
  veevanLikeCount?: number
  viewed: boolean
  comments?: Comment[]
  createdBy?: {
    hidden: boolean
  }
  isRepost: boolean
  isFollowing?: boolean
  postType?: GQLPostType
}

export type HomeFeedActivity = Activity & {
  last_comment_time?: Date
  last_comment_author_id: Maybe<string>
  postAuthorId: string
  createdTime: Date
}

export type UserProfile = Omit<User, 'company'> & {
  company: Company
  aboutMe: Maybe<GQLHtml>
  email?: string
  orgwikiProfile?: string
  profileVisible?: boolean
  aboutMeModifiedTime?: Date
  otherContact?: string
  linkedinUrl?: string
  primaryLocation?: Location
  secondaryLocation?: Location
  syncOrgwikiLocation?: boolean
  roleAtCompany?: Maybe<GQLHtml>
  roleModifiedTime?: Date
  roles?: Role[]
  memberships?: Community[]
  profileModifiedTime?: Date
}

export type Member = {
  user: User
  communityId: string
  created: Date
  leader: boolean
  accountLead: boolean
  commercialLead: boolean
  rdqLead: boolean
}

export type PendingMember = {
  email: string
  companyName: string
  inviter: User
  createdTime: Date
}

export type Comment = {
  authorId: string
  commentId: string
  postId: string
  created_datetime: Date
  media_url?: string
  media_type: Maybe<GQLMediaType>
  content_title?: string
  story: Maybe<GQLHtmlWithMentions>
  views?: number
  likes: string[]
  hidden: boolean
  veevanViewCount: number
  veevanLikeCount: number
  isVeevanDiscussion?: boolean
}

export type Post = {
  authorId: string
  postId: string
  communityId: string
  created_datetime: Date
  title: Maybe<GQLHtmlWithMentions>
  media_url?: string
  media_type: Maybe<GQLMediaType>
  story: Maybe<GQLHtmlWithMentions>
  views?: number
  likes: string[]
  lastComment?: {
    commenter_id: string
    time: Date
  }
  content_title?: string
  comment_count: number
  last_activity_time: Date
  hidden: boolean
  postType: GQLPostType
  comments: Comment[]
  viewed: boolean
  veevanViewCount: number
  veevanLikeCount: number
  veevanCommentCount: number
  draft?: boolean
  isRepost?: boolean
  repostId?: string
  repost?: {
    postId: string
    createdById: string
    communityId: string
  }
  hasBeenReposted?: boolean
  featured?: boolean
  isFollowing?: boolean
}

export type PageInfo = {
  hasNextPage: boolean
  endCursor: string
}

export type Like = {
  isVeevan: boolean
  userId: string
  fullName: string
  company?: Company
  hidden: boolean
}

export type PostLike = Like & {
  postId: string
}

export type CommentLike = Like & {
  commentId: string
}

export type HistoryRow = {
  company?: {
    companyId: string
    name: string
  }
  historyId: string
  modifiedTime: string
  modifiedBy: {
    email: string
    firstName: string
    lastName: string
    userId: string
    hidden: boolean
    company: {
      name: string
      companyId: string
    }
  }
  field: string
  oldValue: string
  newValue: string
  table: string
  action: string
  community: {
    communityId: string
    name: string
    isHomepage: boolean
  }
  user: {
    email: string
    firstName: string
    lastName: string
    userId: string
    hidden: boolean
    company: {
      name: string
      companyId: string
    }
  }
}

export type FeedCommunityListView = {
  communityId: string
  companyId?: string
  companyName?: string
  name: string
  photo: string
  description?: string
  link: string
}

export type Summit = {
  summitId: string
  name: string
  content?: SummitContent
  menuName?: Maybe<string>
  menuCategory?: Maybe<string>
  menuOrder?: Maybe<number>
}

export type SummitContent = {
  title: string
  groups: {
    name: string
    posts: {
      title?: string
      postId: string
      hideBanner?: boolean
    }[]
  }[]
}

export type SearchMatch = {
  __typename?: 'Match'
  post: {
    __typename?: 'PostMatch'
    id: string
    postId: string
    title: string
    authorId: string
    communityId: string
    createdTime: string
  }
  highlight: Array<Maybe<{ __typename?: 'Highlight'; highlights: Array<Maybe<string>>; field: string }>>
}

export enum SearchHighlightKey {
  title = 'title',
  titlePhrases = 'title.phrases',
  contentTitle = 'contentTitle',
  contentTitlePhrases = 'contentTitle.phrases',
  story = 'story',
  storyPhrases = 'story.phrases',
  files = 'files.filePath',
  commentsStory = 'comments.story',
  commentsStoryPhrases = 'comments.story.phrases',
  commentsFiles = 'comments.files.filePath',
  authors = 'authors.name',
  allAuthors = 'allAuthors.name',
  discussionsStory = 'discussions.story',
  discussionsStoryPhrases = 'discussions.story.phrases',
  discussionsFiles = 'discussions.files.filePath',
}
