import { AccordionContext } from 'react-bootstrap'
import { getFullName, getPostPath } from '~/utils'
import React, { EventHandler, SyntheticEvent, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import PostHeaderLayoutComponent from '~/pages/posts/PostHeaderLayoutComponent'
import { usePermissions } from '~/pages/posts/PostUtils'
import {
  GQLMediaType,
  GQLPostType,
  useGetPostHeaderCommunityQuery,
  useGetPostHeaderQuery,
  useGetPostHeaderUserQuery,
} from '~/api/generated/graphql'
import contentReorderIcon from '@web/images/posts/DragDropContent.svg'
import ContentReorderHighlightIcon from '@web/images/posts/DragDropHighlight.svg'
import '@css/pages/posts/PostHeader.scss'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import { Maybe } from '@graphql-tools/utils'
import TimeAgo from '~/common/TimeAgo'

type Props = {
  postId: string
  isExpanded: boolean
  onClick?: EventHandler<SyntheticEvent>
  onDelete?: (isDraft: boolean, id: string) => void
  onClickEdit?: (isRepost: boolean) => void
  isCommPost?: boolean
  fromContentPage: boolean
  isContent?: boolean
  isHomeFeed?: boolean
  dragHandleProps?: Maybe<DraggableProvidedDragHandleProps>
  isEditingPost?: boolean
  isSinglePost?: boolean
  isRepostPage?: boolean
  featuredCount?: number
  showEditor?: boolean
  shouldExpandDraft?: boolean
  showDrag?: boolean
}

export const PostHeader = ({
  postId,
  isExpanded,
  onClick,
  onDelete,
  onClickEdit,
  isCommPost,
  fromContentPage,
  isContent,
  isHomeFeed,
  dragHandleProps,
  isEditingPost,
  isSinglePost,
  isRepostPage,
  showEditor,
  shouldExpandDraft,
  showDrag,
}: Props) => {
  const { data: postData } = useGetPostHeaderQuery({ variables: { id: postId } })
  const post = postData?.post
  const { hasLeaderPermissions } = usePermissions(post?.createdById)

  const { data: communityData } = useGetPostHeaderCommunityQuery({
    variables: { id: post?.communityId ?? '' },
    skip: !post?.communityId,
  })

  const singlePostPath = `${getPostPath(
    communityData?.community,
    {
      postType: post?.postType ?? GQLPostType.Post,
      postId: postId,
    },
    fromContentPage
  )}`
  const { isCondensed } = useWindowSize()
  const { activeEventKey } = useContext(AccordionContext)

  const isAnyPostExpanded = useMemo(() => {
    if (typeof activeEventKey === 'string') {
      return !!activeEventKey
    } else if (Array.isArray(activeEventKey)) {
      return !!activeEventKey.length
    } else {
      return false
    }
  }, [activeEventKey])

  const expandText = isExpanded ? 'Collapse this Post' : 'Expand this Post'
  const expandTip = isCommPost ? '' : expandText
  const showDragDrop = fromContentPage && hasLeaderPermissions && !isCommPost && !isCondensed && showDrag

  if (!post) return null
  return (
    <>
      <div
        className={`post-header-container ${post.isRepost ? '' : 'extra-padding'} ${
          isSinglePost ? 'single-post' : ''
        } ${post.draft ? 'draft' : ''} ${isCondensed ? 'condensed' : ''}
        ${isHomeFeed ? ' home-feed' : ''}`}
      >
        {showDragDrop && (
          <div {...dragHandleProps}>
            <img
              alt={''}
              src={post.featured ? ContentReorderHighlightIcon : contentReorderIcon}
              // Can't add this hidden logic to showDragDrop because the draggable library will complain about not
              // being able to find the drag handle on the draggable element, so just visually hide it instead
              className={`drag-drop-image ${isAnyPostExpanded || isEditingPost ? 'hidden' : ''}`}
              title={'Click and drag to reorder'}
              data-testid={post.featured ? 'featured-handle' : 'regular-handle'}
            />
          </div>
        )}
        <PostHeaderLayoutComponent
          entry={{
            createdTime: new Date(post.createdTime),
            authorId: post?.isRepost ? post?.repost?.createdById ?? '' : post.createdById ?? '',
            postId,
            communityId: post?.communityId,
            veevanViewCount: post?.veevanViewCount ?? 0,
            veevanLikeCount: post?.veevanLikeCount ?? 0,
            veevanCommentCount: post?.veevanCommentCount,
            isRepost: post?.isRepost,
            isDraft: post?.draft,
            lastComment: post?.lastComment
              ? {
                  commenterId: post?.lastComment.createdById ?? '',
                  time: new Date(post?.lastComment.createdTime),
                }
              : undefined,
            repost: {
              postId: post?.repost?.postId ?? '',
              communityId: post?.repost?.communityId ?? '',
              newAuthorId: post?.createdById ?? '',
            },
            postType: post?.postType,
          }}
          fromContentPage={fromContentPage}
          displayAsContent={isContent && !isHomeFeed}
          title={post?.title}
          views={post?.viewCount ?? 0}
          likes={post?.likeCount ?? 0}
          last_activity={
            <LastActivity
              lastCommenterId={post?.lastComment?.createdById}
              lastCommentTime={post?.lastComment?.createdTime ? new Date(post.lastComment?.createdTime) : undefined}
              viewed={post?.viewed ?? false}
              createdTime={new Date(post?.createdTime)}
              authorId={post?.createdById ?? ''}
              isExpanded={isExpanded}
              path={singlePostPath}
              isRepost={post?.isRepost ?? false}
              isDraft={post?.draft ?? false}
            />
          }
          comment_count={post?.commentCount}
          onClick={onClick}
          expandTip={expandTip}
          isCommPost={isCommPost}
          hidden={post?.hidden ?? false}
          isComment={false}
          contentTitle={post?.contentTitle ?? ''}
          mediaType={post?.mediaType || GQLMediaType.Other}
          isExpanded={isExpanded}
          isHomeFeed={isHomeFeed}
          isSinglePost={isSinglePost}
          isRepostPage={isRepostPage}
          onClickEdit={() => onClickEdit?.(post?.isRepost)}
          onDelete={id => onDelete?.(post?.draft, id)}
          followed={post?.isFollowing ?? false}
          showEditor={post?.postType == GQLPostType.Post || showEditor}
          shouldExpandDraft={shouldExpandDraft}
          companyId={communityData?.community?.companyId ?? undefined}
        />
      </div>
    </>
  )
}

const LastActivity = ({
  lastCommenterId,
  authorId,
  lastCommentTime,
  viewed,
  createdTime,
  isExpanded,
  path,
  isRepost,
  isDraft,
}: {
  lastCommenterId: Maybe<string>
  authorId: string
  lastCommentTime: Date | undefined
  createdTime: Date
  viewed: boolean
  isExpanded: boolean
  path: string
  isRepost: boolean
  isDraft: boolean
}) => {
  const { data: lastCommentAuthorData } = useGetPostHeaderUserQuery({
    variables: { id: lastCommenterId ?? '' },
    skip: !lastCommenterId,
  })
  const { data: authorData } = useGetPostHeaderUserQuery({
    variables: { id: authorId },
  })
  const authorName = getFullName(authorData?.user)
  const lastCommentBy = getFullName(lastCommentAuthorData?.user)

  if (isExpanded || !lastCommentBy || !lastCommentTime) {
    return (
      <>
        <div className={`post-meta-time${viewed || isDraft ? ' read' : ' unread'}`}>
          <TimeAgo time={createdTime} />
        </div>
        <div className={`post-meta-author${viewed || isDraft ? ' read' : ' unread'}`}>
          <span>{authorName}</span> {isRepost ? 'reposted' : isDraft ? 'saved draft' : 'posted'}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={`post-meta-time${viewed || isDraft ? ' read' : ' unread'}`}>
          <Link to={path} tabIndex={-1}>
            <TimeAgo time={lastCommentTime} />
          </Link>
        </div>
        <div className={`post-meta-author${viewed || isDraft ? ' read' : ' unread'}`}>
          <span>{lastCommentBy}</span> commented
        </div>
      </>
    )
  }
}
