import { Button, Col, Form } from 'react-bootstrap'
import React, { SyntheticEvent, useState } from 'react'
import { GQLHtml, Maybe } from '~/api/generated/graphql'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import emailIcon from '@web/images/profile/email-icon.png'
import linkedInIcon from '@web/images/profile/LinkedIn-icon.svg'
import { PreUser } from '~/types'
import { asString } from '~/utils'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import '@css/pages/VeevanRegistration.scss'
import { AuthRegistrationRequest } from '~/api/types'
import { LocationList } from '~/common/LocationList'
import { elementClicked } from '~/common/EventLogger'

interface VeevanCompleteProfileProps {
  user?: PreUser
  onSavedProfile: (request: AuthRegistrationRequest) => Promise<void>
}

const VeevanCompleteProfile = ({ user, onSavedProfile }: VeevanCompleteProfileProps) => {
  const { isCondensedPortrait } = useWindowSize()
  const fullName = `${user?.firstName} ${user?.lastName}`
  const [roleAtCompany, setRoleAtCompany] = useState<GQLHtml | null>()
  const [aboutMe, setAboutMe] = useState<GQLHtml | null>()
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false)

  const roleHeader = `Please describe a bit about your role at Veeva.`
  const aboutHeader = 'Please share a bit about yourself.'
  const isCondensed = isCondensedPortrait ? ' condensed' : ''

  const handleSave = (e: SyntheticEvent) => {
    elementClicked(e, 'click-register-save-continue')
    setSaveDisabled(true)
    const request = {
      email: user?.email ?? '',
      aboutMe: asString(aboutMe)?.trim(),
      roleAtCompany: asString(roleAtCompany)?.trim(),
      profileVisible: true,
    }
    onSavedProfile(request).then(() => setSaveDisabled(false))
  }

  const VeevanInfo = () => {
    return (
      <div className="profile-info">
        <div className="user-info">
          <h1 className="name">{fullName}</h1>
          <h2 className="title registration">{user?.title}</h2>
        </div>
        <hr />
        <div className="contact-info">
          <LocationList user={user} isVeevan={true} />

          <ul>
            {user?.email && (
              <li className={'email'}>
                <img className="icon" alt="Email" src={emailIcon} />
                {user?.email}
              </li>
            )}
            {user?.linkedinUrl && (
              <li>
                <img className="icon" alt="LinkedIn" src={linkedInIcon} />
                <a href={`${!user?.linkedinUrl.startsWith('http') ? 'https://' : ''}${user?.linkedinUrl}`}>
                  LinkedIn Profile
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className={'profile-creation-zone-veevan'}>
      <p className={'dialog-subheading-veevan'}>
        Welcome to Veeva Connect! Please verify your profile information, which will be visible to customers.
      </p>
      <div className={`user-header${isCondensed}`}>
        <div className={`profile-photo${isCondensed}`}>
          <div
            className={'photo veevan-registration-photo'}
            style={{ backgroundImage: `url(${user?.photo})`, backgroundSize: 'cover' }}
            title={fullName}
            data-company={'Veeva'}
          />
        </div>
        {VeevanInfo()}
      </div>
      <Form className={`dialog-form veevan${isCondensed}`}>
        {' '}
        <>
          <>
            <div className={'g-0'}>
              <Col lg={12}>
                <p className={`message${isCondensed}`}>Sync'd from OrgWiki.</p>
                <hr />
              </Col>
            </div>
            <div className={'mb-4'}>
              <div className={'veevan-registration'}>
                <p className="dialog-subheading-veevan">Tell us about your role at Veeva</p>
                <QuillEditor<Maybe<GQLHtml>>
                  className="quill-editor name-editor"
                  toolbar={QuillToolbar.Full}
                  placeholder={roleHeader}
                  initialHtml={roleAtCompany}
                  setHtml={setRoleAtCompany}
                />
                <p className="dialog-subheading-veevan">Tell us about yourself</p>
                <QuillEditor<Maybe<GQLHtml>>
                  className="quill-editor name-editor"
                  toolbar={QuillToolbar.Full}
                  placeholder={aboutHeader}
                  initialHtml={aboutMe}
                  setHtml={setAboutMe}
                />
              </div>
            </div>
          </>
          <div className={'button-zone'}>
            <a className={'btn btn-link'} href={'/auth/logout'}>
              Cancel
            </a>
            <Button variant={'primary'} onClick={handleSave} disabled={saveDisabled}>
              Save &amp; Continue
            </Button>
          </div>
        </>
      </Form>
    </div>
  )
}

export default VeevanCompleteProfile
