import { OneColumnLayout } from '~/common/OneColumnLayout'
import { ProfileContent } from '~/pages/profile/ProfileContent'
import { ProfileProvider } from '~/contexts/ProfileContext'
import { useState } from 'react'
import { Navigate, useParams } from 'react-router'
import ProfileUserInfo from '~/pages/profile/ProfileUserInfo'
import { useAuth } from '~/auth/Auth'

export default ({ isEditing, sendToProfile }: { isEditing?: boolean; sendToProfile?: boolean }) => {
  const { authUserId } = useAuth()
  const { userId } = useParams<{ userId: string }>()
  const [updated, setUpdated] = useState(false)

  if (sendToProfile) {
    return <Navigate to={`/profiles/${authUserId}/edit`} />
  }
  if (userId) {
    return (
      <>
        <ProfileProvider userId={userId || authUserId || ''}>
          <OneColumnLayout currentPage={'profile'}>
            <ProfileUserInfo key={userId} setUpdated={() => setUpdated(true)} isEditing={isEditing} />
            <ProfileContent updated={updated} setUpdated={() => setUpdated(true)} />
          </OneColumnLayout>
        </ProfileProvider>
      </>
    )
  } else {
    return <></>
  }
}
