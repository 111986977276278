import React, { useMemo, useState } from 'react'
import {
  GQLPageType,
  useGetCategoryRowQuery,
  useGetReleaseSubcategoriesQuery,
  useRemovePostFromCategoryMutation,
  useSetCategoryPostOrderMutation,
} from '~/api/generated/graphql'
import CategoryPost from '~/pages/page/CategoryPost'
import { Button, Modal } from 'react-bootstrap'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import PostSelectionModal from '~/pages/page/PostSelectionModal'
import { useAuth } from '~/auth/Auth'
import { useSummitCategoryPosts } from '~/contexts/SummitCategoryPostsContext'
import SubcategoryRow from '~/pages/page/SubcategoryRow'

type CategoryRowProps = {
  categoryId: string
  releaseName: string
  isEditing: boolean
  releaseId: string
}

const CategoryRow = ({ categoryId, releaseName, isEditing, releaseId }: CategoryRowProps) => {
  const { actingRelAdmin, actingSummitAdmin, actingSysAdmin } = useAuth()
  const { editModePostIds, pageType } = useSummitCategoryPosts()
  const canSeeDrafts = pageType === GQLPageType.Release ? actingRelAdmin : actingSummitAdmin
  const [pendingPostOrder, setPendingPostOrder] = useState('')
  const canEditPosts = pageType === GQLPageType.Summit ? actingSummitAdmin : actingRelAdmin

  const { data, loading } = useGetCategoryRowQuery({
    variables: { id: categoryId, filter: canSeeDrafts ? {} : { draft: false } },
  })
  const { data: subcategoriesData } = useGetReleaseSubcategoriesQuery({
    variables: { releaseId },
    skip: !releaseId,
  })
  const [draggingRow, setDraggingRow] = useState<number>()
  const [removingPost, setRemovingPost] = useState<string>()

  const [setPostOrder, { loading: saving }] = useSetCategoryPostOrderMutation()
  const [removePost] = useRemovePostFromCategoryMutation()

  const [disableRemove, setDisableRemove] = useState<boolean>(false)

  const [showModal, setShowModal] = useState(false)
  const [pendingSummitPostIds, setPendingSummitPostIds] = useState<string[]>([]) // posts that have just been added from the modal

  const postOrder = useMemo(() => {
    const postIds = data?.category?.posts?.edges?.map(e => e?.node?.postId).filter(Boolean) as string[] | undefined
    const drafts = data?.category?.posts?.edges?.filter(e => e?.node?.draft).map(e => e?.node?.postId) as
      | string[]
      | undefined
    const subcategoryIds = subcategoriesData?.release?.subcategories?.edges
      ?.map(e => e?.node?.subcategoryId)
      .filter(Boolean) as string[] | undefined

    const order = pendingPostOrder ? pendingPostOrder : data?.category?.postOrder

    if (order && postIds && subcategoryIds) {
      let newPostOrder = (JSON.parse(order) as string[]) || []

      postIds.forEach(id => {
        if (!newPostOrder.includes(id)) {
          newPostOrder.push(id)
        }
      })

      subcategoryIds.forEach(id => {
        const orderId = `{sub}${id}`
        if (!newPostOrder.includes(orderId)) {
          newPostOrder.push(orderId)
        }
      })

      pendingSummitPostIds.forEach(id => {
        if (!newPostOrder.includes(id)) {
          newPostOrder.unshift(id)
        }
      })
      newPostOrder = newPostOrder.filter(
        id =>
          id &&
          (postIds.includes(id) || subcategoryIds.includes(id.substring(5)) || pendingSummitPostIds.includes(id)) &&
          !(!(actingSysAdmin || canEditPosts) && drafts?.includes(id))
      )
      if (!(actingSysAdmin || canEditPosts)) {
        newPostOrder = newPostOrder.filter((element, i) => {
          if (element.includes('{sub}')) {
            if (i + 1 < newPostOrder.length) {
              // remove subcategory with no child posts
              return !newPostOrder[i + 1].includes('{sub}')
            } else {
              return false
            }
          } else {
            return true
          }
        })
      }

      return newPostOrder
    } else {
      return [...pendingSummitPostIds, ...(postIds ?? []), ...(subcategoryIds?.map(id => `{sub}${id}`) ?? [])]
    }
  }, [
    data?.category?.posts?.edges,
    data?.category?.postOrder,
    subcategoriesData?.release?.subcategories?.edges,
    pendingPostOrder,
    pendingSummitPostIds,
    actingSysAdmin,
    canEditPosts,
  ])

  // anything that appears under a feature spotlight should be indented. Need to know where the first subcategory
  // is in order to add that indent
  const firstSubcategoryIndex = useMemo(() => {
    const index = postOrder?.findIndex(id => id.substring(0, 5) === '{sub}')
    return index !== -1 ? index : postOrder.length
  }, [postOrder])

  // subcategories that appear after the last post should be hidden in view mode. Need to know where the last post
  // is in order to hide the follow subcategories
  const lastPostIndex = useMemo(
    () => postOrder?.map(id => id.substring(0, 5) !== '{sub}').lastIndexOf(true),
    [postOrder]
  )

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !postOrder) return
    const postOrderCopy = [...postOrder]
    const [removed] = postOrderCopy.splice(result.source.index, 1)
    postOrderCopy.splice(result.destination.index, 0, removed)
    setDraggingRow(undefined)

    // don't want to include any pending posts
    const filteredPostOrder = postOrderCopy.filter(p => !pendingSummitPostIds?.includes(p))
    setPostOrder({
      variables: { categoryId: categoryId, postOrder: filteredPostOrder },
      optimisticResponse: {
        editCategory: {
          ok: true,
          error: {},
          category: {
            categoryId: categoryId,
            postOrder: JSON.stringify(filteredPostOrder),
            __typename: 'Category',
          },
        },
      },
    }).then()

    setPendingPostOrder(JSON.stringify(postOrderCopy))
  }

  const onRemovePost = (postId: string) => {
    setRemovingPost(postId)
  }

  const handleRemoveConfirm = async () => {
    if (!removingPost) return
    setDisableRemove(true)
    const resp = await removePost({
      variables: { categoryId: categoryId, postId: removingPost, filter: canSeeDrafts ? {} : { draft: false } },
    })
    setDisableRemove(false)
    if (!resp.data?.removePostFromCategory?.ok) alert('Oops! There was an issue removing the post')
    setRemovingPost(undefined)
  }

  const pendingPostOrderList = (JSON.parse(pendingPostOrder ? pendingPostOrder : '[]') as string[]) ?? []

  if (loading && !data) return <></>

  if (!data?.category?.posts?.edges?.length && !isEditing) return <></>

  return (
    <div className={'category-row row'} data-testid={`category-${categoryId}`}>
      {saving && <div hidden={true} data-testid={`category-${categoryId}-saving`} />}
      <div className={'col-12 col-sm-3 col category-title'}>
        <span>{data?.category?.name}</span>
        {isEditing && (
          <Button className={'post-add-btn'} onClick={() => setShowModal(true)} data-testid={'category-add-posts'}>
            Add Posts
          </Button>
        )}
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={initial => setDraggingRow(initial.source.index)}>
        <Droppable droppableId={'droppable'}>
          {provided => (
            <div className={'col col-12 col-sm-9'} ref={provided.innerRef} {...provided.droppableProps}>
              {postOrder?.map((id, index) => (
                <Draggable draggableId={id} index={index} key={id}>
                  {provided =>
                    id.substring(0, 5) === '{sub}' ? (
                      <SubcategoryRow
                        id={id.substring(5)}
                        isEditing={isEditing}
                        draggableProps={provided.draggableProps}
                        dragHandleProps={provided.dragHandleProps}
                        draggableInnerRef={provided.innerRef}
                        isDragging={draggingRow === index}
                        isAtBottom={index > lastPostIndex}
                      />
                    ) : (
                      <CategoryPost
                        key={id}
                        postId={id}
                        categoryId={categoryId}
                        releaseName={releaseName}
                        isEditing={isEditing}
                        draggableInnerRef={provided.innerRef}
                        draggableProps={provided.draggableProps}
                        dragHandleProps={provided.dragHandleProps}
                        isDragging={draggingRow === index}
                        onRemove={() => onRemovePost(id)}
                        isEditingSummitTitle={Boolean(editModePostIds?.has(id))}
                        releaseId={releaseId}
                        isUnderSubcategory={index > firstSubcategoryIndex}
                        pendingSummitPostIds={pendingSummitPostIds}
                        setPendingSummitPostIds={setPendingSummitPostIds}
                        postOrder={pendingPostOrderList}
                      />
                    )
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Modal show={!!removingPost} onHide={() => setRemovingPost(undefined)}>
        <Modal.Header closeButton />
        <Modal.Body>Are you sure you want to remove this post from {data?.category?.name}?</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setRemovingPost(undefined)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRemoveConfirm} disabled={disableRemove}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
      <PostSelectionModal
        showModal={showModal}
        setShowModal={setShowModal}
        category={data?.category?.name ?? ''}
        categoryId={categoryId}
        setPendingSummitPostIds={setPendingSummitPostIds}
      />
    </div>
  )
}

export default CategoryRow
