import React, { useRef } from 'react'
import PrivateCommunityIcon from '@web/images/community/PrivateCommunityIcon'
import '@css/pages/community/CommunityAbout.scss'
import { useOutsideAlerter } from '~/common/hooks/useOutsideAlerter'
import { useGetCompanyDomainsQuery, useGetCompanyNameQuery } from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { Link } from 'react-router-dom'

type CommunityVisibilityStatementProps = {
  companyId?: string
  isCompany: boolean
  isPublic: boolean
}

export const CommunityVisibilityStatement = ({ companyId, isCompany, isPublic }: CommunityVisibilityStatementProps) => {
  const { isVeevan } = useAuth()
  const tooltipRef = useRef<HTMLSpanElement>(null)
  const { showElement: showDomains, setShowElement: setShowDomains } = useOutsideAlerter(false, tooltipRef)

  const { data: companyNameData } = useGetCompanyNameQuery({
    variables: { id: companyId ?? '' },
    skip: !companyId,
  })

  const { data: domainsData } = useGetCompanyDomainsQuery({
    variables: { id: companyId ?? '' },
    skip: !companyId,
  })

  const companyName = companyNameData?.company?.name
  const domains = domainsData?.company?.domains

  const displayedDomainMessage = domains && (
    <>
      {domains.length === 1 && domains[0]}
      {domains.length > 1 && (
        <span
          ref={tooltipRef}
          data-tooltip={domains.slice(1).join('\n')}
          className={`more-domains ${showDomains ? 'visible' : ''}`}
          onClick={() => setShowDomains(!showDomains)}
        >
          {domains[0]} and {domains.length - 1} other {domains.length > 2 ? 'domains' : 'domain'}
        </span>
      )}
    </>
  )

  const visibilityMessage = (
    <div className={`statement-body ${!isCompany && !isPublic ? 'private' : ''}`}>
      {isCompany ? (
        <i>
          Only open to users from {companyName}
          {companyName !== 'Veeva' && ` and Veeva`}. All users with email addresses from{' '}
          {isVeevan ? (
            <Link to={`/companies/${companyId}/settings`}>{displayedDomainMessage}</Link>
          ) : (
            displayedDomainMessage
          )}{' '}
          are members.
        </i>
      ) : isPublic ? (
        <i>This community is open to everyone in Veeva Connect.</i>
      ) : (
        <i>
          This community is private and visible only to members that the community leaders have specifically invited.
        </i>
      )}
    </div>
  )

  return (
    <div className={`visibility-statement`}>
      {!isPublic && !isCompany && <PrivateCommunityIcon />}
      {visibilityMessage}
    </div>
  )
}
