import React, { useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import '@css/pages/PostSelectionModal.scss'
import {
  CacheCommunityFragmentDoc,
  GQLCacheCommunityFragment,
  GQLCommunityType,
  GQLPageType,
  useAddPostsToCategoryMutation,
  useGetCommunityContentPostsQuery,
  useGetContentSortOrderQuery,
} from '~/api/generated/graphql'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import PageCommunityTypeahead from '~/pages/page/PageCommunityTypeahead'
import { getFullName, searchCache } from '~/utils'
import { useApolloClient } from '@apollo/client'
import { useAuth } from '~/auth/Auth'
import { SummitTitleErrorType, useSummitCategoryPosts } from '~/contexts/SummitCategoryPostsContext'

type PostSelectionModalProps = {
  showModal: boolean
  setShowModal: (b: boolean) => void
  category: string
  categoryId: string
  setPendingSummitPostIds?: React.Dispatch<React.SetStateAction<string[]>>
}

export type CommunityToDisplay = {
  name: string
  communityId: string
}

type PostsDisplayList = {
  postId: string
  author: string
  contentTitle: string
  checked: boolean
  selected: boolean
  draft: boolean
}

type PostSelectionListProps = {
  posts: PostsDisplayList[]
  categoryId: string
  setShowModal: (b: boolean) => void
  setCommunityId: (s: string) => void
  setPendingSummitPostIds?: React.Dispatch<React.SetStateAction<string[]>>
}

const PostSelectionList = ({
  posts,
  categoryId,
  setShowModal,
  setCommunityId,
  setPendingSummitPostIds,
}: PostSelectionListProps) => {
  const [postsToDisplay, setPostsToDisplay] = useState<PostsDisplayList[]>(posts)
  const [addPostsToCategory] = useAddPostsToCategoryMutation()
  const { actingRelAdmin, actingSummitAdmin } = useAuth()
  const { pageType, setEditModePostIds, setAllPendingPostIds } = useSummitCategoryPosts()
  const canSeeDrafts = pageType == GQLPageType.Release ? actingRelAdmin : actingSummitAdmin
  const { isCondensed } = useWindowSize()

  const checkBox = (index: number) => {
    setPostsToDisplay(posts => {
      const postsCopy = [...posts]
      const post = postsCopy[index]
      if (post) {
        postsCopy[index].checked = !posts[index].checked
      }
      return postsCopy
    })
  }

  const cancelPostSelection = () => {
    setShowModal(false)
    setCommunityId('')
  }

  const savePostSelection = () => {
    const ids: string[] = []

    for (let i = 0; i < postsToDisplay.length; i++) {
      if (postsToDisplay[i].checked) {
        ids.push(postsToDisplay[i].postId)
      }
    }

    if (pageType === GQLPageType.Summit) {
      setEditModePostIds?.(editingIds => {
        const pendingIds = new Map<string, SummitTitleErrorType>(editingIds)
        ids.forEach(id => {
          pendingIds.set(id, SummitTitleErrorType.NEW_UNSAVED)
        })

        return pendingIds
      })
      setPendingSummitPostIds?.(pendingIds => {
        return (pendingIds ?? []).concat(ids)
      })
      setAllPendingPostIds?.(pendingIds => {
        return new Set([...(pendingIds ?? []), ...ids])
      })
    } else if (ids.length > 0) {
      addPostsToCategory({
        variables: { categoryId: categoryId, postIds: ids, filter: canSeeDrafts ? {} : { draft: false } },
      }).then(resp => {
        if (!resp.data?.addPostsToCategory?.ok) {
          return
        }
      })
    }

    setShowModal(false)
    setCommunityId('')
  }

  return (
    <>
      <Modal.Body>
        <div>
          <div className={'content-table'}>
            <div className={'content-labels'}>
              <p>Author</p>
              <p>Title</p>
            </div>
            <div className={'content-rows'}>
              {postsToDisplay &&
                postsToDisplay.map((post, index) => (
                  <div
                    className={`content-row ${post.selected && 'selected-post'} ${isCondensed ? 'condensed' : ''}`}
                    key={post.postId}
                    data-testid={'content-row'}
                  >
                    <div className={'checkbox-container'}>
                      <input
                        type={'checkbox'}
                        onChange={() => checkBox(index)}
                        disabled={post.selected}
                        data-testid={'checkbox'}
                      />
                    </div>
                    <p>{post.author}</p>
                    <div className={'content-title-container'}>
                      {post.draft && <div className={'draft-indicator'}>DRAFT</div>}
                      <p>{post.contentTitle}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={cancelPostSelection}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={savePostSelection} data-testid={'add-posts-modal'}>
          Add Posts
        </Button>
      </Modal.Footer>
    </>
  )
}

const PostSelectionModal = ({
  showModal,
  setShowModal,
  category,
  categoryId,
  setPendingSummitPostIds,
}: PostSelectionModalProps) => {
  const { isCondensed } = useWindowSize()
  const [communityId, setCommunityId] = useState('')
  const { allPendingPostIds } = useSummitCategoryPosts()

  const { data: postsData } = useGetCommunityContentPostsQuery({
    variables: { communityId: communityId },
    skip: !showModal || !communityId,
  })
  const { data: sortOrderData } = useGetContentSortOrderQuery({
    variables: { id: communityId },
    skip: !showModal || !communityId,
  })

  const [communitiesOptions, setCommunitiesOptions] = useState<CommunityToDisplay[]>([])

  const postsToDisplay = useMemo(() => {
    if (!postsData) {
      return []
    }

    const posts = new Map()

    postsData?.community?.posts?.edges
      ?.filter(post => !post?.node?.hidden)
      .map(post => {
        posts.set(post?.node?.postId, {
          postId: post?.node?.postId ?? '',
          author: getFullName(post?.node?.createdBy),
          contentTitle: post?.node?.contentTitle ?? '',
          checked: false,
          selected: !!post?.node?.categoryId || allPendingPostIds?.has(post?.node?.postId ?? ''),
          draft: post?.node?.draft,
        } as PostsDisplayList)
      })

    const orderedPosts: PostsDisplayList[] = []
    const unorderedPosts: PostsDisplayList[] = []

    // Organize post list into content order
    const orderedIds = (JSON.parse(sortOrderData?.community?.contentOrder ?? null) as string[]) ?? []

    // add posts included in the content order
    for (let i = 0; i < orderedIds.length; i++) {
      const post = posts.get(orderedIds[i])
      if (post) orderedPosts.push(post)
    }

    // add remaining posts that aren't in the content order and append to the end of the list
    for (const [postId] of posts.entries()) {
      if (!orderedIds.includes(postId)) {
        const post = posts.get(postId)
        if (post) unorderedPosts.push(post)
      }
    }

    return orderedPosts.concat(unorderedPosts)
  }, [allPendingPostIds, postsData, sortOrderData?.community?.contentOrder])

  const { cache } = useApolloClient()

  const handleSearch = (search: string) => {
    const normalizedSearch = search.normalize('NFKD')
    const filteredResults = searchCache(normalizedSearch)

    const newOptions = filteredResults.map(k => {
      const [objectType, id] = k.split(':')
      if (objectType === 'Community') {
        const community = cache.readFragment<GQLCacheCommunityFragment>({
          id: cache.identify({ __typename: 'Community', communityId: id }),
          fragment: CacheCommunityFragmentDoc,
        })

        if (community?.type == GQLCommunityType.Public) {
          return {
            name: community?.name ?? '',
            communityId: community?.communityId ?? '',
          }
        }
      }

      return { name: '', communityId: '' }
    })

    setCommunitiesOptions(newOptions)
  }

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false)
        setCommunityId('')
      }}
      className={`post-selection-modal ${isCondensed && 'condensed'}`}
    >
      <Modal.Header>
        <div>
          <h4>Add Posts</h4>
          <table className={'modal-options'}>
            <tbody>
              <tr className={'modal-option-row'}>
                <td>Category</td>
                <td>{category}</td>
              </tr>
              <tr className={`modal-option-row ${isCondensed ? 'condensed' : ''}`}>
                <td>Community</td>
                <td>
                  <PageCommunityTypeahead
                    options={communitiesOptions}
                    handleSearch={handleSearch}
                    onSelect={(s: string) => setCommunityId(s)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Header>
      {postsToDisplay.length > 0 && communityId ? (
        <PostSelectionList
          posts={postsToDisplay}
          categoryId={categoryId}
          setShowModal={setShowModal}
          setCommunityId={setCommunityId}
          setPendingSummitPostIds={setPendingSummitPostIds}
        />
      ) : (
        <>
          <Modal.Body>
            <div>
              <div className={'content-table'}>
                <div className={'content-labels'}>
                  <p>Author</p>
                  <p>Title</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                setShowModal(false)
                setCommunityId('')
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setShowModal(false)
                setCommunityId('')
              }}
              data-testid={'add-posts-modal'}
            >
              Add Posts
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default PostSelectionModal
