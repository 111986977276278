import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GQLMediaType, Maybe, useCreateMediaMutation } from '~/api/generated/graphql'
import { encodeRFC5987ValueChars } from '~/utils'
import useEventListener from '@use-it/event-listener'
import { usePrompt } from '~/common/hooks/usePrompt'
import { elementClickedNoEvent } from '~/common/EventLogger'

const MAX_VIDEO_SIZE = 8000000000

type MediaUploadContextType = {
  uploadMedia: (file: File) => void
  uploading: boolean
  mediaType?: GQLMediaType
  mediaUrl: string
  mediaDeleted: boolean
  uploadId?: string
  toastMessage?: string
  showToast?: boolean
  setShowToast?: (b: boolean) => void
  removeMedia: () => void
  filename: string
  fileTitle: string
  setFileTitle: (t: string) => void
  progress: number
  timeleft: number
  mediaName?: string
  isToastError?: boolean
  formFile?: File | null
}

const MediaUploadContext = React.createContext<MediaUploadContextType>({
  uploadMedia: () => {
    return
  },
  fileTitle: '',
  filename: '',
  mediaDeleted: false,
  mediaUrl: '',
  mediaType: GQLMediaType.Other,
  removeMedia: () => {
    return
  },
  setFileTitle: () => {
    return
  },
  uploadId: '',
  uploading: false,
  progress: 0,
  timeleft: 0,
  isToastError: true,
  formFile: undefined,
})

type MediaSource = {
  media_url?: string
  media_type?: Maybe<GQLMediaType>
  content_title?: string
  media_name?: string
}

export const MediaUploadProvider = ({ children, source }: { children: ReactNode; source?: MediaSource }) => {
  const [createMedia] = useCreateMediaMutation()
  const [uploading, setUploading] = useState(false)
  const [flag, setFlag, next] = usePrompt(uploading)
  const [progress, setProgress] = useState(0)
  const [timeleft, setTimeleft] = useState(0)
  const [mediaDeleted, setMediaDeleted] = useState<boolean>(false)
  const [mediaUrl, setMediaUrl] = useState<string>(source?.media_url || '')
  const [contentTitle, setContentTitle] = useState(source?.content_title || '')
  const [uploadId, setUploadId] = useState<string>()
  const [toastMessage, setToastMessage] = useState<string>()
  const [isToastError, setIsToastError] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [formFile, setFormFile] = useState<File | null>()
  const [uploadUrl, setUploadUrl] = useState<string>()
  const [uploadFile, setUploadFile] = useState<{ id: string }>()
  const [uploadVideo, setUploadVideo] = useState<{ projectId: string; token: string } | undefined>()
  const [contentType, setContentType] = useState<string>('application/octet-stream')
  const [mediaType, setMediaType] = useState<GQLMediaType | undefined>(source?.media_type || undefined)
  // Change the instance when the file changes
  const xhr = useMemo(() => (formFile ? new XMLHttpRequest() : new XMLHttpRequest()), [formFile])
  const [mediaName, setMediaName] = useState(source?.media_name ?? '')

  const resetState = useCallback(() => {
    setFormFile(null)
    setUploadFile(undefined)
    setUploadUrl('')
    setMediaUrl('')
    setMediaType(undefined)
    setUploadId(undefined)
    setUploadVideo(undefined)
    setProgress(0)
    setTimeleft(0)
    setUploading(false)
    setMediaName('')
    xhr.abort()
  }, [
    xhr,
    setFormFile,
    setUploadFile,
    setUploadUrl,
    setMediaUrl,
    setUploadId,
    setUploadVideo,
    setProgress,
    setTimeleft,
    setUploading,
    setMediaType,
  ])

  useEffect(() => {
    if (formFile) {
      if (formFile.size > MAX_VIDEO_SIZE && formFile.type.includes('video')) {
        setToastMessage('Media exceeds size limit.')
        setIsToastError(true)
        setShowToast(true)
        resetState()
        return
      }
      createMedia({
        variables: {
          content_type: formFile.type,
          filename: formFile.name,
        },
      }).then(response => {
        if (response.data?.createMedia?.error) {
          setToastMessage(response.data?.createMedia.error.message ?? '')
          setIsToastError(true)
          setShowToast(true)
          resetState()
          return
        }
        const createMediaResponse = response?.data?.createMedia
        setUploadUrl(createMediaResponse?.uploadUrl || undefined)
        setUploadFile(createMediaResponse?.uploadedFile ? { id: createMediaResponse.uploadedFile.id } : undefined)
        setUploadVideo(
          createMediaResponse?.uploadedVideo
            ? {
                projectId: createMediaResponse?.uploadedVideo?.projectId,
                token: createMediaResponse?.uploadedVideo?.token,
              }
            : undefined
        )
        setContentType(createMediaResponse?.contentType ?? 'application/octet-stream')
        setMediaType(createMediaResponse?.mediaType ?? GQLMediaType.Other)
        setMediaName(createMediaResponse?.mediaName ?? '')
        elementClickedNoEvent('click-post-file-uploaded', { mediaUrl: createMediaResponse?.uploadUrl })
      })
    }
  }, [formFile, createMedia, resetState])

  useEffect(() => {
    if (flag) {
      if (window.confirm('File upload still in progress. Are you sure you want to leave?')) {
        setFlag(false)
        resetState()
        next()
      } else {
        setFlag(false)
      }
    }
  }, [flag, next, setFlag, resetState])

  useEffect(() => {
    if (uploading) {
      window.onbeforeunload = e => {
        e.preventDefault()
        e.returnValue = ''
      }
    }
  }, [uploading])

  let starttime = new Date().getTime()
  const progressListener = useCallback(
    (ev: ProgressEvent) => {
      const curTime = new Date().getTime()
      if (ev.lengthComputable) {
        const progressValue = (100.0 * ev.loaded) / ev.total
        const timeLeftValue = ((1.0 - ev.loaded / ev.total) * (curTime - starttime)) / (ev.loaded / ev.total)

        // Stall at 99% until thumbnail finishes uploading.
        setProgress(progressValue > 99 ? 99 : progressValue)
        setTimeleft(timeLeftValue < 1 ? 1 : timeLeftValue)
      }
    },
    [setProgress, setTimeleft, starttime]
  )

  const getThumbnailProgressInfo = (hashed_id: string) => {
    return fetch(`https://fast.wistia.com/embed/medias/${hashed_id}.json`)
      .then(response => response.json())
      .then(json => {
        // The thumbnail information is not always at the same index for each upload
        for (let i = 0; i < json.media.assets.length; i++) {
          if (json.media.assets[i].type == 'still_image') {
            return json.media.assets[i].progress
          }
        }
        return -1
      })
  }

  const doThumbnailUpload = useCallback(async (hashed_id: string) => {
    let thumbnail_progress = await getThumbnailProgressInfo(hashed_id)
    if (thumbnail_progress == -1) {
      setProgress(100)
      setTimeleft(0)
      setToastMessage(
        'Could not fetch the thumbnail at this time. The video thumbnail will appear in a few moments after saving the post.'
      )
      setIsToastError(false)
      setShowToast(true)
    } else {
      while (thumbnail_progress < 1) {
        thumbnail_progress = await getThumbnailProgressInfo(hashed_id)
      }
    }
  }, [])

  const loadVideoListener = useCallback(async () => {
    if (!xhr || xhr.readyState !== 4) {
      return
    }

    const data = JSON.parse(xhr.responseText)
    if (xhr.status === 200) {
      const { hashed_id } = data
      doThumbnailUpload(hashed_id).then(() => {
        setProgress(100)
        setTimeleft(0)
        setUploading(false)
      })
      setMediaUrl(`https://fast.wistia.com/embed/medias/${hashed_id}.jsonp`)
      setMediaType(GQLMediaType.Video)
      setMediaDeleted(false)
      setContentTitle(formFile?.name || '')
    } else if (xhr.status !== 200) {
      setProgress(100)
      setTimeleft(0)
      setToastMessage('Video Upload Failed. Please Try Again')
      setIsToastError(true)
      setShowToast(true)
      resetState()
      setUploading(false)
    }
  }, [
    formFile,
    setProgress,
    setTimeleft,
    setMediaUrl,
    setMediaType,
    setMediaDeleted,
    setContentTitle,
    setToastMessage,
    setShowToast,
    xhr,
    resetState,
    doThumbnailUpload,
  ])

  useEventListener(
    'loadstart',
    () => {
      starttime = new Date().getTime()
    },
    { element: xhr }
  )
  useEventListener('progress', progressListener, { element: xhr?.upload })

  const fileLoadListener = useCallback(() => {
    if (formFile) {
      if (xhr?.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          setProgress(100)
          setTimeleft(0)
          setMediaDeleted(false)
          setContentTitle(formFile.name)
          if (mediaType !== GQLMediaType.Image) {
            setMediaUrl(`data:${formFile.type},`)
          } else {
            const reader = new FileReader()
            reader.readAsDataURL(formFile)
            reader.onload = e => {
              const result = e?.target?.result
              setMediaUrl(result?.toString() || '')
            }
          }
          setUploadId(uploadFile?.id)
          setUploading(false)
        } else {
          resetState()
          setToastMessage('There was an error uploading the file. Please try again.')
          setIsToastError(true)
          setShowToast(true)
        }
      }
    }
  }, [formFile, setProgress, setTimeleft, setMediaDeleted, setContentTitle, mediaType, uploadFile, xhr, resetState])

  const doUploadFile = useCallback(
    (uploadUrl: string) => {
      if (formFile && xhr && uploadUrl) {
        xhr.onreadystatechange = fileLoadListener
        xhr.open('PUT', uploadUrl)
        xhr.setRequestHeader(
          'Content-Disposition',
          "attachment; filename*=UTF-8''" + encodeRFC5987ValueChars(formFile.name)
        )
        xhr.setRequestHeader('Content-Type', contentType)
        xhr.send(formFile)
      }
    },
    [formFile, xhr, fileLoadListener, contentType]
  )

  const doUploadVideo = useCallback(
    (uploadUrl: string, projectId: string, token: string) => {
      if (formFile && xhr) {
        const body = new FormData()
        body.append('access_token', token)
        body.append('project_id', projectId)
        body.append('file', formFile)

        xhr.open('POST', uploadUrl)
        xhr.onreadystatechange = loadVideoListener
        xhr.send(body)
      }
    },
    [xhr, formFile, loadVideoListener]
  )

  useEffect(() => {
    if (uploadUrl) {
      setUploading(true)
      if (uploadFile) {
        doUploadFile(uploadUrl)
      } else if (uploadVideo) {
        const { projectId, token } = uploadVideo
        setUploadId(undefined)
        doUploadVideo(uploadUrl, projectId, token)
      }
    } else {
      setUploadId(undefined)
      setUploading(false)
    }
  }, [uploadUrl, uploadFile, uploadVideo, doUploadVideo, doUploadFile])

  const uploadMedia = (uploadedFile: File) => {
    setFormFile(uploadedFile)
  }

  const removeMedia = () => {
    setMediaUrl('')
    setUploadId(undefined)
    setMediaDeleted(true)
    resetState()
  }

  return (
    <MediaUploadContext.Provider
      value={{
        uploadMedia,
        formFile,
        uploading,
        mediaType,
        mediaUrl,
        mediaDeleted,
        uploadId,
        toastMessage,
        removeMedia,
        filename: contentTitle,
        fileTitle: contentTitle.split('.').slice(0, -1).join('.'),
        progress,
        timeleft,
        setFileTitle: (t: string) => {
          if (t) setContentTitle(`${t}.${mediaType}`)
        },
        mediaName,
        isToastError,
        showToast,
        setShowToast,
      }}
    >
      {children}
    </MediaUploadContext.Provider>
  )
}

export const useMediaUpload = () => {
  return useContext(MediaUploadContext)
}
