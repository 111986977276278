import React, { useMemo, useState } from 'react'
import { GQLRelease, useGetReleasesDropdownQuery } from '~/api/generated/graphql'
import '@css/common/ReleasesDropdownItems.scss'
import { Link } from 'react-router-dom'
import { Maybe } from '@graphql-tools/utils'
import { useAuth } from '~/auth/Auth'

type DropdownSection = {
  sectionId: string
  name: string
  releaseOrder: string
  visible: boolean
  opened: boolean
  releases: Partial<Maybe<GQLRelease>>[]
}

const ReleasesDropdownItemsSections = ({
  sectionsMap,
  hide,
}: {
  sectionsMap: Map<string, DropdownSection>
  hide: () => void
}) => {
  const [openedRows, setOpenedRows] = useState<Map<string, DropdownSection>>(sectionsMap)

  const handleClickSection = (sectionId: string) => {
    setOpenedRows(openedRows => {
      const section = openedRows.get(sectionId)
      if (section) {
        const updatedOpenedRows = new Map(openedRows)
        updatedOpenedRows.set(sectionId, {
          ...section,
          opened: !section.opened,
        })
        return updatedOpenedRows
      }
      return openedRows
    })
  }

  const sortReleases = (releaseOrderRaw: Maybe<string>, releases: Partial<Maybe<GQLRelease>>[] | undefined) => {
    if (!releaseOrderRaw) return releases
    const releaseOrder = JSON.parse(releaseOrderRaw) as string[]
    const releasesToSort = [...(releases ?? [])]
    return (
      releasesToSort?.sort((a, b) => {
        const aIndex = releaseOrder.indexOf(a?.releaseId ?? '')
        const bIndex = releaseOrder.indexOf(b?.releaseId ?? '')
        if (aIndex === -1) return 1
        if (bIndex === -1) return -1
        return aIndex - bIndex
      }) ?? []
    )
  }

  const sections = [...openedRows.values()]

  return (
    <>
      {sections?.map(s => (
        <div key={`releases_dropdown_section_${s?.sectionId}`}>
          <div
            onClick={e => {
              e.stopPropagation()
              handleClickSection(s?.sectionId ?? '')
            }}
            onKeyDown={e => {
              if (e.key == 'Enter') {
                e.stopPropagation()
                handleClickSection(s?.sectionId ?? '')
              }
            }}
            className={`section-name${s?.visible ? '' : ' hidden'}`}
            tabIndex={0}
          >
            <span>{s?.name}</span>
            <span role={'image'} className={`arrow-icon${s.opened ? ' rotated' : ''}`} />
          </div>
          <>
            {s.opened &&
              sortReleases(s?.releaseOrder, s?.releases)?.map(r => (
                <Link
                  className={`release-name${r?.visible ? '' : ' hidden'}`}
                  to={`/releases/${r?.releaseId}`}
                  key={`releases_link_${r?.releaseId}`}
                  onClick={hide}
                  tabIndex={0}
                >
                  <div className={'name-text'}>
                    {r?.name} {r?.draft ? '(draft)' : ''}
                  </div>
                </Link>
              ))}
          </>
        </div>
      ))}
    </>
  )
}

const ReleasesDropdownItems = ({ hide }: { hide: () => void }) => {
  const { data, loading } = useGetReleasesDropdownQuery()
  const { actingSysAdmin, actingRelAdmin } = useAuth()

  const sections = useMemo(() => {
    const sectionsMap = new Map<string, DropdownSection>()

    data?.sections?.edges
      ?.filter(
        s =>
          Boolean(s?.node?.releases?.edges.length) &&
          (s?.node?.releases?.edges.some(s => s?.node?.hasPublishedPosts) || actingRelAdmin || actingSysAdmin)
      )
      .sort((a, b) => {
        return (a?.node?.index ?? 0) - (b?.node?.index ?? 0)
      })
      .forEach(s => {
        sectionsMap.set(s?.node?.sectionId ?? '', {
          sectionId: s?.node?.sectionId,
          visible: s?.node?.visible,
          name: s?.node?.name,
          releaseOrder: s?.node?.releaseOrder,
          opened: !s?.node?.collapseSection,
          releases: s?.node?.releases?.edges
            ?.filter(r => r?.node?.hasPublishedPosts || actingSysAdmin || actingRelAdmin)
            .map(e => e?.node),
        } as DropdownSection)
      })

    return sectionsMap
  }, [actingRelAdmin, actingSysAdmin, data?.sections])

  if (!data && loading) return <>Loading...</>

  return <ReleasesDropdownItemsSections sectionsMap={sections} hide={hide} />
}

export default ReleasesDropdownItems
