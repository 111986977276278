import React from 'react'
import { useCommunities } from '~/contexts/CommunitiesContext'
import { Link } from 'react-router-dom'
import '@css/pages/feed/MyCommunitiesTile.scss'
import arrow from '@web/images/feed/link-arrow.svg'
import { useAuth } from '~/auth/Auth'
import { sortCommunityList } from '~/utils'
import { elementClicked } from '~/common/EventLogger'

const MyCommunitiesTile = () => {
  const { myCommunities } = useCommunities()
  const { companyId } = useAuth()

  return (
    <div className={'feed-tile-full'} role={'my-communities-tile'}>
      <div className={'tile-title'}>
        <h1>My Communities</h1>
      </div>
      <div className={'tile-container'}>
        {sortCommunityList(companyId, myCommunities)?.map(community => (
          <Link
            onClick={e =>
              elementClicked(e, 'click-my-community-tile', {
                type: community.type,
                community_id: community.communityId,
              })
            }
            to={
              community.type === 'Company'
                ? `/companies/${community.companyId}`
                : `/communities/${community.communityId}`
            }
            className={'tile community-link'}
            key={community.communityId}
          >
            <div
              className={'profile-photo community-photo'}
              style={community.photo ? { backgroundImage: `url(${community.photo})` } : {}}
            />
            <div className={'community-name'}>{community.name}</div>
            <img src={arrow} alt={'Go'} />
          </Link>
        ))}
        <div className={'tile community-link'}>
          <Link to={'/allcommunities'} onClick={e => elementClicked(e, 'click-feed-discover-communities')}>
            Discover more communities
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MyCommunitiesTile
