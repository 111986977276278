import React, { SyntheticEvent, useCallback, useState } from 'react'
import '@css/pages/community/CommunityTable.scss'
import '@css/pages/community/CommunityMembers.scss'
import '@css/pages/company/CompanyAccountPartners.scss'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import ToastComponent from '~/common/ToastComponent'
import {
  GQLMembership,
  useChangeMemberTypeMutation,
  useGetCommunityMemberRowUserQuery,
  useRemoveMemberMutation,
} from '~/api/generated/graphql'
import { useAuth } from '~/auth/Auth'
import { useNavigate } from 'react-router'
import { getFullName, removeMemberCache } from '~/utils'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { usePermissions } from '~/pages/posts/PostUtils'
import MemberRemove from '@web/images/community/MemberRemove'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { elementClicked } from '~/common/EventLogger'
import { useCommunity } from '~/contexts/CommunityContext'

interface CommunityMemberRowProps {
  member: GQLMembership
  canRemoveLeader: boolean
  setParentModalText?: (s: string) => void
}

const CommunityMemberRow = ({ member, canRemoveLeader, setParentModalText }: CommunityMemberRowProps) => {
  const { isCondensed } = useWindowSize()
  const { authUserId, profileVisible } = useAuth()
  const { hasLeaderPermissions, loading } = usePermissions()
  const { communityId } = useCommunity()
  const navigate = useNavigate()

  const [isManaging, setIsManaging] = useState<boolean>(false)
  const showEditPencil = hasLeaderPermissions && !isManaging && profileVisible
  const { data: memberUserData } = useGetCommunityMemberRowUserQuery({
    variables: { id: member.userId || '' },
    skip: !member.userId,
  })
  const memberUser = memberUserData?.user
  const profileLink = `/profiles/${member.userId}`
  const [memberType, setMemberType] = useState<string>(member.leader ? 'Leader' : 'Member')
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)
  const [isHoveringRemove, setIsHoveringRemove] = useState(false)

  const [modalText, setModalText] = useState<string | null>(null)
  const [toast, setToast] = useState('')
  const [showToast, setShowToast] = useState(false)

  const handleHideModal = useCallback(() => {
    setModalText(null)
  }, [])

  const memberCompanyName = memberUser?.company?.name
  const memberTitle = memberUser?.title

  const [changeMemberType] = useChangeMemberTypeMutation()

  const handleEditClick = (e: SyntheticEvent) => {
    setIsManaging(!isManaging)
    elementClicked(e, '', { userId: memberUser?.userId, communityId: communityId })
  }

  const updateMember = async () => {
    const isBecomingLeader = memberType === 'Leader'
    if (!isBecomingLeader && member.leader && !canRemoveLeader) {
      setToast('At least one leader is required in a community')
      setShowToast(true)
      return
    }
    if (isBecomingLeader !== member.leader) {
      const response = await changeMemberType({
        variables: {
          userId: member.userId ?? '',
          communityId: member.communityId ?? '',
          leader: isBecomingLeader,
        },
      })
      if (response.data?.changeMemberType?.ok) {
        const fullName = `${member.user?.firstName} ${member.user?.lastName}`
        setToast(
          isBecomingLeader
            ? `${fullName} is now a leader of the community.`
            : `${fullName} is no longer a leader of the community.`
        )
        setShowToast(true)
      } else {
        setToast(response.data?.changeMemberType?.error?.message ?? '')
        setShowToast(true)
      }
    }
    setIsManaging(false)
  }

  const [removeMember] = useRemoveMemberMutation({
    update: (cache, { data }) => {
      data?.removeMember?.ok && removeMemberCache(cache, data)
    },
  })

  const handleClickRemove = () => {
    if (member.leader && !canRemoveLeader) {
      setToast('At least one leader is required in a community')
      setShowToast(true)
    } else setShowRemoveDialog(true)
  }
  const handleCancelRemove = () => setShowRemoveDialog(false)
  const handleConfirmDelete = async () => {
    if (!member.leader || canRemoveLeader) {
      const response = await removeMember({
        variables: {
          userId: member.userId ?? '',
          communityId: member.communityId ?? '',
        },
      })
      setShowRemoveDialog(false)
      if (authUserId === member.userId) {
        navigate(`/communities/${member.communityId}/about`)
      }
      if (response?.data?.removeMember?.ok) {
        setParentModalText?.('The member was removed successfully')
      } else if (response?.data?.removeMember?.error) {
        switch (response.data.removeMember.error.code) {
          case 'cannotRemoveNonVeevan':
            setModalText(`Employees cannot be removed from their customer homepage`)
            break
          default: // fallback just in case
            setModalText('Something went wrong. Please try again.')
            break
        }
      }
    } else {
      setToast(`At least one Leader is required in a community`)
      setShowToast(true)
    }
  }

  const memberFullName = getFullName(memberUser)
  const profileTitle = 'View ' + memberFullName + "'s Profile"

  const editRef = useClickOnEnter<HTMLImageElement>()
  const memberInfoRef = useClickOnEnter<HTMLDivElement>()

  const modals = (
    <>
      <Modal show={showRemoveDialog} onHide={handleCancelRemove} className={'delete'}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>Are you sure you want to remove this member from this community?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={handleCancelRemove}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={handleConfirmDelete}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!modalText} onHide={handleHideModal}>
        <Modal.Header closeButton>
          <Modal.Body>
            <p>{modalText}</p>
          </Modal.Body>
        </Modal.Header>
      </Modal>
      <ToastComponent show={showToast} onClose={() => setShowToast(false)}>
        {toast ? toast : ''}
      </ToastComponent>
    </>
  )

  const memberIsVeevan = memberUser?.isVeevan
  const managingLayout = (
    <div className={'managing-wrapper'}>
      {memberIsVeevan && (
        <div className={'dropdown-container'}>
          <Dropdown id="dropdown-member-type" role="type-dropdown">
            <Dropdown.Toggle id="dropdown-basic" role={'dropdown-toggle'}>
              <span>{memberType}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item
                data-testid={'leader-option'}
                className={`${memberType === 'Leader' ? 'selected' : 'unselected'}`}
                onClick={() => setMemberType('Leader')}
              >
                Leader
              </Dropdown.Item>
              <Dropdown.Item
                data-testid={'member-option'}
                className={`${memberType === 'Member' ? 'selected' : 'unselected'}`}
                onClick={() => setMemberType('Member')}
              >
                Member
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div className={`community-role-edit-section ${!memberIsVeevan ? 'non-veevan-member' : ''}`}>
        <div className={`button-row-top`}>
          <Button
            variant="light"
            onClick={() => {
              setMemberType(member.leader ? 'Leader' : 'Member')
              setIsManaging(!isManaging)
            }}
            role={'cancel-button'}
            tabIndex={0}
          >
            Cancel
          </Button>
          {memberIsVeevan && (
            <Button onClick={() => updateMember()} role={'save-button'} tabIndex={0}>
              Save
            </Button>
          )}
          <span
            className="remove-button"
            onClick={handleClickRemove}
            onMouseOver={() => setIsHoveringRemove(true)}
            onMouseOut={() => setIsHoveringRemove(false)}
            role={'remove-button'}
            title="Remove member"
            tabIndex={0}
          >
            {isHoveringRemove ? <MemberRemove fill={'#c00000'} /> : <MemberRemove fill={'#676969'} />}
          </span>
        </div>
      </div>
    </div>
  )

  const standardLayout = () => (
    <>
      <div className="table-row members">
        <div className={`image-with-details wide-column`} title={profileTitle}>
          <Link to={profileLink} tabIndex={-1}>
            <ProfilePhoto userId={memberUser?.userId} />
          </Link>
          <div
            className="member-container"
            tabIndex={0}
            ref={memberInfoRef}
            onClick={() => {
              navigate(profileLink)
            }}
          >
            <span className="member-name" tabIndex={-1}>
              {memberFullName}
            </span>
            <span className="member-title" tabIndex={-1}>
              {memberTitle}
            </span>
          </div>
        </div>
        <div className="semi-wide-column row-text" role="company">
          {memberCompanyName}
        </div>
        {!isManaging && (
          <div className="semi-wide-column row-text" role="type">
            {memberType}
          </div>
        )}
        <div className={`${!isManaging ? 'edit-button-container' : 'manage-container'}`}>
          {isManaging ? (
            managingLayout
          ) : (
            <div className="edit-actions">
              {showEditPencil && (
                <img
                  className="edit-button"
                  src={pencilIcon}
                  alt="Edit"
                  onClick={handleEditClick}
                  role={'button'}
                  ref={editRef}
                  tabIndex={0}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {modals}
    </>
  )

  const mobileLayout = () => (
    <div className={'condensed-member'}>
      <div className={'member-card'}>
        {isManaging && memberIsVeevan ? (
          <div className={`member-type-selection`}>
            <Dropdown id="dropdown-member-type" role="type-dropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <span>{memberType}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item
                  className={`${memberType === 'Leader' ? 'selected' : 'unselected'}`}
                  onClick={() => setMemberType('Leader')}
                >
                  Leader
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${memberType === 'Member' ? 'selected' : 'unselected'}`}
                  onClick={() => setMemberType('Member')}
                >
                  Member
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <>{member.leader && !isManaging && <span className={'member-leader'}>Leader</span>}</>
        )}
        {isManaging && (
          <div className={'button-row'}>
            <span className="remove-button" onClick={handleClickRemove} role={'remove-button'} tabIndex={0}>
              <MemberRemove fill={'#c00000'} />
            </span>
            <Button
              variant="light"
              onClick={() => {
                setMemberType(member.leader ? 'Leader' : 'Member')
                setIsManaging(!isManaging)
              }}
              role={'cancel-button'}
              tabIndex={0}
            >
              Cancel
            </Button>
            {memberIsVeevan && (
              <Button onClick={() => updateMember()} role={'save-button'} tabIndex={0}>
                Save
              </Button>
            )}
          </div>
        )}
        <div className={`condensed-image-with-details`} title={profileTitle}>
          <Link to={profileLink} tabIndex={-1}>
            <ProfilePhoto userId={member.userId} />
          </Link>
          <div
            className="member-details"
            tabIndex={0}
            ref={memberInfoRef}
            onClick={() => {
              navigate(profileLink)
            }}
          >
            <span className="member-name" tabIndex={-1}>
              {memberFullName}
            </span>
            <span className="member-title" tabIndex={-1}>
              {memberTitle}
            </span>
            <span className="member-company" tabIndex={-1}>
              {memberCompanyName}
            </span>
          </div>
        </div>
      </div>
      {showEditPencil && (
        <img
          className="edit-button"
          src={pencilIcon}
          alt="Edit"
          onClick={handleEditClick}
          role={'button'}
          ref={editRef}
          tabIndex={0}
        />
      )}
      {modals}
    </div>
  )
  if (loading) return null
  if (isCondensed) return mobileLayout()
  else return standardLayout()
}

export default CommunityMemberRow
