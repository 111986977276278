import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '@css/common/MultipleEditor.scss'
import EditorRow from '~/common/quill/EditorRow'
import {
  GQLHtmlWithMentions,
  GQLMediaAlignmentType,
  GQLMediaType,
  GQLMeetupInput,
  Maybe,
} from '~/api/generated/graphql'
import ReactQuill from 'react-quill'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'

const emptyStoryHTML = { htmlWithMentions: '<p><br></p>' } // typing then deleting text leaves a p tag
const emptyStory = JSON.stringify(emptyStoryHTML)

export type EditorRowData = {
  story?: Maybe<GQLHtmlWithMentions>
  uploadId?: string
  videoUrl?: string
  alignment: GQLMediaAlignmentType
  mediaUrl?: string
  mediaType?: GQLMediaType
  filename?: string
  file?: File
  directUrlUpload?: string
  quillRef?: React.MutableRefObject<ReactQuill | null>
  forceSetFocus?: boolean
}

type MultipleEditorProps = {
  rowsData: EditorRowData[]
  originalRowsData: EditorRowData[]
  setRowsData: React.Dispatch<React.SetStateAction<EditorRowData[]>>
  className?: string
  placeholder?: string
  communityId: string
  postId?: string
  commentId?: string
  veevanOnlyMentions?: boolean
  noToolbar?: boolean
  onSubmit?: (args: {
    skipWarning?: boolean
    warned?: boolean
    e?: SyntheticEvent | KeyboardEvent
    meetup?: GQLMeetupInput
  }) => Promise<void>
  updateQuillRef?: (ref: ReactQuill) => void
  setMeetup?: (meetup: GQLMeetupInput) => void
  isComment?: boolean
  primaryVideoUrl?: Maybe<string>
  allowVideoTimestamps?: boolean
}

const MultipleEditor = ({
  originalRowsData,
  rowsData,
  setRowsData,
  className,
  placeholder,
  communityId,
  postId,
  veevanOnlyMentions,
  noToolbar,
  onSubmit,
  updateQuillRef,
  setMeetup,
  isComment,
  commentId,
  primaryVideoUrl,
  allowVideoTimestamps,
}: MultipleEditorProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number>(0)
  const heightRef = useRef<HTMLDivElement>(null)
  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md

  const { setDraftingComment, setDraftingPost } = useDraftingCommentPost()
  const getRowData = (r: EditorRowData) => {
    return {
      story: r.story?.htmlWithMentions || '<p><br></p>',
      uploadId: r.uploadId,
      videoUrl: r.videoUrl,
      alignment: r.alignment,
      mediaUrl: r.mediaUrl,
      mediaType: r.mediaType,
      filename: r.filename,
      directUrlUpload: r.directUrlUpload,
    } as EditorRowData
  }

  const changed = useMemo(() => {
    return !(
      originalRowsData.length === rowsData.length &&
      originalRowsData.every((r, i) => JSON.stringify(getRowData(r)) == JSON.stringify(getRowData(rowsData[i])))
    )
  }, [originalRowsData, rowsData])

  useEffect(() => {
    if (isComment) {
      // If we are currently in the comment add box, then we don't have an associated commentId, so we use the postId as a "placeholder" id to keep track of it
      setDraftingComment?.(`${veevanOnlyMentions ? 'vd:' : ''}${commentId ?? postId ?? ''}`, changed, postId)
    } else {
      // If we are currently in the post add box, then we don't have an associated postId, so we use the communityId as a "placeholder" id to keep track of it
      setDraftingPost?.(postId ?? communityId ?? '', changed, false)
    }
  }, [changed, commentId, communityId, isComment, postId, setDraftingComment, setDraftingPost, veevanOnlyMentions])

  const hasMeetup = useMemo(() => {
    return rowsData.some(r => Array.from(r.story?.htmlWithMentions?.matchAll(/<meetup.+<\/meetup>/gm) ?? []).length)
  }, [rowsData])

  const addLeftMedia = useCallback(() => {
    setRowsData(rowsData => {
      const rowsDataCopy = [...rowsData]
      rowsDataCopy.splice(focusedIndex, 1, {
        alignment: GQLMediaAlignmentType.LeftMedia,
        story: rowsData[focusedIndex].story,
      })
      return rowsDataCopy
    })
  }, [setRowsData, focusedIndex])

  const addRightMedia = useCallback(() => {
    setRowsData(rowsData => {
      const rowsDataCopy = [...rowsData]
      rowsDataCopy.splice(focusedIndex, 1, {
        alignment: GQLMediaAlignmentType.RightMedia,
        story: rowsData[focusedIndex].story,
      })
      return rowsDataCopy
    })
  }, [setRowsData, focusedIndex])

  const addFullText = useCallback(
    (index: number) => {
      const rowsDataCopy = [...rowsData]
      rowsDataCopy.splice(index + 1, 0, {
        alignment: GQLMediaAlignmentType.FullText,
      })
      setRowsData(rowsDataCopy)
      setAddedRow(true)
      setNewIndex(index + 1)
    },
    [setRowsData, rowsData]
  )

  const addFullMedia = useCallback(() => {
    // If there is an existing story in the current row we want to add a new full media row.
    // If the row does not contain a story then we want to replace the current row with the full media row.
    setRowsData(rowsData => {
      const hasStory = rowsData[focusedIndex].story && JSON.stringify(rowsData[focusedIndex].story) !== emptyStory
      const i = hasStory ? focusedIndex + 1 : focusedIndex
      const rowsDataCopy = [...rowsData]
      rowsDataCopy.splice(i, hasStory ? 0 : 1, {
        alignment: GQLMediaAlignmentType.FullMedia,
      })

      return rowsDataCopy
    })
  }, [setRowsData, focusedIndex])

  const addPastedImage = useCallback(
    (file: File, index: number) => {
      setRowsData(rowsData => {
        const data = [...rowsData]
        if (data[index].alignment == GQLMediaAlignmentType.FullText) {
          const hasStory = data[index].story && JSON.stringify(data[index].story) !== emptyStory
          data.splice(index + (hasStory ? 1 : 0), hasStory ? 0 : 1, {
            file,
            alignment: GQLMediaAlignmentType.FullMedia,
          })
        } else {
          data[index].file = file
        }
        return data
      })
    },
    [setRowsData]
  )

  const hideBorder = rowsData.length === 1 && rowsData[0].alignment === GQLMediaAlignmentType.FullText
  const [dragging, setDragging] = useState<boolean>(false)
  const [addedRow, setAddedRow] = useState<boolean>(false)
  const [newIndex, setNewIndex] = useState<number>(-1)

  const onDragStart = () => {
    setDragging(true)
  }

  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      const originalIndex = result.source.index
      const newIndex = result.destination.index
      const rowsDataCopy = [...rowsData]
      const [removed] = rowsDataCopy.splice(originalIndex, 1)
      rowsDataCopy.splice(newIndex, 0, removed)
      setRowsData(rowsDataCopy)
      setFocusedIndex(newIndex)
    }
    setDragging(false)
  }

  // Changing the focused index in addFullText does not set focus to the correct row and causes odd behavior.
  // Instead, we can keep track of addedRow to see when to set the newly inserted row as focused.
  useEffect(() => {
    if (addedRow) {
      setFocusedIndex(newIndex)
      setAddedRow(false)
    }
  }, [addedRow, dragging, newIndex])

  const insertPill = (index: number) => {
    return (
      <>
        {!noToolbar && (
          <div className={'editor-row-border'}>
            {!dragging && (
              <div
                className={'insert-pill'}
                onClick={() => {
                  addFullText(index)
                }}
                data-testid={'insert-pill'}
              >
                Insert Row
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  const handleMediaChange = useCallback(
    (index: number, type: GQLMediaType, uploadId?: string, filename?: string, videoUrl?: string, mediaUrl?: string) => {
      setRowsData(rowsData => {
        const data = [...rowsData]
        data[index].mediaType = type
        data[index].uploadId = uploadId
        data[index].videoUrl = videoUrl
        data[index].filename = filename
        data[index].mediaUrl = mediaUrl
        return data
      })
    },
    [setRowsData]
  )

  const handleDeleteMedia = useCallback(
    (index: number, removeTarget: boolean) => {
      setRowsData(rowsData => {
        const data = [...rowsData]
        data[index].uploadId = undefined
        data[index].videoUrl = undefined
        data[index].mediaUrl = undefined
        data[index].filename = undefined
        data[index].mediaType = undefined

        const fullMedia = data[index].alignment == GQLMediaAlignmentType.FullMedia

        if ((!fullMedia || (index == 0 && data.length == 1)) && removeTarget) {
          data[index].alignment = GQLMediaAlignmentType.FullText
          return data
        } else {
          const spliced = [...data]
          spliced.splice(index, 1)
          focusedIndex == index && removeTarget ? setFocusedIndex(index > 0 ? index - 1 : index + 1) : null
          return removeTarget ? spliced : data
        }
      })
    },
    [setFocusedIndex, setRowsData, focusedIndex]
  )

  const handleDirectUrlUpload = useCallback(
    (index: number, uploadedVideo: string) => {
      setRowsData(rowsData => {
        const data = [...rowsData]
        data[index].directUrlUpload = uploadedVideo
        return data
      })
    },
    [setRowsData]
  )

  const setMediaType = useCallback(
    (index: number, type: GQLMediaType) => {
      setRowsData(rowsData => {
        const data = [...rowsData]
        data[index].mediaType = type
        return data
      })
    },
    [setRowsData]
  )

  const setMediaName = useCallback(
    (index: number, mediaName: string) => {
      setRowsData(rowsData => {
        const data = [...rowsData]
        data[index].filename = mediaName
        return data
      })
    },
    [setRowsData]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId={'droppable'}>
        {provided => (
          <div
            className={`multiple-editor-container${rowsData.length > 1 ? ' extra-padding' : ''}${
              hideBorder ? ' hide-border' : ''
            } ${className ?? ''}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div ref={heightRef}>
              {/* to prevent a difficult to trace bug which was causing the editor to not fully be cleared when it's cleared
       due to quill holding onto some state, gracefully handle the case where there are no rows by just simply
       showing a placeholder element*/}
              {rowsData?.length ? (
                <>
                  {rowsData.map((row, index) => {
                    const handleInputChange = (story: Maybe<GQLHtmlWithMentions>) => {
                      const data = [...rowsData]
                      data[index].story = story
                      setRowsData(data)
                    }
                    const handleDelete = () => {
                      const data = [...rowsData]
                      // Parent component has ref to first quill element to focus it on 'Start Veeva Discussion'
                      if (index == 0 && data[1]) {
                        data[1].quillRef = data[0].quillRef
                      }
                      data.splice(index, 1)
                      setRowsData([...data])
                    }
                    return (
                      <Draggable draggableId={index.toString()} index={index} key={index}>
                        {provided => (
                          <div key={index} className={'editor-row-wrapper'}>
                            <EditorRow
                              row={row}
                              onInputChange={handleInputChange}
                              onMediaChange={handleMediaChange}
                              setMediaType={setMediaType}
                              setMediaName={setMediaName}
                              setDirectUrlUpload={handleDirectUrlUpload}
                              onDelete={handleDelete}
                              onDeleteMedia={handleDeleteMedia}
                              onFocus={() => setFocusedIndex(index)}
                              focused={focusedIndex === index}
                              onLeftMediaClick={addLeftMedia}
                              onFullMediaClick={addFullMedia}
                              onRightMediaClick={addRightMedia}
                              onAddPastedImage={addPastedImage}
                              placeholder={placeholder}
                              forceHideDelete={rowsData.length <= 1}
                              communityId={communityId}
                              postId={postId ?? ''}
                              commentId={commentId ?? ''}
                              veevanOnlyMentions={veevanOnlyMentions}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              draggableInnerRef={provided.innerRef}
                              draggingRow={dragging && focusedIndex === index}
                              dragging={dragging}
                              totalRows={rowsData.length}
                              isCondensed={isCondensed}
                              insertPill={insertPill(index)}
                              index={index}
                              setFocusedIndex={setFocusedIndex}
                              noToolbar={noToolbar}
                              onSubmit={onSubmit}
                              hasMeetup={hasMeetup}
                              updateQuillRef={updateQuillRef}
                              setMeetup={setMeetup}
                              primaryVideoUrl={primaryVideoUrl}
                              allowVideoTimestamps={allowVideoTimestamps}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                </>
              ) : (
                <span className={'placeholder'}>{placeholder}</span>
              )}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default MultipleEditor
