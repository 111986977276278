import { useAuth } from '~/auth/Auth'
import { useCommunity } from '~/contexts/CommunityContext'
import { useMemo } from 'react'
import { ChildNode, Comment, Element, ProcessingInstruction, Text } from 'domhandler'
import * as HTMLReactParser from 'html-react-parser'
import {
  CanAddContentDocument,
  GetContentsDocument,
  GetFollowedPostsDocument,
  GetPostsDocument,
  GQLCanAddContentQuery,
  GQLGetContentsQuery,
  GQLGetFollowedPostsQuery,
  GQLGetPostsQuery,
  GQLHtml,
  GQLHtmlWithMentions,
  GQLPost,
  GQLPostEdge,
  GQLPostType,
  Maybe,
  useGetPostPermissionsQuery,
  useGetUserCommunitiesQuery,
} from '~/api/generated/graphql'
import { asString } from '~/utils'
import { ApolloCache, ApolloClient } from '@apollo/client'

export const calculateFieldLengthSeverity = (fieldLength: number, maxLength: number) => {
  return fieldLength > maxLength ? ' red' : fieldLength > maxLength - 10 ? ' yellow' : ' gray'
}

export const usePermissions = (
  authorId?: Maybe<string>,
  postId?: string
): {
  canEdit: boolean
  canDelete: boolean
  hasLeaderPermissions: boolean
  hasMemberPermissions: boolean
  loading: boolean
} => {
  const { authUserId, actingSysAdmin } = useAuth()
  const userId = authUserId
  const { data, loading } = useGetUserCommunitiesQuery({ variables: { id: authUserId ?? '' }, skip: !authUserId })
  const { data: postData, loading: postsDataLoading } = useGetPostPermissionsQuery({
    variables: { id: postId ?? '' },
    skip: !postId,
  })
  const communityIds = useMemo(() => data?.user?.memberships?.edges.map(e => e?.node?.communityId) || [], [data])
  const leadershipIds = useMemo(
    () => data?.user?.memberships?.edges?.filter(e => e?.node?.leader).map(e => e?.node?.communityId) || [],
    [data]
  )
  const { communityId } = useCommunity()
  const isLeader = useMemo(
    () => actingSysAdmin || leadershipIds.some(c => c === communityId),
    [leadershipIds, communityId, actingSysAdmin]
  )
  const isMember = useMemo(
    () => actingSysAdmin || communityIds.some(c => c === communityId),
    [communityIds, communityId, actingSysAdmin]
  )

  const canEdit = Boolean(
    (authorId === userId ||
      (!postData?.post?.draft && isLeader && postData?.post?.postType == GQLPostType.Content) ||
      (postData?.post?.draft && actingSysAdmin)) &&
      !postData?.post?.hidden
  )

  const canDelete = canEdit || actingSysAdmin || isLeader

  return {
    canEdit,
    canDelete,
    hasLeaderPermissions: isLeader,
    hasMemberPermissions: isMember,
    loading: loading || postsDataLoading,
  }
}

export const getNodeText = (
  node:
    | (ChildNode | Comment | Element | ProcessingInstruction | Text)[]
    | (ChildNode | Comment | Element | ProcessingInstruction | Text)
): string => {
  if (!node) return ''
  if (node instanceof Text) return node.data
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (node instanceof Element) return getNodeText(node.childNodes)
  return ''
}

export const getHtmlLength = (content: Maybe<GQLHtml> | Maybe<GQLHtmlWithMentions>) => {
  const value = asString(content) ?? ''
  return getNodeText(HTMLReactParser.htmlToDOM(value)).trim().length
}

export const updateCachePosts = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  authUserId: string | null | undefined,
  newPost?: GQLPost
) => {
  if (!newPost) return
  const communityId = newPost.communityId
  const oldFollowedPosts = cache.readQuery<GQLGetFollowedPostsQuery>({
    query: GetFollowedPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
  })

  const oldPosts = cache.readQuery<GQLGetPostsQuery>({
    query: GetPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
  })

  const getSortedEdges = (oldEdges: GQLPostEdge[]): GQLPostEdge[] => {
    const edges = [
      { node: newPost, typename: 'PostEdge' },
      ...(oldEdges?.filter(e => e.node?.postId !== newPost.postId) ?? []),
    ]
    return edges.sort((a, b) => {
      const aCreatedDate = new Date(a?.node?.createdTime)
      const bCreatedDate = new Date(b?.node?.createdTime)
      if (a?.node?.draft === b?.node?.draft) {
        if (bCreatedDate > aCreatedDate) return 1
        return -1
      } else if (b?.node?.draft) {
        return 1
      } else if (a?.node?.draft) {
        return -1
      }
      return 0
    }) as GQLPostEdge[]
  }

  const newFollowedPostsData = {
    posts: {
      ...(oldFollowedPosts?.posts ?? { pageInfo: {} }),
      edges: getSortedEdges(oldFollowedPosts?.posts?.edges as GQLPostEdge[]).filter(p => p.node?.isFollowing),
    },
  }

  const newPostsData = {
    posts: {
      ...(oldPosts?.posts ?? { pageInfo: {} }),
      edges: getSortedEdges(oldPosts?.posts?.edges as GQLPostEdge[]),
    },
  }

  client.writeQuery({
    query: GetFollowedPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
    data: newFollowedPostsData,
  })

  client.writeQuery({
    query: GetPostsDocument,
    variables: { communityId: communityId ?? '', userId: authUserId ?? '' },
    data: newPostsData,
  })
}

export const updateCacheContent = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  communityId: string | null | undefined,
  authUserId: string | null | undefined,
  newPost?: GQLPost
) => {
  if (!newPost) return
  const oldCount = cache.readQuery<GQLCanAddContentQuery>({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? newPost.communityId },
  })

  const oldContent = cache.readQuery<GQLGetContentsQuery>({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? newPost.communityId, userId: authUserId ?? '' },
  })

  const oldData = oldCount?.canAddContent
  const newCount = (oldData?.count ?? 0) + (newPost?.draft ? 0 : 1)

  const newCountData = {
    canAddContent: {
      ok: newCount !== oldData?.max,
      count: newCount > (oldData?.max ?? 0) ? oldCount : newCount,
      max: oldData?.max ?? 0,
      __typename: 'ContentValidator',
    },
  }

  const newContentData = {
    posts: {
      ...oldContent?.posts,
      edges: [{ node: newPost, typename: 'PostEdge' }, ...(oldContent?.posts?.edges ?? [])],
    },
  }

  client.writeQuery({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? newPost.communityId },
    data: newCountData,
  })

  client.writeQuery({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? newPost.communityId, userId: authUserId ?? '' },
    data: newContentData,
  })
}

export const removeCacheContent = (
  cache: ApolloCache<object>,
  client: ApolloClient<object>,
  communityId: string | null | undefined,
  authUserId: string | null | undefined,
  removedContent?: GQLPost
) => {
  if (!removedContent) return
  const oldCount = cache.readQuery<GQLCanAddContentQuery>({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? removedContent.communityId },
  })

  const oldContent = cache.readQuery<GQLGetContentsQuery>({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? removedContent.communityId, userId: authUserId ?? '' },
  })

  const oldData = oldCount?.canAddContent
  const newCount = (oldData?.count ?? 0) - 1

  const newCountData = {
    canAddContent: {
      ok: newCount !== oldData?.max,
      count: newCount > (oldData?.max ?? 0) ? oldCount : newCount,
      max: oldData?.max ?? 0,
      __typename: 'ContentValidator',
    },
  }

  const newContentData = {
    posts: {
      edges: oldContent?.posts?.edges.filter(e => e?.node?.postId !== removedContent.postId) ?? { node: {} },
    },
  }

  client.writeQuery({
    query: CanAddContentDocument,
    variables: { communityId: communityId ?? removedContent.communityId },
    data: newCountData,
  })

  client.writeQuery({
    query: GetContentsDocument,
    variables: { communityId: communityId ?? removedContent.communityId, userId: authUserId ?? '' },
    data: newContentData,
  })
}
