import React, { useRef } from 'react'
import { ProfileActivityRow } from '~/pages/profile/ProfileActivityRow'
import '@css/pages/profile/ProfileActivityList.scss'
import { Button } from 'react-bootstrap'
import { elementClicked } from '~/common/EventLogger'
import { useGetRecentActivityQuery } from '~/api/generated/graphql'
import { useProfile } from '~/contexts/ProfileContext'
import { AdvancedSearchState } from '~/pages/search/AdvancedSearch'
import { useNavigate } from 'react-router'

export const ProfileActivityList = () => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { userId, user } = useProfile()
  const navigate = useNavigate()

  const { data, loading, error } = useGetRecentActivityQuery({
    variables: { userId: userId ?? '', pageSize: 5 },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  })

  const activities = data?.recentActivity?.activities

  if (loading || !user) return <div className={'list-container loading'}>Loading...</div>
  if (!data || error) return null

  const clickShowMore = (e: React.MouseEvent) => {
    elementClicked(e, 'click-profile-recent-posts-show-more')
    const state: AdvancedSearchState = { selectedAuthorFacets: [{ name: user.fullName, authorId: user.userId }] }
    navigate('/search', { state })
    buttonRef?.current?.blur()
  }

  return (
    <>
      <div className="list-container" data-testid={'profile-activity-container'}>
        {(activities?.length ?? 0) > 0 ? (
          <>
            {activities?.map((a, index) => (
              <ProfileActivityRow key={index} postId={a?.post?.postId} commentId={a?.comment?.commentId} />
            ))}
          </>
        ) : (
          <div className="section-empty">No Recent Activity</div>
        )}
      </div>
      {data?.recentActivity?.hasNext && (
        <div className="show-more-activity">
          <Button ref={buttonRef} onClick={clickShowMore} tabIndex={0} className={'show-more-button'}>
            View more activity
          </Button>
        </div>
      )}
    </>
  )
}
