import React, { SyntheticEvent, useState } from 'react'
import '@css/common/EmployeeBadgeComponent.scss'
import { getFullName, getGlobalLeadList } from '~/utils'
import Globe from '@web/images/community/globe-icon.svg'
import { Maybe } from '@graphql-tools/utils'
import { useGetEmployeeBadgeMemberQuery, useGetEmployeeBadgeUserQuery } from '~/api/generated/graphql'
import { ProfilePhoto } from '~/common/ProfilePhoto'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import { Link } from 'react-router-dom'

export const EmployeeBadgeComponent = ({
  userId,
  communityId,
  clickEmployee,
  isLeaderBadge,
  isCompanyHome,
  showRole,
  memberType,
  isMention,
}: {
  userId: Maybe<string>
  communityId?: Maybe<string>
  clickEmployee?: (e: SyntheticEvent) => void
  isLeaderBadge?: boolean
  isCompanyHome?: boolean
  showRole?: boolean
  memberType?: string
  isMention?: boolean
}) => {
  const { data, loading } = useGetEmployeeBadgeUserQuery({ variables: { userId: userId ?? '' }, skip: !userId })
  const user = data?.user
  const title = showRole ? memberType : user?.title || undefined
  const fullName = getFullName(user)
  const companyName = user?.company?.name
  const inactive = user?.hidden

  const profileTitle = 'View ' + fullName + "'s Profile"
  const infoRef = useClickOnEnter<HTMLDivElement>()
  const badgeRef = useClickOnEnter<HTMLDivElement>()

  const [showTitle, setShowTitle] = useState(true)

  const memberData = useGetEmployeeBadgeMemberQuery({
    variables: {
      communityId: communityId ?? '',
      userId: userId ?? '',
    },
    skip: !userId || !communityId,
  })

  const member = memberData?.data?.membership

  const isGlobalLead = member?.accountLead || member?.commercialLead || member?.rdqLead
  const showGlobe = isLeaderBadge && isCompanyHome && isGlobalLead
  const globeTitle = getGlobalLeadList(
    Boolean(member?.accountLead),
    Boolean(member?.commercialLead),
    Boolean(member?.rdqLead)
  )

  if (!data && loading) return null

  const innerBadge = () => {
    return (
      <React.Fragment>
        <ProfilePhoto userId={userId} inactive={inactive} />
        <div className={`profile-info${inactive ? ' inactive' : ''}`} ref={infoRef}>
          <h6 className={`name${inactive ? ' inactive' : ''}`}>{fullName}</h6>
          <p className={`title${inactive ? ' inactive' : ''}`}>
            {inactive && companyName == 'Veeva' ? '(Inactive) ' : ''}
            {title}
          </p>
          <p className={`company${inactive ? ' inactive' : ''}`}>{companyName}</p>
        </div>
        {showGlobe && (
          <div
            className={'globe-container'}
            onMouseEnter={() => setShowTitle(false)}
            onMouseLeave={() => setShowTitle(true)}
          >
            <img className="globe" src={Globe} alt={'Global'} />
            <span className="globe-tooltip">{globeTitle}</span>
          </div>
        )}
      </React.Fragment>
    )
  }
  return (
    <div className={`user-badge${inactive ? ' inactive' : ''}`} title={showTitle ? profileTitle : ''} ref={badgeRef}>
      <div className="badge-body">
        {isMention ? (
          <a className={'badge-link'} href={`/profiles/${userId}`}>
            {innerBadge()}
          </a>
        ) : (
          <>
            {inactive ? (
              <div className={'badge-link inactive'}>{innerBadge()}</div>
            ) : (
              <Link
                className={'badge-link'}
                to={`/profiles/${userId}`}
                onClick={e => {
                  e.stopPropagation()
                  clickEmployee?.(e)
                }}
              >
                {innerBadge()}
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}
