import { KeyboardEventHandler } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { GQLValidateMeetupQuery } from '~/api/generated/graphql'

export const QuillMeetupModal = ({
  linkIsMeetup,
  validateMeetupLoading,
  meetupLink,
  displayText,
  setCopyLinkText,
  handleCodeKeyDown,
  handleLinkChanged,
  meetupData,
  showHasMeetupError,
  setDisplayText,
  meetupLinkResult,
  linkText,
  hasVideo,
}: {
  linkIsMeetup: boolean
  validateMeetupLoading: boolean
  meetupLink: string
  displayText: string
  setCopyLinkText: (copy: boolean) => void
  handleCodeKeyDown: KeyboardEventHandler<HTMLInputElement>
  handleLinkChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  showHasMeetupError: boolean
  setDisplayText: (displayText: string) => void
  linkText: string
  meetupLinkResult: string
  meetupData?: GQLValidateMeetupQuery | undefined
  hasVideo: boolean
}) => {
  const hasMeetupRespError: boolean = Boolean(meetupData?.validateMeetup?.error) || !meetupData?.validateMeetup

  const meetupRespText = () => {
    if (!hasMeetupRespError) return 'The calendar meeting was found.'
    switch (meetupData?.validateMeetup?.error?.code) {
      case 'notOrganizer':
        return 'You are not the organizer/owner for the calendar event.'
      case 'tooManyEntries':
        return 'More than one calendar event with the Zoom URL was found which isn’t supported.'
      case 'allDayMeetup':
        return 'All day calendar events are not allowed for meetups.'
      default:
        return 'The Zoom URL did not match a meeting on your Google calendar.'
    }
  }

  return (
    <>
      <div>
        <p>Display Text</p>
        <Form.Control
          className={'form-control'}
          type={'text'}
          onChange={e => {
            setCopyLinkText(false)
            setDisplayText(e.target.value)
          }}
          value={displayText}
          onKeyDown={() => {
            setCopyLinkText(false)
          }}
          disabled={linkIsMeetup}
          data-testid={'link-modal-display-text'}
        />
      </div>
      {!meetupLinkResult && (
        <div>
          <p>Edit Link</p>
          <Form.Control
            className={'form-control'}
            type={'text'}
            value={hasVideo ? '' : linkText}
            onKeyDown={handleCodeKeyDown}
            onChange={handleLinkChanged}
            autoFocus={true}
            disabled={hasVideo || (linkIsMeetup && validateMeetupLoading)}
            data-testid={'link-modal-link-text'}
          />
        </div>
      )}
      {validateMeetupLoading && (
        <div className="meetup-loading">
          <div className="spinner-wrapper">
            <Spinner animation="border" role="status" style={{ width: '18px', height: '18px' }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
          <span>Loading calendar meeting...</span>
        </div>
      )}
      {!validateMeetupLoading && meetupLink && (
        <>
          {meetupLinkResult && (
            <div>
              <p className="meetup-title">Meetup URL (Calendar meeting link)</p>
              <a className="meetup-link" href={meetupLinkResult}>
                {meetupLinkResult}
              </a>
            </div>
          )}
          <span className={`meetup-confirmation-text ${hasMeetupRespError ? 'error' : ''}`}>{meetupRespText()}</span>
        </>
      )}
      {showHasMeetupError && (
        <span className={`meetup-confirmation-text ${showHasMeetupError ? 'error' : ''}`}>
          Multiple meetups are not allowed.
        </span>
      )}
    </>
  )
}
