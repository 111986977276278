import React, { EventHandler, RefObject, SyntheticEvent, useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import ToastComponent from '~/common/ToastComponent'
import { getFullName, getPostPath } from '~/utils'
import MentionableText from '~/common/MentionableText'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import Counts from '~/pages/posts/Counts'
import { useAuth, useSearchCacheContext } from '~/auth/Auth'
import {
  GQLPostType,
  useGetActivityAuthorQuery,
  useGetActivityCommentQuery,
  useGetActivityCommunityQuery,
  useGetActivityPostQuery,
} from '~/api/generated/graphql'
import { PostDropdownItems } from '~/pages/posts/PostDropdownItems'
import { CommentEdit } from '~/pages/posts/CommentEdit'
import { PostEdit } from '~/pages/posts/PostEdit'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'
import TimeAgo from '~/common/TimeAgo'
import { elementClicked } from '~/common/EventLogger'
import { Link } from 'react-router-dom'

type Props = {
  commentId?: string
  postId?: string
}

export const ProfileActivityRow = ({ commentId, postId }: Props) => {
  // Ensure the user cache data is loaded before we try to fetch any data since it should all be
  // present in the cache.
  const { loading } = useSearchCacheContext()
  const { isCondensedPortrait } = useWindowSize()
  const navigate = useNavigate()
  const { isVeevan, actingSysAdmin } = useAuth()
  const [isEditingActivity, setIsEditingActivity] = useState<boolean>(false)
  const isComment = !!commentId

  const { data: postData, loading: postLoading } = useGetActivityPostQuery({
    variables: { id: postId ?? '' },
    skip: !postId,
  })
  const { data: commentData, loading: commentLoading } = useGetActivityCommentQuery({
    variables: { id: commentId ?? '' },
    skip: !commentId,
  })

  const communityId = isComment ? commentData?.comment?.post?.communityId : postData?.post?.communityId
  const authorId = isComment ? commentData?.comment?.createdById : postData?.post?.createdById
  const post = isComment ? commentData?.comment?.post : postData?.post
  const activityTime = new Date(isComment ? commentData?.comment?.createdTime : postData?.post?.createdTime)
  const hidden = isComment ? commentData?.comment?.hidden : postData?.post?.hidden
  const activityPostId = (isComment ? commentData?.comment?.postId : postId) ?? ''

  const { data: authorData, loading: loadingAuthor } = useGetActivityAuthorQuery({
    variables: { id: authorId ?? '' },
    skip: !authorId || loading,
  })
  const profilePhoto = authorData?.user?.photo

  const loadingAuthors = loadingAuthor && !authorData
  const fullName = getFullName(authorData?.user)

  const { data: communityData, loading: loadingCommunity } = useGetActivityCommunityQuery({
    variables: { id: communityId ?? '' },
    skip: !communityId || loading,
  })
  const community = communityData?.community

  const communityLink = community?.companyId
    ? `/companies/${community?.companyId}/home`
    : `/communities/${communityId}/about`

  const postLink = getPostPath(community, { postId: post?.postId ?? '' }, post?.postType == GQLPostType.Content)

  const activityText = (
    <>
      {isComment ? 'commented' : postData?.post?.isRepost ? 'reposted' : 'posted'}
      &nbsp;
      <TimeAgo time={activityTime} />
    </>
  )

  const handleClickPostLink = (e: React.MouseEvent<HTMLElement>) => {
    elementClicked(e, 'click-profile-recent-post', {
      postId: postId,
    })
    navigate(postLink)
  }

  const outerDivRef = React.useRef<HTMLDivElement>(null)
  const CustomToggle = React.forwardRef(
    (props: { onClick: EventHandler<SyntheticEvent> }, ref: RefObject<HTMLAnchorElement>) => (
      <a
        className="activity-context-menu-control"
        href=""
        ref={ref}
        onClick={e => {
          // Propagate click so that other toggle menus can close
          outerDivRef?.current?.click()
          e.preventDefault()
          props.onClick(e)
        }}
      >
        &#8942;
      </a>
    )
  )

  const rowRef = useClickOnEnter<HTMLDivElement>()
  const commRef = useClickOnEnter<HTMLDivElement>()

  const {
    viewCount = 0,
    veevanViewCount = 0,
    likeCount = 0,
    veevanLikeCount = 0,
    commentCount = 0,
    veevanCommentCount = 0,
  } = (isComment ? commentData?.comment?.post : postData?.post) ?? {}

  const [showDeleteToast, setShowDeleteToast] = useState<boolean>(false)
  const expandRef = useRef<HTMLDivElement>(null)

  if (hidden && !actingSysAdmin)
    return (
      <ToastComponent show={showDeleteToast} onClose={() => setShowDeleteToast(false)} bg={'success'}>
        {'The post was deleted successfully.'}
      </ToastComponent>
    )

  if (loadingAuthors || (loadingCommunity && !communityData)) return null
  if ((commentLoading && !commentData) || (postLoading && !postData)) return null

  const communityInner = (
    <>
      <div
        className="profile-photo community-photo"
        style={community?.photo ? { backgroundImage: `url(${community?.photo})` } : {}}
      />
      <div className={`community-name ${isCondensedPortrait ? 'condensed' : ''}`} ref={commRef}>
        {community?.name}
      </div>
    </>
  )
  const displayLayout = (
    <>
      <div
        ref={outerDivRef}
        className={`activity-row${hidden ? ' hidden' : ''}${isCondensedPortrait ? ' condensed' : ''}`}
      >
        <div
          className={`activity-item ${isCondensedPortrait ? 'condensed' : ''}`}
          onClick={handleClickPostLink}
          role={'link'}
          ref={expandRef}
        >
          <div className={`activity-post-info ${isCondensedPortrait ? 'condensed' : 'not-condensed'}`}>
            <div className={`post-link ${isCondensedPortrait ? 'condensed' : ''}`} ref={rowRef}>
              <div className={`community-identity ${isCondensedPortrait ? 'condensed' : ''}`}>
                {isCondensedPortrait ? (
                  communityInner
                ) : (
                  <>
                    <Link
                      to={communityLink}
                      className={`community-link ${isCondensedPortrait ? 'condensed' : ''}`}
                      tabIndex={0}
                      onClick={e => {
                        elementClicked(e, 'click-profile-recent-community', {
                          postId: activityPostId,
                        })
                        e.stopPropagation()
                      }}
                    >
                      {communityInner}
                    </Link>
                    <div className={'mobile-link-spacer'} />
                  </>
                )}

                {!hidden && isCondensedPortrait && (
                  <div className={`activity-menu condensed`}>
                    <Counts
                      isComment={false}
                      likes={likeCount}
                      veevanLikes={veevanLikeCount ?? 0}
                      views={viewCount}
                      veevanViews={veevanViewCount ?? 0}
                      comments={commentCount}
                      veevanComments={veevanCommentCount}
                      isVeevan={isVeevan}
                    />
                  </div>
                )}
              </div>

              <div className={`activity-info ${isCondensedPortrait ? 'condensed' : ''}`} tabIndex={0}>
                <div className={`title-wrapper bookmark`}>
                  <div className={`post-title${post?.viewed ? ' read' : ' unread'}`}>
                    {isCondensedPortrait ? (
                      <MentionableText value={post?.title} postHeader={false} postId={postId} />
                    ) : (
                      <Link
                        to={postLink}
                        tabIndex={-1}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          elementClicked(e, 'click-profile-recent-post', {
                            postId: activityPostId,
                          })
                          e.stopPropagation()
                        }}
                      >
                        <MentionableText value={post?.title} postHeader={false} postId={postId} />
                      </Link>
                    )}
                  </div>
                </div>
                <div className={`activity-description profile-activity`}>
                  <div
                    className={'profile-photo'}
                    style={profilePhoto ? { backgroundImage: `url(${profilePhoto})` } : {}}
                  />
                  <div className={`activity-author${isCondensedPortrait ? ' condensed' : ''}`}>
                    <div>
                      {fullName} {activityText}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'activity-meta-info'}>
            <div className={'post-stats'}>
              <Counts
                isComment={false}
                likes={likeCount}
                veevanLikes={veevanLikeCount ?? 0}
                views={viewCount}
                veevanViews={veevanViewCount ?? 0}
                comments={commentCount}
                veevanComments={veevanCommentCount}
                isVeevan={isVeevan}
              />
            </div>
            <div className={`activity-time${post?.viewed ? ' read' : ' unread'}`}>
              <div>
                <TimeAgo time={new Date(post?.createdTime)} />
              </div>
            </div>
            <div className="activity-menu extra-spacing">
              <Dropdown align="end" onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle as={CustomToggle} />
                <PostDropdownItems
                  postId={activityPostId}
                  isCommPost={false}
                  isRecentActivity={true}
                  onClickEdit={() => setIsEditingActivity(true)}
                  showEditor={post?.postType == GQLPostType.Post}
                  fromContentPage={post?.postType == GQLPostType.Content}
                />
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  if (isEditingActivity)
    return (
      <>
        {isComment ? (
          <div className={'profile-edit-comment'}>
            <CommentEdit
              commentId={commentId ?? ''}
              onDone={() => setIsEditingActivity(false)}
              communityId={communityId ?? ''}
            />
          </div>
        ) : (
          <PostEdit postId={postId ?? ''} onDone={() => setIsEditingActivity(false)} />
        )}
      </>
    )
  else return displayLayout
}
