import React, { useEffect, useState } from 'react'
import { Footer } from '~/common/Footer'
import { AppHeader } from '~/common/AppHeader'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import '@css/pages/page/AddPage.scss'
import PlainTextInput from '~/common/PlainTextInput'
import { useNavigate, useParams } from 'react-router'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import AddPageCategoryRow from '~/pages/page/AddPageCategoryRow'
import { useAuth } from '~/auth/Auth'
import {
  GQLPageType,
  GQLHtml,
  useAddCategoryMutation,
  useAddReleaseMutation,
  useAddSubcategoryMutation,
  useEditCategoryMutation,
  useEditReleaseMutation,
  useGetMenuSectionsQuery,
  useEditSubcategoryMutation,
  useGetReleaseCategoriesQuery,
  useRemoveCategoryMutation,
  useRemoveSubcategoryMutation,
} from '~/api/generated/graphql'
import ToastComponent from '~/common/ToastComponent'
import { removeWhiteSpace } from '~/utils'
import { useSearchParams } from 'react-router-dom'
import QuillEditor, { QuillToolbar } from '~/common/quill/QuillEditor'
import { Maybe } from '@graphql-tools/utils'
import AddReleaseSubcategoryRow from '~/pages/page/AddReleaseSubcategoryRow'
import { useWindowSize } from '~/common/hooks/useWindowSize'

type MenuSection = {
  name: string
  sectionId: string
  releaseOrder: string[]
}

export type CategoryRow = {
  name: string
  categoryId: string
  index?: number
  postCount: number
}

export type SubcategoryRow = {
  name: string
  subcategoryId: string
}

type OriginalRelease = {
  releaseId: string
  title: string
  label: string
  menuOrder: number
  section: {
    sectionId: string
    name: string
    releaseOrder: string[]
    pageType: GQLPageType
  }
  status: boolean
  categoryOrder: string[]
  categoryRows: CategoryRow[]
  subcategoryRows: SubcategoryRow[]
  overview: Maybe<GQLHtml>
}

const AddMenuPageWrapper = ({ isEditingPage, pageType }: { isEditingPage: boolean; pageType: GQLPageType }) => {
  const { releaseId } = useParams()
  const navigate = useNavigate()
  const { data: categoriesData, loading } = useGetReleaseCategoriesQuery({
    variables: { releaseId: releaseId ?? '' },
    skip: !releaseId,
  })

  const categoriesMap = new Map()

  categoriesData?.release?.categories?.edges?.map(category => {
    categoriesMap.set(category?.node?.categoryId, {
      name: category?.node?.name,
      categoryId: category?.node?.categoryId,
      postCount: category?.node?.postCount,
    } as CategoryRow)
  })

  const orderedCategories: CategoryRow[] = []

  const orderedIds = (JSON.parse(categoriesData?.release?.categoryOrder ?? null) as string[]) || []

  for (let i = 0; i < orderedIds.length; i++) {
    orderedCategories.push({ ...categoriesMap.get(orderedIds[i]), index: i })
  }

  const unOrderedCategories = Array.from(categoriesMap.values())

  for (let i = 0; i < unOrderedCategories.length; i++) {
    if (orderedIds.indexOf(unOrderedCategories[i].categoryId) == -1) {
      orderedCategories.push({ ...unOrderedCategories[i], index: i })
    }
  }

  const menuOrder =
    ((JSON.parse(categoriesData?.release?.section?.releaseOrder ?? null) as string[]) ?? []).indexOf(releaseId ?? '') +
    1

  const defaultPageValues = {
    releaseId: releaseId ?? '',
    title: categoriesData?.release?.title ?? '',
    label: categoriesData?.release?.name ?? '',
    menuOrder: menuOrder,
    section: {
      sectionId: categoriesData?.release?.section?.sectionId ?? '',
      name: categoriesData?.release?.section?.name ?? '',
      releaseOrder: (JSON.parse(categoriesData?.release?.section?.releaseOrder ?? null) as string[]) ?? [],
      pageType: categoriesData?.release?.section?.pageType ?? GQLPageType.Release,
    },
    status: categoriesData?.release?.draft == undefined ? true : categoriesData?.release?.draft,
    categoryOrder: (JSON.parse(categoriesData?.release?.categoryOrder ?? null) as string[]) || [],
    categoryRows: orderedCategories,
    subcategoryRows: (categoriesData?.release?.subcategories?.edges.map(s => s?.node).filter(Boolean) ??
      []) as SubcategoryRow[],
    overview: categoriesData?.release?.overview,
  }

  if (releaseId && !categoriesData && loading) {
    return <div className={'loading'}>Loading...</div>
  }

  if (releaseId && pageType != defaultPageValues.section.pageType) {
    navigate(`/${defaultPageValues.section.pageType === GQLPageType.Summit ? 'summits' : 'releases'}/${releaseId}/edit`)
  }

  return (
    <div key={releaseId ?? pageType == GQLPageType.Release ? 'release-add' : 'summit-add'}>
      <AddPage isEditingPage={isEditingPage} defaultPageValues={defaultPageValues} pageType={pageType} />
    </div>
  )
}

const AddPage = ({
  isEditingPage,
  defaultPageValues,
  pageType,
}: {
  isEditingPage: boolean
  defaultPageValues: OriginalRelease
  pageType: GQLPageType
}) => {
  const { actingSysAdmin, loading: permissionsLoading } = useAuth()
  const { isCondensed } = useWindowSize()

  const [menuLabel, setMenuLabel] = useState(defaultPageValues.label)
  const [pageTitle, setPageTitle] = useState(defaultPageValues.title)
  // order starting at 1
  const [menuOrder, setMenuOrder] = useState<number | undefined>(
    defaultPageValues.menuOrder > 0 ? defaultPageValues.menuOrder : undefined
  )
  const [menuSection, setMenuSection] = useState<MenuSection>(defaultPageValues.section)
  const [isDraft, setIsDraft] = useState(defaultPageValues.status)
  const [categories, setCategories] = useState<CategoryRow[]>(defaultPageValues.categoryRows)
  const [subcategories, setSubcategories] = useState<SubcategoryRow[]>(defaultPageValues.subcategoryRows)
  const [overview, setOverview] = useState<Maybe<GQLHtml>>(defaultPageValues.overview)

  const [addingRow, setAddingRow] = useState(false)
  const [addingSubcategoryRow, setAddingSubcategoryRow] = useState(false)
  const [editing, setEditing] = useState(!isEditingPage)
  const [showModal, setShowModal] = useState<string | undefined>()
  const releaseId = defaultPageValues.releaseId

  const [showToast, setShowToast] = useState(false)

  const isNewPage = !isEditingPage && !releaseId
  const pageTypeName = pageType == GQLPageType.Release ? 'Release' : 'Summit'
  const pageTypeNav = pageType == GQLPageType.Release ? 'releases' : 'summits'

  const navigate = useNavigate()

  const [addCategory] = useAddCategoryMutation()
  const [editCategory] = useEditCategoryMutation()
  const [removeCategory] = useRemoveCategoryMutation()
  const [addRelease] = useAddReleaseMutation()
  const [editRelease] = useEditReleaseMutation()
  const [addSubcategory] = useAddSubcategoryMutation()
  const [editSubcategory] = useEditSubcategoryMutation()
  const [removeSubcategory] = useRemoveSubcategoryMutation()

  const { data: sectionsData } = useGetMenuSectionsQuery({ variables: { pageType: pageType } })
  const sections =
    sectionsData?.sections?.edges
      ?.filter(e => e?.node?.visible)
      .map(
        section =>
          ({
            name: section?.node?.name,
            sectionId: section?.node?.sectionId,
            releaseOrder: (JSON.parse(section?.node?.releaseOrder ?? null) as string[]) ?? [],
          }) as MenuSection
      ) ?? []

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const originalIndex = result.source.index
    const newIndex = result.destination.index

    const categoriesCopy = [...categories]
    const [removed] = categoriesCopy.splice(originalIndex, 1)
    categoriesCopy.splice(newIndex, 0, removed)
    setCategories(categoriesCopy)

    if (releaseId) {
      const categoryIds = categoriesCopy.map(category => category.categoryId)
      await editRelease({
        variables: {
          releaseId: releaseId,
          categoryOrder: categoryIds,
        },
      })
    }
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.blur() // prevents the buttons on the non-edit view to be focused/highlighted
    if (!releaseId) {
      navigate('/')
    } else {
      setEditing(false)
      setPageTitle(defaultPageValues.title)
      setMenuLabel(defaultPageValues.label)
      setMenuOrder(defaultPageValues.menuOrder > 0 ? defaultPageValues.menuOrder : undefined)
      setMenuSection(defaultPageValues.section)
      setIsDraft(defaultPageValues.status)
    }
  }

  const titleChanged = pageTitle != defaultPageValues.title
  const sectionChanged = menuSection.sectionId != defaultPageValues.section.sectionId
  const menuOrderChanged = menuOrder != defaultPageValues.menuOrder
  const labelChanged = menuLabel != defaultPageValues.label
  const hasMenuOrder = !!menuOrder || menuOrder == 0
  const enableSave = pageTitle && menuLabel && menuSection.sectionId && hasMenuOrder && categories.length > 0

  const handleFormSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.blur() // prevents the buttons on the non-edit view to be focused/highlighted
    if (!(menuSection && menuLabel && hasMenuOrder && pageTitle && categories.length > 0)) {
      setShowModal('Must fill in all required fields.')
      if (categories.length < 1) {
        setShowModal('At least one category must be added.')
      }
    } else {
      if (releaseId) {
        const releaseOrder = menuSection?.releaseOrder ?? []

        if (releaseOrder.includes(releaseId)) {
          releaseOrder.splice(defaultPageValues.menuOrder - 1, 1)
        }
        releaseOrder.splice(Math.max(1, menuOrder) - 1, 0, releaseId)

        const categoryIds = categories.map(category => category.categoryId)

        const resp = await editRelease({
          variables: {
            releaseId: releaseId,
            title: titleChanged ? pageTitle : '',
            name: labelChanged ? removeWhiteSpace(menuLabel) : '',
            categoryOrder: categoryIds,
            draft: isDraft,
            sectionId: sectionChanged ? menuSection?.sectionId : '',
            releaseOrder: sectionChanged || menuOrderChanged ? releaseOrder : null,
            overview: overview?.html,
          },
        })

        if (resp.data?.editRelease?.ok) {
          setEditing(false)
          setShowToast(true)
          setMenuOrder(
            ((JSON.parse(resp?.data?.editRelease?.release?.section?.releaseOrder ?? null) as string[]) ?? []).indexOf(
              releaseId ?? ''
            ) + 1
          )
        } else {
          setShowModal(resp.data?.editRelease?.error?.message ?? 'Cannot save changes at this time.')
          return
        }
      } else {
        const resp = await addRelease({
          variables: {
            name: removeWhiteSpace(menuLabel),
            sectionId: menuSection.sectionId,
            title: pageTitle,
            categoryNames: categories.map(category => category.name),
            draft: isDraft,
            index: Math.max(1, menuOrder) - 1,
            subcategoryNames: subcategories.map(s => s.name),
            overview: overview?.html,
          },
        })

        if (resp.data?.addRelease?.ok) {
          if (resp.data?.addRelease?.release?.releaseId) {
            navigate(`/${pageTypeNav}/${resp.data?.addRelease?.release?.releaseId ?? ''}/edit?success=1`)
          }
        } else {
          setShowModal(resp.data?.addRelease?.error?.message ?? 'There was an error with adding this page.')
          return
        }
      }
    }
  }

  const addSubcategoryRow = async (subcategory: SubcategoryRow) => {
    if (isNewPage) {
      setSubcategories([...subcategories, subcategory])
      return true
    } else {
      const trimmedName = removeWhiteSpace(subcategory.name)
      const resp = await addSubcategory({ variables: { name: trimmedName, releaseId } })
      if (resp?.data?.addSubcategory?.ok) {
        setSubcategories([
          ...subcategories,
          { ...subcategory, subcategoryId: resp?.data?.addSubcategory?.subcategory?.subcategoryId ?? '' },
        ])
        return true
      } else {
        setShowModal(resp?.data?.addSubcategory?.error?.message ?? 'Could not add subcategory at this time.')
        return false
      }
    }
  }

  const saveSubcategoryRow = async (subcategory: SubcategoryRow) => {
    const trimmedName = removeWhiteSpace(subcategory.name)
    const index = subcategories.findIndex(s => s.subcategoryId === subcategory.subcategoryId)
    if (isNewPage && index !== -1) {
      const subcategoriesCopy = [...subcategories]
      subcategoriesCopy.splice(index, 1, subcategory)
      setSubcategories(subcategoriesCopy)
      return true
    } else if (releaseId) {
      if (index !== -1) {
        const existingSubcat = subcategories.at(index)
        // if name is not actually changing just return
        if (existingSubcat?.name === trimmedName) {
          return true
        }
      }
      const resp = await editSubcategory({ variables: { id: subcategory.subcategoryId, name: trimmedName } })
      if (!resp?.data?.editSubcategory?.ok) {
        setShowModal(resp?.data?.editSubcategory?.error?.message ?? 'Could not add subcategory at this time.')
        return false
      }
      return true
    }
  }

  const deleteSubcategoryRow = async (subcategory: SubcategoryRow) => {
    const index = subcategories.findIndex(s => s.subcategoryId === subcategory.subcategoryId)
    if (isNewPage && index !== -1) {
      const subcategoriesCopy = [...subcategories]
      subcategoriesCopy.splice(index, 1)
      setSubcategories(subcategoriesCopy)
      return true
    } else if (releaseId) {
      const resp = await removeSubcategory({ variables: { id: subcategory.subcategoryId } })
      if (resp?.data?.removeSubcategory?.ok) {
        setSubcategories(subcategories => {
          const index = subcategories.findIndex(s => s.subcategoryId === subcategory.subcategoryId)
          subcategories.splice(index, 1)
          return subcategories.slice()
        })
        return true
      } else {
        setShowModal(resp?.data?.removeSubcategory?.error?.message ?? 'Cold not remove subcategory at this time.')
        return false
      }
    }
  }

  const addRow = async (category: CategoryRow) => {
    const categoryNames = categories.map(c => c.name)
    const trimmedName = removeWhiteSpace(category.name)
    if (categoryNames.indexOf(category.name) == -1) {
      if (isNewPage && category.index != null) {
        setCategories([...categories, category])
        return true
      } else if (releaseId) {
        const resp = await addCategory({ variables: { name: trimmedName, releaseId: releaseId } })
        if (resp?.data?.addCategory?.ok) {
          setCategories([
            ...categories,
            { ...category, categoryId: resp?.data?.addCategory?.category?.categoryId ?? '' },
          ])
          return true
        } else {
          setShowModal(resp?.data?.addCategory?.error?.message ?? 'Could not add category at this time.')
          return false
        }
      }
    } else {
      setShowModal(`Another category with name ${trimmedName} exists`)
      return false
    }
  }

  const saveRow = async (category: CategoryRow) => {
    const categoryNames = categories.map(c => c.name)
    const trimmedName = removeWhiteSpace(category.name)
    if (categoryNames.indexOf(trimmedName) == -1) {
      if (isNewPage && category.index != null) {
        const categoriesCopy = [...categories]
        categoriesCopy.splice(category.index, 1, category)
        setCategories(categoriesCopy)
        return true
      } else if (releaseId) {
        const resp = await editCategory({ variables: { categoryId: category.categoryId, name: trimmedName } })
        if (!resp?.data?.editCategory?.ok) {
          setShowModal(resp?.data?.editCategory?.error?.message ?? 'Could not add category at this time.')
          return false
        }
        return true
      }
    } else {
      setShowModal(`Another category with name ${trimmedName} exists`)
      return false
    }
  }

  const deleteRow = async (category: CategoryRow) => {
    if (categories.length > 1) {
      if (isNewPage && category.index != null) {
        if (category.postCount) {
          setShowModal('This category still has posts associated with it. Are you sure you want to delete it?')
        }
        const categoriesCopy = [...categories]
        categoriesCopy.splice(category.index, 1)
        setCategories(categoriesCopy)
        return true
      } else if (releaseId) {
        const resp = await removeCategory({ variables: { categoryId: category.categoryId } })
        if (resp.data?.removeCategory?.ok) {
          setCategories(categories => {
            const index = categories.findIndex(c => c.categoryId === category.categoryId)
            categories.splice(index, 1)
            return categories.slice()
          })
          return true
        } else {
          setShowModal(resp?.data?.removeCategory?.error?.message ?? 'Cannot delete this category at this time.')
          return false
        }
      }
    } else {
      setShowModal('Must have at least 1 category.')
      return false
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get('success') === '1') {
      setShowToast(true)
      setSearchParams({})
    }
  }, [searchParams, setSearchParams])

  if (!actingSysAdmin && !permissionsLoading) {
    navigate('/')
    return <></>
  }

  return (
    <div>
      <AppHeader />
      <div className={`add-release-wrapper ${isCondensed ? 'condensed' : ''}`}>
        <div className={'add-release-container'}>
          <div className={'add-release-header'}>
            <h3 className="comm-title">{!isEditingPage || editing ? `Add ${pageTypeName} Page` : pageTitle}</h3>
            <div className={'buttons-container'}>
              {editing ? (
                <>
                  <Button variant="light" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleFormSave} disabled={!enableSave}>
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={'edit-details-button'}
                    tabIndex={0}
                    onClick={e => {
                      setEditing(true)
                      e.currentTarget.blur() // prevents the buttons on the edit view to be focused/highlighted
                    }}
                  >
                    Edit Page Details
                  </Button>
                  <Button
                    variant={'primary'}
                    onClick={e => {
                      navigate(`/${pageTypeNav}/${releaseId}`)
                      e.currentTarget.blur() // prevents the buttons on the edit view to be focused/highlighted
                    }}
                  >
                    Done
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={'release-options'}>
            {editing ? (
              <>
                <div className={'top-options'}>
                  <div className={'release-info-input menu-section'}>
                    <label>{pageTypeName} Menu Section*</label>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-section-options"
                        data-testid="dropdown-section-options"
                        role="section-dropdown"
                        className={menuSection.name ? '' : 'placeholder-toggle'}
                      >
                        <span>{menuSection.name ? menuSection.name : 'Select Menu'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {sections.map(section => (
                          <Dropdown.Item key={section.sectionId} onClick={() => setMenuSection(section)}>
                            {section.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className={'release-info-input'}>
                    <label>Menu Label*</label>
                    <PlainTextInput
                      placeholder={'Add a Menu Label'}
                      onChange={s => {
                        setMenuLabel(s.slice(0, 200))
                      }}
                      value={menuLabel}
                      className={'menu-label-input'}
                    />
                  </div>
                  <div className={'release-info-input menu-order'}>
                    <label>Menu Order*</label>
                    <input
                      type={'number'}
                      placeholder={'Add a Menu Order'}
                      onChange={e => setMenuOrder(e.target.value ? +e.target.value : undefined)}
                      defaultValue={menuOrder}
                      min={1}
                      data-testid={'menu-order-input'}
                    />
                  </div>
                </div>
                <div className={'bottom-options'}>
                  <div className={'release-info-input page-title'}>
                    <label>Page Title*</label>
                    <PlainTextInput
                      placeholder={'Add a Page Title'}
                      onChange={s => {
                        setPageTitle(s.slice(0, 200))
                      }}
                      value={pageTitle}
                      className={'page-title-input'}
                    />
                  </div>
                  <div className={'release-info-input'}>
                    <label>Status*</label>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-release-type"
                        role="release-status-dropdown"
                        data-testid={'status-dropdown'}
                      >
                        <span>{isDraft ? 'Draft' : 'Active'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setIsDraft(true)}>Draft</Dropdown.Item>
                        <Dropdown.Item onClick={() => setIsDraft(false)}>Active</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className={'overview-container'}>
                  <label className={'overview-label'}>Page SubTitle</label>
                  <QuillEditor<Maybe<GQLHtml>>
                    initialHtml={overview}
                    setHtml={setOverview}
                    toolbar={QuillToolbar.Overview}
                    placeholder={'Add an Overview'}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={'top-options'}>
                  <div className={'release-info-input'}>
                    <label>{pageTypeName} Menu Section*</label>
                    <p>{menuSection.name}</p>
                  </div>
                  <div className={'release-info-input'}>
                    <label>Menu Label*</label>
                    <p>{menuLabel}</p>
                  </div>
                  <div className={'release-info-input menu-order'}>
                    <label>Menu Order*</label>
                    <p>{menuOrder}</p>
                  </div>
                </div>
                <div className={'bottom-options'}>
                  <div className={'release-info-input page-title'}>
                    <label>Page Title*</label>
                    <p>{pageTitle}</p>
                  </div>
                  <div className={'release-info-input'}>
                    <label>Status*</label>
                    <p>{isDraft ? 'Draft' : 'Active'}</p>
                  </div>
                </div>
                <div className={'overview-container'}>
                  <label className={'overview-label'}>Page SubTitle</label>
                  <div className={'quill-editor-elements'} dangerouslySetInnerHTML={{ __html: overview?.html ?? '' }} />
                </div>
              </>
            )}
          </div>
          <div className={'release-categories'}>
            <div className={'categories-header'}>
              <div>
                <h4>Categories*</h4>
                <p>
                  The category will be used to attach {pageTypeName.toLowerCase()} content posts in the{' '}
                  {pageTypeName.toLowerCase()} page.
                </p>
              </div>
              <Button
                className={`${isCondensed ? 'condensed' : ''}`}
                variant="primary"
                onClick={() => setAddingRow(true)}
              >
                Add Category
              </Button>
            </div>
            <div className={'categories-container'}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'droppable'}>
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {categories.map((category, index) => {
                        return category ? (
                          <Draggable
                            draggableId={`category_row_${category.index}`}
                            index={index}
                            key={`category_row_${category.index}`}
                          >
                            {provided => (
                              <AddPageCategoryRow
                                category={category}
                                draggableInnerRef={provided.innerRef}
                                draggableProps={provided.draggableProps}
                                dragHandleProps={provided.dragHandleProps}
                                onSave={saveRow}
                                onDelete={deleteRow}
                              />
                            )}
                          </Draggable>
                        ) : (
                          <></>
                        )
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {addingRow && (
                <AddPageCategoryRow
                  category={{ categoryId: '', name: '', index: categories.length, postCount: 0 }}
                  addingRow={true}
                  setAddingRow={setAddingRow}
                  onSave={addRow}
                />
              )}
            </div>
          </div>
          <div className={'release-categories subcategories'}>
            <div className={'categories-header'}>
              <div>
                <h4>Subcategories</h4>
                <p>Subcategories will be used to group similar posts within a category section on the release page.</p>
              </div>
              <Button
                className={`subcategory-button ${isCondensed ? 'condensed' : ''}`}
                variant="primary"
                onClick={() => setAddingSubcategoryRow(true)}
              >
                Add Subcategory
              </Button>
            </div>
            <div className={'categories-container'}>
              {subcategories.map(subcategory => (
                <AddReleaseSubcategoryRow
                  key={subcategory.subcategoryId}
                  subcategory={subcategory}
                  onSave={saveSubcategoryRow}
                  onDelete={deleteSubcategoryRow}
                />
              ))}
              {addingSubcategoryRow && (
                <AddReleaseSubcategoryRow
                  subcategory={{ subcategoryId: '', name: '' }}
                  addingRow={true}
                  setAddingRow={setAddingSubcategoryRow}
                  onSave={addSubcategoryRow}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={!!showModal} onHide={() => setShowModal(undefined)} className={''}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p className={'message'}>{showModal}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowModal(undefined)}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" onClick={() => setShowModal(undefined)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
      <ToastComponent onClose={() => setShowToast(false)} show={showToast}>
        Successfully saved changes.
      </ToastComponent>
    </div>
  )
}

export default AddMenuPageWrapper
