import { Dropdown, Spinner } from 'react-bootstrap'
import { CustomToggle } from '~/common/CustomToggle'
import React, { useEffect, useRef, useState } from 'react'
import { ModalCropper } from '~/common/ModalCropper'
import { getCroppedUploadHandler } from '~/utils'
import { GQLCreateMediaMutation, useCreateMediaMutation } from '~/api/generated/graphql'
import '@css/pages/community/CommunityPhotoEdit.scss'
import { useClickOnEnter } from '~/common/hooks/useClickOnEnter'

type CommunityPhotoEditProps = {
  existingPhoto?: string
  communityName?: string
  setUploadId: (uploadId?: string) => void
  setRemovePhoto: (removePhoto: boolean) => void
  disabled?: boolean
  uploading: boolean
  setUploading: (b: boolean) => void
}

const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG', 'JFIF']

const CommunityPhotoEdit = ({
  disabled,
  existingPhoto,
  communityName,
  setUploadId,
  setRemovePhoto,
  uploading,
  setUploading,
}: CommunityPhotoEditProps) => {
  const [mediaUrl, setMediaUrl] = useState<string | undefined | null>(existingPhoto)
  const [addedPhoto, setAddedPhoto] = useState<boolean>(!!existingPhoto)
  const [showPhotoEdit, setShowPhotoEdit] = useState<boolean>(false)
  const fileUploaderRef = useRef<HTMLInputElement>(null)
  const [cropImage, setCropImage] = useState<File | null>()
  const [createMediaResponse, setCreateMediaResponse] = useState<GQLCreateMediaMutation>()
  const [createMedia] = useCreateMediaMutation()
  const handleFileFileCrop = getCroppedUploadHandler(setUploading, createMedia, setCreateMediaResponse, setMediaUrl)

  const editImageRef = useClickOnEnter<HTMLImageElement>()

  useEffect(() => {
    setMediaUrl(existingPhoto)
    setAddedPhoto(!!existingPhoto)
  }, [existingPhoto])

  useEffect(() => {
    setUploadId(createMediaResponse?.createMedia?.uploadedFile?.id)
  }, [createMediaResponse, setUploadId])

  const clickUploadPhoto = (ev: { stopPropagation: () => void }) => {
    ev.stopPropagation()
    fileUploaderRef.current?.click()
  }

  const clickRemovePhoto = () => {
    setRemovePhoto(true)
    setCreateMediaResponse(undefined)
    setMediaUrl('')
    setAddedPhoto(false)
  }

  const onChangeFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files) {
      const e = ev.target.files[0]
      if (e) {
        setAddedPhoto(true)
        setCropImage(e)
        setShowPhotoEdit(true)
      }
      ev.target.value = ''
    }
  }

  return (
    <div className={'photo-edit'}>
      {showPhotoEdit && !!cropImage && (
        <ModalCropper
          file={cropImage}
          handleBlobUpload={(blob, contentType, fileName) => {
            handleFileFileCrop(blob, contentType, fileName)
            setRemovePhoto(false)
          }}
          onClose={() => {
            setCropImage(null)
            setShowPhotoEdit(false)
          }}
          isCommunity={true}
        />
      )}
      <Dropdown className={'profile-photo'}>
        <Dropdown.Toggle as={CustomToggle} id={'profile-edit-photo'}>
          {uploading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div
              className={'photo'}
              style={{ backgroundImage: `url(${mediaUrl})`, backgroundSize: 'cover', backgroundColor: '#f5f5f5' }}
              title={communityName}
            >
              <div className={'edit-photo'} title={'Edit Photo'} role={'button'} tabIndex={0} ref={editImageRef} />
            </div>
          )}
        </Dropdown.Toggle>

        {!disabled && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={clickUploadPhoto}>Upload Photo</Dropdown.Item>
            {addedPhoto && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={clickRemovePhoto}>Remove Photo</Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
      <input
        type={'file'}
        name={'file'}
        ref={fileUploaderRef}
        onChange={onChangeFile}
        accept={fileTypes.map(t => `.${t.toLowerCase()}`).join(',')}
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default CommunityPhotoEdit
