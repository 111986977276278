import { relayStylePagination } from '@apollo/client/utilities'
import { getNormalizedTokens, updatePermissions } from '~/utils'
import { GetPublicCommentsDocument, GetVeevaDiscussionDocument } from '~/api/generated/graphql'

const prefixTokenize = (s: string) => {
  const words = getNormalizedTokens(s)

  // return array of all prefix sequences in each word
  // for example, if the string is 'min max', this will return
  // ['m', 'mi', 'min', 'm', 'ma', 'max']
  return ([] as string[]).concat(
    ...words.map(w => {
      return w.split('').map((l, idx) => w.substring(0, idx) + l)
    })
  )
}
window.prefixCache = new Map<string, Set<string>>()

// update the search cache when values for fields change
const updateSearchIndex = (oldString: string, newString: string, newId: string) => {
  if (oldString !== newString) {
    const prefixCache = window.prefixCache
    const oldKeysList = prefixTokenize(oldString)
    const oldKeys = new Set(oldKeysList)
    const newKeysList = prefixTokenize(newString)
    const newKeys = new Set(newKeysList)
    // This does the set difference, so it removes the tokens that were in the old set but aren't in the
    // new set and adds the tokens that are in the new set but not in the old set while leaving
    // the tokens that haven't changed alone
    const toRemove = oldKeysList.filter(x => !newKeys.has(x))
    const toAdd = newKeysList.filter(x => !oldKeys.has(x))
    toRemove.forEach(x => {
      prefixCache?.get(x)?.delete(newId)
    })
    toAdd.forEach(x => {
      if (!prefixCache?.has(x)) {
        prefixCache?.set(x, new Set([newId]))
      } else if (!prefixCache.get(x)?.has(newId)) {
        prefixCache.get(x)?.add(newId)
      }
    })
  }
}

// get old and new values for fields that are being searched on for updating the search cache
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeIndexStrings(existing: any, incoming: any, fields: string[], opts: any) {
  const oldFields: string[] = []
  const newFields: string[] = []
  const oldObj = existing ? (opts.isReference(existing) ? opts.cache.data.data[existing['__ref']] : existing) : existing
  const newObj = incoming ? (opts.isReference(incoming) ? opts.cache.data.data[incoming['__ref']] : incoming) : incoming

  // For homepages, include the company name in the index strings. This technically will not remove old
  // company names from the index if the name is modified, but given how rare that is and that it is
  // solved by a refresh, that should not be a problem
  if (newObj && newObj['__typename'] === 'Community' && newObj['companyId']) {
    const company =
      opts.cache.data.data[opts.toReference({ __typename: 'Company', companyId: newObj['companyId'] })['__ref']]
    if (company && company.name) newFields.push(company.name)
  }
  if (newObj && !(opts.isReference(incoming) && opts.isReference(existing))) {
    const incomingKeys = Object.keys(newObj)
    const existingKeys = oldObj ? Object.keys(oldObj) : []
    fields.forEach(f => {
      if (incomingKeys.includes(f)) {
        if (existingKeys.includes(f)) {
          if (f === 'aliases') {
            oldObj[f].forEach((a: string) => oldFields.push(a))
          } else oldFields.push(oldObj[f])
        }
        if (f === 'aliases') {
          newObj[f].forEach((a: string) => newFields.push(a))
        } else newFields.push(newObj[f])
      }
    })
  }

  return [oldFields.join(' '), newFields.join(' ')]
}

const connectionFieldPagination = (keyArgs: string[]) => ({
  keyArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge: function (existing: any, incoming: any, opts: any) {
    const mergedObj = opts.mergeObjects(existing, incoming)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedEdges: any[] = []
    const edgesMap = new Map<string, number>()
    const oldEdges = existing ? opts.readField('edges', existing) ?? [] : []
    const newEdges = incoming ? opts.readField('edges', incoming) ?? [] : []

    // If 'after' arg doesn't exist, can use new list entirely rather than merging
    if (!opts.args?.after) {
      if (incoming && opts.readField('edges', incoming)) {
        return { ...mergedObj, edges: newEdges }
      } else if (existing && opts.readField('edges', existing)) {
        return { ...mergedObj, edges: oldEdges }
      }
      return { ...mergedObj }
    } else {
      if (oldEdges?.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        oldEdges.forEach((e: any, index: number) => {
          mergedEdges.push(e)
          const node = opts.readField('node', e)
          edgesMap.set(node.__ref, index)
        })
      }
      if (newEdges?.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newEdges.forEach((e: any) => {
          const node = opts.readField('node', e)
          const index = edgesMap.get(node.__ref)
          if (typeof index === 'undefined') {
            mergedEdges.push(e)
          } else {
            mergedEdges[index] = opts.mergeObjects(mergedEdges[index], e)
          }
        })
      }
    }

    return { ...mergedObj, edges: mergedEdges }
  },
})

const searchMatchPagination = (keyArgs: string[], fieldName = 'matches') => ({
  keyArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge: function (existing: any, incoming: any, opts: any) {
    const mergedObj = opts.mergeObjects(existing, incoming)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const oldMatches = existing ? opts.readField(fieldName, existing) ?? [] : []
    const newMatches = incoming ? opts.readField(fieldName, incoming) ?? [] : []

    // If 'after' arg does not exist, can use new list entirely rather than merging
    const mergedMatches = opts.args?.after ? [...oldMatches, ...newMatches] : newMatches

    return { ...mergedObj, [fieldName]: mergedMatches }
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformRefsToData = (cacheObj: any, opts: any) => {
  if (cacheObj && cacheObj['__ref']) {
    const dataObj = opts.cache.data.data[cacheObj['__ref']]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.keys(dataObj).reduce((prev: Record<string, any>, key) => {
      let value
      // Originally this was written as a recursive function but that caused a stack overflow
      // since posts reference comments and comments also reference the post. Just resolving refs
      // at the top level of the object appears to be sufficient
      if (dataObj[key] && dataObj[key]['__ref']) {
        value = opts.cache.data.data[dataObj[key]['__ref']]
      } else {
        value = dataObj[key]
      }
      prev[key] = value
      return prev
    }, {})
  } else {
    return cacheObj
  }
}

// Note: you must merge fields for the full object merge to have an "existing" object set.
export const typePolicies = {
  Query: {
    fields: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      community(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Community',
          communityId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      company(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Company',
          companyId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Event',
          eventId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      user(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'User',
          userId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      membership(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Membership',
          communityId: args.communityId,
          userId: args.userId,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      comment(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Comment',
          commentId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      post(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Post',
          postId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      role(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Role',
          companyId: args.companyId,
          userId: args.userId,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      category(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Category',
          categoryId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      subcategory(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Subcategory',
          subcategoryId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      release(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Release',
          releaseId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      section(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Section',
          sectionId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      hostname(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Hostname',
          hostnameId: args.id,
        })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      meetup(_: any, { args, toReference }: any) {
        return toReference({
          __typename: 'Meetup',
          meetupId: args.id,
        })
      },
      communities: relayStylePagination(['filters', 'sort']),
      users: relayStylePagination(['filters', 'sort']),
      notifications: relayStylePagination(['filters', 'sort']),
      companies: relayStylePagination(['filters', 'sort']),
      posts: connectionFieldPagination(['filters', 'sort']),
      comments: relayStylePagination(['filters', 'sort']),
      members: relayStylePagination(['filters', 'sort']),
      roles: relayStylePagination(['filters', 'sort']),
      invitations: relayStylePagination(['filters', 'sort']),
      history: relayStylePagination(['filters', 'sort']),
      hostnames: relayStylePagination(['filters', 'sort']),
      recentContents: relayStylePagination(),
      customerActivityCommunities: relayStylePagination(['companyId']),
      customerMembershipCommunities: relayStylePagination(['companyId']),
      advancedSearch: searchMatchPagination([
        'query',
        'allMatch',
        'tab',
        'communities',
        'authors',
        'companies',
        'time',
        'afterDate',
        'beforeDate',
        'includeDiscussions',
      ]),
      communityMembers: searchMatchPagination(
        ['communityId', 'searchQuery', 'sortType', 'sortDirection', 'isVeevan'],
        'members'
      ),
    },
  },
  Role: {
    keyFields: ['companyId', 'userId'],
  },
  History: {
    keyFields: ['historyId'],
  },
  User: {
    keyFields: ['userId'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: function (existing: any, incoming: any, opts: any) {
      const firstName = 'firstName'
      const lastName = 'lastName'
      const nickName = 'nickName'

      const newId = `User:${opts.readField('userId', incoming)}`
      const [oldString, newString] = makeIndexStrings(existing, incoming, [firstName, lastName, nickName], opts)
      updateSearchIndex(oldString, newString, newId)
      return opts.mergeObjects(existing, incoming)
    },
    fields: {
      firstName: { merge: true },
      lastName: { merge: true },
      nickName: { merge: true },
      communities: relayStylePagination(['filters', 'sort']),
      roles: relayStylePagination(['filters', 'sort']),
      memberships: relayStylePagination(['filters', 'sort']),
      homepages: relayStylePagination(['filters', 'sort']),
    },
  },
  Community: {
    keyFields: ['communityId'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: function (existing: any, incoming: any, opts: any) {
      const fields = ['name', 'aliases']
      const newId = `Community:${opts.readField('communityId', incoming)}`

      const [oldString, newString] = makeIndexStrings(existing, incoming, fields, opts)
      updatePermissions(opts.cache, incoming)
      updateSearchIndex(oldString, newString, newId)
      return opts.mergeObjects(existing, incoming)
    },
    fields: {
      name: { merge: true },
      comments: relayStylePagination(['filters', 'sort']),
      posts: connectionFieldPagination(['filters', 'sort']),
      postLikes: relayStylePagination(['filters', 'sort']),
      leaders: relayStylePagination(['filters', 'sort']),
      events: relayStylePagination(['filters', 'sort', 'eventEndGte']),
      users: relayStylePagination(['filters', 'sort']),
      invitations: relayStylePagination(['filters', 'sort']),
      members: connectionFieldPagination(['filters', 'sort']),
      sections: relayStylePagination(['filters', 'sort']),
      categories: relayStylePagination(['filters', 'sort']),
      releases: relayStylePagination(['filters', 'sort']),
    },
  },
  Event: {
    keyFields: ['eventId'],
  },
  Invitation: {
    keyFields: ['inviterId', 'inviteeId', 'communityId'],
  },
  Comment: {
    keyFields: ['commentId'],
    fields: {
      likes: relayStylePagination(['filters', 'sort']),
    },
    merge: true,
  },
  Company: {
    keyFields: ['companyId'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: function (existing: any, incoming: any, opts: any) {
      const name = 'name'
      const newId = `Company:${opts.readField('companyId', incoming)}`

      const [oldString, newString] = makeIndexStrings(existing, incoming, [name], opts)
      updateSearchIndex(oldString, newString, newId)
      return opts.mergeObjects(existing, incoming)
    },
    fields: {
      name: { merge: true },
    },
  },
  Notification: {
    keyFields: ['notificationId'],
    merge: true,
  },
  Membership: {
    keyFields: ['userId', 'communityId'],
  },
  CommentLike: {
    keyFields: ['userId', 'commentId'],
  },
  Meetup: {
    keyFields: ['meetupId'],
  },
  PostLike: {
    keyFields: ['userId', 'postId'],
  },
  Post: {
    keyFields: ['postId'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: function (existing: any, incoming: any, opts: any) {
      const isRepost = opts.readField('isRepost', incoming)
      if (!isRepost) {
        const fields = ['communityName', 'contentTitle']
        const newId = `Post:${opts.readField('postId', incoming)}`

        const [oldString, newString] = makeIndexStrings(existing, incoming, fields, opts)

        updateSearchIndex(oldString, newString, newId)
      }

      return opts.mergeObjects(existing, incoming)
    },
    fields: {
      comments: relayStylePagination(['filters', 'sort']),
      likes: relayStylePagination(['filters', 'sort']),
      contentTitle: { merge: true },
      discussionComments: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        merge: function (existing: any, incoming: any, opts: any) {
          if (incoming && opts.cache.data.data[incoming['__ref']]) {
            const postId = opts.readField('id', incoming).split(':')[1]
            opts.cache.writeQuery({
              query: GetVeevaDiscussionDocument,
              variables: { id: postId },
              data: {
                discussionComments: {
                  __typename: 'CommentList',
                  // need to convert comments refs to data
                  comments: opts.cache.data.data[incoming['__ref']].comments.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (c: any) => transformRefsToData(c, opts)
                  ),
                  id: opts.readField('id', incoming),
                },
              },
            })
          }

          return opts.mergeObjects(existing, incoming)
        },
      },
      publicComments: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        merge: function (existing: any, incoming: any, opts: any) {
          if (incoming && opts.cache.data.data[incoming['__ref']]) {
            const postId = opts.readField('id', incoming).split(':')[1]
            opts.cache.writeQuery({
              query: GetPublicCommentsDocument,
              variables: { id: postId },
              data: {
                publicComments: {
                  __typename: 'CommentList',
                  // need to convert comments refs to data
                  comments: opts.cache.data.data[incoming['__ref']].comments.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (c: any) => transformRefsToData(c, opts)
                  ),
                  id: opts.readField('id', incoming),
                },
              },
            })
          }

          return opts.mergeObjects(existing, incoming)
        },
      },
    },
  },
  Category: {
    keyFields: ['categoryId'],
  },
  Subcategory: {
    keyFields: ['subcategoryId'],
  },
  Release: {
    keyFields: ['releaseId'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merge: function (existing: any, incoming: any, opts: any) {
      const fields = ['title', 'categoryNames', 'sectionName', 'name', 'isRelease']

      const newId = `Release:${opts.readField('releaseId', incoming)}`

      const [oldString, newString] = makeIndexStrings(existing, incoming, fields, opts)
      updateSearchIndex(oldString, newString, newId)

      return opts.mergeObjects(existing, incoming)
    },
  },
  Section: {
    keyFields: ['sectionId'],
  },
  Hostname: {
    keyFields: ['hostnameId'],
  },
}
