import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { PostHeader } from '~/pages/posts/PostHeader'
import { PostBody } from '~/pages/posts/PostBody'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import {
  GQLMediaType,
  GQLPostType,
  useGetCommunityNameQuery,
  useGetCommunityPostQuery,
  useGetPostsQuery,
} from '~/api/generated/graphql'
import { PostEdit } from '~/pages/posts/PostEdit'
import RecommendedCommunities from '~/pages/feed/RecommendedCommunities'
import { useWindowSize } from '~/common/hooks/useWindowSize'
import '@css/pages/community/CommunityPost.scss'
import { POSTS_PAGE_SIZE } from '~/contexts/PostsContext'
import { useCommunity } from '~/contexts/CommunityContext'
import ToastComponent from '~/common/ToastComponent'
import { elementClicked } from '~/common/EventLogger'
import { useTitle } from '~/common/hooks/useTitle'
import { Maybe } from '@graphql-tools/utils'
import { getSafeLocationState, getVideoStartTime } from '~/utils'
import { usePermissions } from '~/pages/posts/PostUtils'
import { useAuth } from '~/auth/Auth'
import { Button, Modal } from 'react-bootstrap'
import { CommentLoadingMediaProvider } from '~/contexts/CommentLoadingMediaContext'
import VideoScanPopover from '~/common/VideoScanPopover'
import { useLoadObjUsers } from '~/common/hooks/useLoadObjUsers'

type CommunityPostState = {
  fromSearchPage?: boolean
  fromReleaseName?: string
  fromSummitPage?: boolean
}

interface CommunityPostProps {
  fromContentPage: boolean
  fromRepostPage?: boolean
}

const CommunityPost = ({ fromContentPage, fromRepostPage = false }: CommunityPostProps) => {
  const { state } = useLocation()
  const { authUserId, isVeevan } = useAuth()
  const { postId } = useParams<{ postId: string }>()
  // Preload the posts for the community, but don't wait on them.  Make the "Back to posts" link faster
  const { communityId } = useCommunity()
  useGetPostsQuery({
    variables: { communityId: communityId ?? '', userId: authUserId ?? '', pageSize: POSTS_PAGE_SIZE },
    skip: !communityId,
  })
  const [searchParams] = useSearchParams()

  const commentId = searchParams.get('c')
  const convertedType =
    searchParams.get('cv') === GQLPostType.Post
      ? GQLPostType.Post
      : searchParams.get('cv') === GQLPostType.Content
        ? GQLPostType.Content
        : undefined
  const navToVeevaDiscussion = isVeevan ? searchParams.get('vd') === '1' : false

  const wistiaStartTime = searchParams.get('t')

  const { fromSearchPage, fromReleaseName, fromSummitPage } = getSafeLocationState<CommunityPostState>(state) ?? {}

  return (
    <CommunityPostInner
      fromContentPage={fromContentPage}
      fromRepostPage={fromRepostPage}
      fromSearchPage={fromSearchPage ?? false}
      fromReleaseName={fromReleaseName}
      fromSummitPage={fromSummitPage}
      postId={postId}
      commentId={commentId ?? ''}
      navToVeevaDiscussion={navToVeevaDiscussion}
      convertedType={convertedType}
      videoPlayTime={wistiaStartTime ? getVideoStartTime(wistiaStartTime) : null}
    />
  )
}

interface CommunityPostInnerProps {
  fromContentPage: boolean
  fromRepostPage: boolean
  fromSearchPage: boolean
  fromReleaseName?: string
  fromSummitPage?: boolean
  postId?: string
  commentId?: string
  navToVeevaDiscussion?: boolean
  convertedType?: GQLPostType
  videoPlayTime?: Maybe<number>
}

const CommunityPostInner = ({
  fromContentPage,
  fromRepostPage,
  fromSearchPage,
  fromReleaseName,
  fromSummitPage,
  postId,
  commentId,
  navToVeevaDiscussion,
  convertedType,
  videoPlayTime,
}: CommunityPostInnerProps) => {
  const navigate = useNavigate()
  const [editingPost, setEditingPost] = useState(false)
  const [loadingRecommended, setLoadingRecommended] = useState(fromContentPage)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showPostDeletedToast, setShowPostDeletedToast] = useState(false)
  const [showConvertedToast, setShowConvertedToast] = useState(false)
  const [showEditRepostModal, setShowEditRepostModal] = useState(false)
  const handleClosePostDeletedToast = useCallback(() => setShowPostDeletedToast(false), [])
  const { data, loading: postLoading } = useGetCommunityPostQuery({ variables: { id: postId ?? '' }, skip: !postId })
  const { data: communitydata } = useGetCommunityNameQuery({
    variables: { id: data?.post?.communityId ?? '' },
    skip: !data?.post?.communityId,
  })
  const { loading: usersLoading } = useLoadObjUsers(data?.post, 'postId')

  const { isCondensed, isCondensedPortrait: isMobile } = useWindowSize()
  const { communityId, companyId, setNewActivity } = useCommunity()
  const post = data?.post
  const { canEdit, loading: permissionsLoading } = usePermissions(post?.createdById, post?.postId)
  const loading = postLoading || permissionsLoading

  const backToLink = `/${companyId ? 'companies' : 'communities'}/${companyId ?? communityId}/${
    fromContentPage ? 'content' : 'posts'
  }`

  const postListPath = `/${companyId ? 'companies' : 'communities'}/${companyId ?? communityId}/${
    post?.postType == GQLPostType.Content ? 'content' : 'posts'
  }`

  const returnToLastPage = (e: SyntheticEvent) => {
    elementClicked(e, 'click-post-single-view-back', {})
    navigate(-1)
  }
  const getText = (htmlString: Maybe<string>) => {
    if (!htmlString) return ''
    const divContainer = document.createElement('div')
    divContainer.innerHTML = htmlString
    return divContainer.textContent || divContainer.innerText || ''
  }

  const communityName = communitydata?.community?.name
  const postName = useMemo(() => getText(post?.title?.htmlWithMentions), [post?.title?.htmlWithMentions])
  useTitle(`${communityName ? `${communityName} ${postName ? ` - ${postName} - ` : ''}Veeva Connect` : ''}`)

  const handleClickEdit = (isRepost = false) => {
    if (isRepost) {
      setShowEditRepostModal(true)
    } else {
      setEditingPost(true)
    }
  }

  const handleDelete = (_: boolean, id: string) => {
    setShowPostDeletedToast(true)
    setNewActivity?.(activityObjs => {
      return [...activityObjs].filter(o => o.objId != id)
    })
  }

  useEffect(() => {
    if (searchParams.get('edit') === '1' && !loading) {
      if (post?.isRepost) {
        setShowEditRepostModal(true)
      } else {
        setEditingPost(canEdit)
      }
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams, canEdit, loading, post?.isRepost])

  useEffect(() => {
    if (convertedType !== undefined) {
      setShowConvertedToast(true)
    }
  }, [convertedType, setShowConvertedToast])

  if (!loading && !post) {
    navigate('/?show_private_toast=1')
  }

  useEffect(() => {
    if (!loading && !post) {
      navigate('/?show_private_toast=1')
    }
  }, [loading, navigate, post])

  if (loading || usersLoading) return <div>Loading ...</div>
  if (!post) return null

  return (
    <div className={`post-container ${isMobile ? 'mobile' : ''}`}>
      {!fromRepostPage && (
        <div className={`nav-simple-zone${isCondensed ? ' condensed' : ''}`}>
          {fromSearchPage ? (
            <span className={'link'} onClick={returnToLastPage}>
              &larr; Back to Search
            </span>
          ) : fromReleaseName ? (
            <span className={'link'} onClick={returnToLastPage}>
              &larr; Back to {fromReleaseName}
            </span>
          ) : fromSummitPage ? (
            <span className={'link'} onClick={returnToLastPage}>
              &larr; Back to Summit
            </span>
          ) : (
            <Link to={backToLink}>&larr; Back to {fromContentPage ? 'Content' : 'Posts'}</Link>
          )}
        </div>
      )}
      {fromContentPage && (
        <RecommendedCommunities singleContentView={true} setLoadingRecommended={setLoadingRecommended} />
      )}
      <div className={`post-list ${isMobile ? 'mobile' : ''}`}>
        {loadingRecommended ? (
          <>Loading...</>
        ) : post ? (
          editingPost ? (
            <PostEdit
              key={'editPost_' + post.postId}
              postId={post.postId}
              onDone={() => setEditingPost(false)}
              onClickDeleteDraft={() => setShowPostDeletedToast(true)}
              postListPath={postListPath}
              isSinglePost={true}
            />
          ) : (
            <div className={`post-item expanded${post.commentCount === 0 ? ' no-comments' : ''}`}>
              <PostHeader
                isExpanded={true}
                postId={post.postId}
                onDelete={handleDelete}
                onClickEdit={handleClickEdit}
                isCommPost={true}
                fromContentPage={fromContentPage}
                isContent={post.postType === GQLPostType.Content}
                isSinglePost={true}
                isRepostPage={fromRepostPage}
                showEditor={true}
              />
              <CommentLoadingMediaProvider key={post.postId}>
                <PostBody
                  postId={post.postId}
                  isSinglePost={true}
                  isRepostPage={fromRepostPage}
                  scrollToComment={commentId}
                  defaultToVeevaDiscussion={navToVeevaDiscussion}
                  onClickDeleteDraft={() => setShowPostDeletedToast(true)}
                  onClickEditDraft={() => setEditingPost(true)}
                  postListPath={postListPath}
                />
              </CommentLoadingMediaProvider>
            </div>
          )
        ) : (
          <div className={'post-item'}>
            <div className={'error type01'}>Post could not be found</div>
          </div>
        )}
      </div>
      <ToastComponent onClose={handleClosePostDeletedToast} show={showPostDeletedToast} bg={'success'}>
        {`The ${post.draft ? 'draft' : 'post'} was deleted successfully.`}
      </ToastComponent>
      <ToastComponent onClose={() => setShowConvertedToast(false)} show={showConvertedToast} bg={'success'}>
        {`${convertedType === GQLPostType.Post ? 'Content' : 'Regular'} post converted to ${
          convertedType === GQLPostType.Post ? 'regular' : 'content'
        } post`}
      </ToastComponent>
      <Modal show={showEditRepostModal} onHide={() => setShowEditRepostModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>This is a repost. To edit it, you must edit the original.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={() => setShowEditRepostModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      {post?.mediaUrl && post?.mediaType === GQLMediaType.Video && (
        <VideoScanPopover mediaUrl={post.mediaUrl} playTime={videoPlayTime} />
      )}
    </div>
  )
}

export default CommunityPost
