import { useMemo } from 'react'
import {
  ThreadUserFragmentDoc,
  useGetDiscussionCommentAuthorsQuery,
  useGetPostCreatedByQuery,
  useGetPublicCommentAuthorsQuery,
} from '~/api/generated/graphql'
import { Maybe } from '@graphql-tools/utils'
import { useApolloClient } from '@apollo/client'
import { userByNameSort } from '~/utils'

export const usePostThreadIds = (postId: Maybe<string>) => {
  const client = useApolloClient()
  const { data: postData } = useGetPostCreatedByQuery({ variables: { id: postId ?? '' }, skip: !postId })
  const { data: publicCommentsData } = useGetPublicCommentAuthorsQuery({
    variables: { id: postId ?? '' },
    skip: !postId,
  })
  const { data: discussionCommentsData } = useGetDiscussionCommentAuthorsQuery({
    variables: { id: postId ?? '' },
    skip: !postId,
  })

  return useMemo(() => {
    const ids = new Set<string>()
    if (postData) {
      ids.add(postData?.post?.createdById ?? '')
    }
    if (publicCommentsData) {
      publicCommentsData.publicComments?.comments
        ?.map(c => {
          const userId = c?.createdById
          const user = client.readFragment({
            fragment: ThreadUserFragmentDoc,
            id: client.cache.identify({ __typename: 'User', userId }),
          })
          if (user) return user
        })
        .sort(userByNameSort)
        .forEach(u => ids.add(u?.userId))
    }
    if (discussionCommentsData) {
      discussionCommentsData.discussionComments?.comments
        ?.map(c => {
          const userId = c?.createdById
          const user = client.readFragment({
            fragment: ThreadUserFragmentDoc,
            id: client.cache.identify({ __typename: 'User', userId }),
          })
          if (user) return user
        })
        .sort(userByNameSort)
        .forEach(u => ids.add(u?.userId))
    }
    return Array.from(ids).filter(Boolean)
  }, [postData, publicCommentsData, discussionCommentsData, client])
}
