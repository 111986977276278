import React from 'react'
import '@css/pages/community/CommunityTable.scss'
import '@css/pages/community/CommunityMembers.scss'
import { useAuth } from '~/auth/Auth'
import { PendingMember } from '~/types'
import { toTimeAgo } from '~/utils'

interface CommunityPendingMemberRowProps {
  member: PendingMember
  isCompany: boolean
}

const CommunityPendingMemberRow = ({ member, isCompany }: CommunityPendingMemberRowProps) => {
  const { isVeevan } = useAuth()
  const profilePhoto = {
    backgroundImage: 'url(' + member.inviter.photo + ')',
  }

  return (
    <div className="table-row pending">
      <div className="wide-column row-added-user" role="email">
        {member.email}
      </div>
      <div className="semi-wide-column row-text" role="company">
        {member.companyName}
      </div>
      {(isVeevan || isCompany) && (
        <div className="semi-wide-column row-added-by" role="added-by">
          <span className={`profile-photo profile-photo-added-by ${member.inviter.photo ? 'has-photo' : ''}`}>
            <div style={profilePhoto} />
          </span>
          <span>
            {member.inviter.fullName}, {member.inviter.company.name}
          </span>
        </div>
      )}
      <div className="semi-wide-column row-text" role="when-added">
        {toTimeAgo(member.createdTime)}
      </div>
    </div>
  )
}

export default CommunityPendingMemberRow
