import React, { useMemo } from 'react'
import { useDraftingCommentPost } from '~/contexts/DraftingCommentPostContext'
import { GQLSubscriptionInfo } from '~/api/generated/graphql'
import { useNewActivity } from '~/contexts/NewActivityContext'

export const NewActivityButton = ({
  newActivity,
  loading,
  showNewActivity,
}: {
  loading: boolean
  newActivity: GQLSubscriptionInfo[]
  showNewActivity: () => Promise<void>
}) => {
  const { hasDrafts, setShowWarning } = useDraftingCommentPost()
  const { newCommentIds } = useNewActivity()

  const doShowNewActivity = async () => {
    if (hasDrafts) {
      setShowWarning?.(true)
    } else {
      await showNewActivity?.()
    }
  }

  const showMoreActivityButton = useMemo(() => {
    // We show the button if there is new activity that is not just new comments in any expanded posts, so here we filter out any overlapping activity in expanded posts (new comments)
    const filteredActivity = [...(newActivity ?? [])].filter(
      x => x && !newCommentIds?.includes(x.objId)
    ) as GQLSubscriptionInfo[]
    return Boolean(filteredActivity.length) && !loading
  }, [newActivity, loading, newCommentIds])

  return (
    <>
      {showMoreActivityButton && (
        <button className={'new-activity-message'} onClick={doShowNewActivity}>
          Show new activity
        </button>
      )}
    </>
  )
}
