import { SizeBreakpoint, useWindowSize } from '~/common/hooks/useWindowSize'
import React, { SyntheticEvent, useMemo, useState } from 'react'
import { elementClicked } from '~/common/EventLogger'
import { Button } from 'react-bootstrap'
import AddCommunityBox from '~/pages/search/AddCommunityBox'
import AddAuthorBox from '~/pages/search/AddAuthorBox'
import AddCompanyBox from '~/pages/search/AddCompanyBox'
import { FacetResponse, SearchVars } from '~/pages/search/AdvancedSearch'
import TimeFacets, { SelectedTimeFacet } from '~/pages/search/TimeFacets'
import MoreArrow from '@web/images/community/MoreArrow'
import LessArrow from '@web/images/community/LessArrow'
import { useAuth } from '~/auth/Auth'
import { useGetAuthorFacetsQuery } from '~/api/generated/graphql'
import { debounce } from '~/utils'

export class CommunityFacet {
  communityId: string
  companyId?: string
  name: string
}

export class AuthorFacet {
  authorId: string
  name: string
}

export class CompanyFacet {
  companyId: string
  name: string
}

export const AdvancedSearchSidebar = ({
  visibleCommunityFacets,
  visibleAuthorFacets,
  visibleCompanyFacets,
  addCommunityFacet,
  removeCommunityFacet,
  addAuthorFacet,
  removeAuthorFacet,
  addCompanyFacet,
  removeCompanyFacet,
  clearFacets,
  responseCommunityFacets,
  responseCompanyFacets,
  responseTimeFacets,
  startDate,
  endDate,
  selectedTime,
  handleSelectTime,
  handleSelectStartDate,
  handleSelectEndDate,
  includeDiscussions,
  clickedDiscussions,
  searchState,
}: {
  visibleCommunityFacets: CommunityFacet[]
  visibleAuthorFacets: AuthorFacet[]
  visibleCompanyFacets: CompanyFacet[]
  addCommunityFacet: (facet: CommunityFacet) => void
  removeCommunityFacet: (facet: CommunityFacet) => void
  addAuthorFacet: (facet: AuthorFacet) => void
  removeAuthorFacet: (facet: AuthorFacet) => void
  addCompanyFacet: (facet: CompanyFacet) => void
  removeCompanyFacet: (facet: CompanyFacet) => void
  clearFacets: () => void
  responseCommunityFacets: FacetResponse[]
  responseCompanyFacets: FacetResponse[]
  responseTimeFacets: FacetResponse[]
  startDate: Date
  endDate: Date
  selectedTime: SelectedTimeFacet
  handleSelectTime: (time: SelectedTimeFacet) => void
  handleSelectStartDate: (date: Date) => void
  handleSelectEndDate: (time: Date) => void
  includeDiscussions: boolean
  clickedDiscussions: () => void
  searchState: SearchVars
}) => {
  const { breakpoint } = useWindowSize()
  const isCondensed = breakpoint <= SizeBreakpoint.md
  const { isVeevan, actingSysAdmin } = useAuth()

  const [communityText, setCommunityText] = useState<string>('')
  const [authorText, setAuthorText] = useState<string>('')
  const [debouncedAuthorText, setDebouncedAuthorText] = useState<string>('')
  const [companyText, setCompanyText] = useState<string>('')

  const [isTimeCollapsed, setIsTimeCollapsed] = useState<boolean>(true)
  const [isDiscussionCollapsed, setIsDiscussionCollapsed] = useState<boolean>(false)

  const { data: authorsData } = useGetAuthorFacetsQuery({
    variables: { ...searchState, authorQuery: debouncedAuthorText },
    skip: !debouncedAuthorText,
  })

  const debounceAuthorText = useMemo(
    () =>
      debounce((search: string) => {
        setDebouncedAuthorText(search)
      }, 300),
    [setDebouncedAuthorText]
  )

  const setAuthorTextValues = (search: string) => {
    setAuthorText(search)
    debounceAuthorText(search).then()
  }

  const authorFacets: FacetResponse[] = useMemo(
    () =>
      authorsData?.authorFacets?.authors
        ?.map(a => ({
          key: a?.key ?? '',
          count: a?.count ?? 0,
        }))
        .filter(Boolean) as FacetResponse[],
    [authorsData]
  )

  const handleRemoveCommunityFacet = (e: SyntheticEvent, facet: CommunityFacet) => {
    elementClicked(e, 'click-advanced-search-result-filter-community-remove', {
      community_id: facet.communityId,
    })
    removeCommunityFacet(facet)
  }

  const handleRemoveAuthorFacet = (e: SyntheticEvent, facet: AuthorFacet) => {
    elementClicked(e, 'click-advanced-search-result-filter-author-remove', {
      author_id: facet.authorId,
    })
    removeAuthorFacet(facet)
  }

  const handleRemoveCompanyFacet = (e: SyntheticEvent, facet: CompanyFacet) => {
    elementClicked(e, 'click-advanced-search-result-filter-company-remove', {
      company_id: facet.companyId,
    })
    removeCompanyFacet(facet)
  }

  const clickClearFacets = () => {
    setCommunityText('')
    setAuthorText('')
    setCompanyText('')
    clearFacets()
  }

  return (
    <div className={'facet-sidebar'}>
      <div className={'sidebar-header'}>
        <span className={'refine-results'}>Refine Results</span>
        <Button
          className={'clear-filters'}
          onClick={e => {
            elementClicked(e, 'click-advanced-search-result-filter-clear-all', {})
            clickClearFacets()
          }}
          data-testid={'clear-filters'}
        >
          Clear Filters
        </Button>
      </div>
      <div className={'facet-selectors'}>
        <span className={'facet-header'}>Community</span>
        {visibleCommunityFacets?.map((facet: CommunityFacet) => (
          <div key={facet.communityId} className={'selected-facet'}>
            {facet.name}
            <div
              className={'remove-facet'}
              onClick={e => {
                handleRemoveCommunityFacet(e, facet)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') handleRemoveCommunityFacet(e, facet)
              }}
              tabIndex={0}
              aria-roledescription={`remove ${facet.name}`}
              role={'button'}
            />
          </div>
        ))}
        <AddCommunityBox
          searchText={communityText}
          placeholder={'Posted in Community'}
          isCondensed={isCondensed}
          selectedCommunities={visibleCommunityFacets}
          responseFacets={responseCommunityFacets}
          onTextChange={setCommunityText}
          onAddCommunity={(f, e) => {
            elementClicked(e, 'click-advanced-search-result-filter-community-add', {
              community_id: f?.communityId,
            })
            if (f) {
              addCommunityFacet(f)
            }
          }}
        />

        <span className={'facet-header'}>Author</span>
        {visibleAuthorFacets?.map((facet: AuthorFacet) => (
          <div key={facet.authorId} className={'selected-facet'}>
            {facet.name}
            <div
              className={'remove-facet'}
              onClick={e => {
                handleRemoveAuthorFacet(e, facet)
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') handleRemoveAuthorFacet(e, facet)
              }}
              tabIndex={0}
              aria-roledescription={`remove ${facet.name}`}
              role={'button'}
            />
          </div>
        ))}
        <AddAuthorBox
          searchText={authorText}
          placeholder={"Author's Name"}
          isCondensed={isCondensed}
          selectedAuthors={visibleAuthorFacets}
          responseFacets={authorFacets}
          onTextChange={setAuthorTextValues}
          onAddAuthor={(f, e) => {
            elementClicked(e, 'click-advanced-search-result-filter-author-add', {
              author_id: f?.authorId,
            })
            if (f) {
              addAuthorFacet(f)
            }
          }}
        />
        {isVeevan && (
          <>
            <span className={'facet-header'}>Author's Company</span>
            {visibleCompanyFacets?.map((facet: CompanyFacet) => (
              <div key={facet.companyId} className={'selected-facet'}>
                {facet.name}
                <div
                  className={'remove-facet'}
                  onClick={e => {
                    handleRemoveCompanyFacet(e, facet)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') handleRemoveCompanyFacet(e, facet)
                  }}
                  tabIndex={0}
                  aria-roledescription={`remove ${facet.name}`}
                  role={'button'}
                />
              </div>
            ))}
            <AddCompanyBox
              searchText={companyText}
              placeholder={"Author's Company"}
              isCondensed={isCondensed}
              selectedCompanies={visibleCompanyFacets}
              responseFacets={responseCompanyFacets}
              onTextChange={setCompanyText}
              onAddCompany={(f, e) => {
                elementClicked(e, 'click-advanced-search-result-filter-company-add', {
                  company_id: f?.companyId,
                })
                if (f) {
                  addCompanyFacet(f)
                }
              }}
            />
            {actingSysAdmin && (
              <div className={'facet-header'} data-testid={'time-facet'}>
                <a
                  className={'collapsable-header'}
                  onClick={() => setIsTimeCollapsed(!isTimeCollapsed)}
                  tabIndex={0}
                  data-testid={'collapsable-header'}
                >
                  {isTimeCollapsed ? (
                    <span className="more">
                      <MoreArrow fill="#222222" width={'12'} height={'12'} />
                    </span>
                  ) : (
                    <span className="less">
                      <LessArrow fill="#222222" width={'12'} height={'12'} />
                    </span>
                  )}
                  <span className={'time'}>Time</span>
                </a>
                {!isTimeCollapsed && (
                  <TimeFacets
                    responseTimeFacets={responseTimeFacets}
                    startDate={startDate}
                    endDate={endDate}
                    selectedTime={selectedTime}
                    onSelectTime={handleSelectTime}
                    onSelectStartDate={handleSelectStartDate}
                    onSelectEndDate={handleSelectEndDate}
                  />
                )}
              </div>
            )}

            <div className={'facet-header'}>
              <a
                className={'collapsable-header'}
                onClick={() => setIsDiscussionCollapsed(!isDiscussionCollapsed)}
                tabIndex={0}
                data-testid={'collapsable-header'}
              >
                {isDiscussionCollapsed ? (
                  <span className="more">
                    <MoreArrow fill="#222222" width={'12'} height={'12'} />
                  </span>
                ) : (
                  <span className="less">
                    <LessArrow fill="#222222" width={'12'} height={'12'} />
                  </span>
                )}
                <span className={'discussions'}>Veeva Options</span>
              </a>
              {!isDiscussionCollapsed && (
                <div className={'checkbox-container'} data-testid={'veeva-discussions-facet'}>
                  <input
                    id={'discussion'}
                    type="checkbox"
                    checked={includeDiscussions}
                    onChange={() => {
                      clickedDiscussions()
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') clickedDiscussions()
                    }}
                    role={'input'}
                  />
                  <label htmlFor={'discussion'}>Include Veeva Discussions</label>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
