import React, { useEffect, useMemo, useState } from 'react'
import { GQLPageType, useGetReleaseLayoutQuery } from '~/api/generated/graphql'
import { useNavigate } from 'react-router'
import CategoryRow from '~/pages/page/CategoryRow'
import { Button, Col, Dropdown, Modal, Row } from 'react-bootstrap'
import { useTitle } from '~/common/hooks/useTitle'
import { useAuth } from '~/auth/Auth'
import pencilIcon from '@web/images/community/pencil-icon.svg'
import { Link } from 'react-router-dom'
import { CustomToggle } from '~/common/CustomToggle'
import { useSummitCategoryPosts } from '~/contexts/SummitCategoryPostsContext'
import PostDraftsModal, { CategoryItem, DraftPostsDisplayList } from '~/pages/page/PostDraftsModal'
import { getFullName } from '~/utils'
import { usePrompt } from '~/common/hooks/usePrompt'
import Counts from '~/pages/posts/Counts'

type ReleaseProps = {
  id: string | undefined
}

const Page = ({ id }: ReleaseProps) => {
  const { actingSysAdmin, actingRelAdmin, actingSummitAdmin, isVeevan } = useAuth()
  const { pageType, editModePostIds, hasEditModeSummitRows, setPostsWithErrors } = useSummitCategoryPosts()
  const [showDraftsModal, setShowDraftsModal] = useState(false)
  const [flag, setFlag, next] = usePrompt(hasEditModeSummitRows)

  const pageTypeNav = pageType === GQLPageType.Release ? 'Releases' : 'Summits'

  const canEditPosts = pageType === GQLPageType.Summit ? actingSummitAdmin : actingRelAdmin

  useEffect(() => {
    if (window.lastId != id) {
      window.scrollPosition = 0
    }
    document.body.children[0].scrollTo(0, window.scrollPosition ?? 0)
    window.lastId = id ?? ''
  }, [id])

  const { data, loading } = useGetReleaseLayoutQuery({
    variables: { id: id ?? '', filter: canEditPosts ? {} : { draft: false } },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  })
  const navigate = useNavigate()
  const release = data?.release
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useTitle(`${pageTypeNav} - ${data?.release?.title} - Veeva Connect`)

  const categoryOrder = useMemo(() => {
    const categoryIds = (data?.release?.categories?.edges?.map(e => e?.node?.categoryId).filter(Boolean) ??
      []) as string[]
    if (data?.release?.categoryOrder) {
      const categoryOrder = ((JSON.parse(data.release.categoryOrder) as string[]) || []).filter(id =>
        categoryIds.includes(id)
      )
      categoryIds.forEach(id => {
        if (!categoryOrder.includes(id)) {
          categoryOrder.push(id)
        }
      })
      return categoryOrder
    }
    return categoryIds
  }, [data])

  const onFinishEditing = () => {
    if (hasEditModeSummitRows && pageType === GQLPageType.Summit) {
      setPostsWithErrors?.(new Set(editModePostIds?.keys()))
    } else {
      setIsEditing(false)
    }
  }

  const onPostDrafts = () => {
    setShowDraftsModal(true)
  }

  const draftPostsToDisplay = useMemo(() => {
    if (!data || pageType === GQLPageType.Release) {
      return [] as DraftPostsDisplayList[]
    }

    const categories = new Map()

    data?.release?.categories?.edges.map(category => {
      const posts = new Map()

      category?.node?.posts?.edges.forEach(post => {
        if (!post?.node?.hidden && post?.node?.draft) {
          posts.set(post?.node?.postId, {
            postId: post?.node?.postId ?? '',
            author: getFullName(post?.node?.createdBy),
            contentTitle: post?.node?.contentTitle ?? '',
            checked: true,
            communityName: post?.node?.community?.name,
            summitTitle: post?.node?.summitTitle,
            categoryName: category?.node?.name,
          } as DraftPostsDisplayList)
        }
      })

      const orderedPosts: DraftPostsDisplayList[] = []
      const unorderedPosts: DraftPostsDisplayList[] = []

      // Organize post list into content order
      const orderedIds = (JSON.parse(category?.node?.postOrder ?? null) as string[]) ?? []

      // add posts included in the content order
      orderedIds.forEach(id => {
        const post = posts.get(id)
        if (post) orderedPosts.push(post)
      })

      // add remaining posts that aren't in the content order and append to the end of the list
      for (const [postId] of posts.entries()) {
        if (!orderedIds.includes(postId)) {
          const post = posts.get(postId)
          if (post) unorderedPosts.push(post)
        }
      }

      const categoryPosts = orderedPosts.concat(unorderedPosts)

      categories.set(category?.node?.categoryId, {
        categoryId: category?.node?.categoryId,
        name: category?.node?.name,
        posts: categoryPosts,
      } as CategoryItem)
    })
    const orderedIds = (JSON.parse(data?.release?.categoryOrder ?? null) as string[]) ?? []

    const orderedCategories: CategoryItem[] = []
    const unorderedCategories: CategoryItem[] = []

    for (let i = 0; i < orderedIds.length; i++) {
      const category = categories.get(orderedIds[i])
      if (category) orderedCategories.push(category)
    }

    // add remaining posts that aren't in the content order and append to the end of the list
    for (const [categoryId] of categories.entries()) {
      if (!orderedIds.includes(categoryId)) {
        const category = categories.get(categoryId)
        if (category) unorderedCategories.push(category)
      }
    }

    let allPosts = [] as DraftPostsDisplayList[]
    const allCategories = orderedCategories.concat(unorderedCategories)

    allCategories.forEach(category => {
      allPosts = allPosts.concat(category.posts)
    })

    return allPosts as DraftPostsDisplayList[]
  }, [data, pageType])

  useEffect(() => {
    if (flag && !hasEditModeSummitRows) {
      next()
    }
  }, [flag, hasEditModeSummitRows, next])

  const canSeePage = data?.release?.hasPublishedPosts || actingSysAdmin || canEditPosts
  useEffect(() => {
    if (!loading && (!data?.release || !canSeePage)) {
      navigate('/?show_private_toast=1')
    }
  }, [loading, data?.release, canSeePage, navigate])

  if (loading && !data) return <div className={'loading'}>Loading...</div>

  return (
    <div className={'release-column-container'}>
      {isVeevan && (
        <Row className={'page-top-header-container'}>
          <div className={'page-top-header col-sm-12 col-md-10 col-md-offset-1 offset-md-1'}>
            <Counts
              isComment={false}
              isVeevan={true}
              isReleasePage={true}
              views={release?.viewCount ?? 0}
              veevanViews={release?.veevanViewCount ?? 0}
              likes={release?.likeCount ?? 0}
              veevanLikes={release?.veevanLikeCount ?? 0}
              comments={release?.commentCount ?? 0}
              veevanComments={release?.veevanCommentCount ?? 0}
              postViewCount={release?.postViewCount ?? 0}
              veevanPostViewCount={release?.veevanPostViewCount ?? 0}
            />
          </div>
        </Row>
      )}
      {(actingSysAdmin || canEditPosts) && (
        <Row className={'admin-controls-container'}>
          <div className={'admin-controls col-sm-12 col-md-10 col-md-offset-1 offset-md-1'}>
            <div>Status: {release?.draft ? 'Draft' : 'Published'}</div>
            {isEditing ? (
              <div>
                <Button onClick={onFinishEditing}>Done</Button>
              </div>
            ) : (
              <Dropdown align={'end'} id={'edit-page-page'}>
                <Dropdown.Toggle as={CustomToggle}>
                  <img alt={'edit'} src={pencilIcon} data-testid={'edit-pencil'} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {actingSysAdmin && (
                    <Dropdown.Item as={Link} to={`/${pageTypeNav.toLowerCase()}/${release?.releaseId}/edit`}>
                      Edit {pageType === GQLPageType.Release ? 'Release' : 'Summit'} Page
                    </Dropdown.Item>
                  )}
                  {canEditPosts && <Dropdown.Item onClick={() => setIsEditing(true)}>Edit Posts</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </Row>
      )}
      <Row className={'title-container-row'}>
        <Col>
          <h1 className={`pt-2 ${release?.overview?.html ? 'pb-1' : 'pb-4'}`}>{release?.title}</h1>
        </Col>
      </Row>
      <div className={'overview-container col-sm-12 col-md-10 col-md-offset-1 col'}>
        {release?.overview?.html ? (
          <div className={'quill-editor-elements'} dangerouslySetInnerHTML={{ __html: release.overview.html }} />
        ) : (
          <></>
        )}
      </div>
      {isEditing && pageType === GQLPageType.Summit && (
        <Row className={'post-drafts-button'}>
          <div className={'offset-md-1 col-md-10 col-md-offset-1 col'}>
            <Button onClick={onPostDrafts} disabled={draftPostsToDisplay.length === 0}>
              Post Drafts
            </Button>
          </div>
        </Row>
      )}
      <Row className={'categories-info-container'}>
        <div className={'categories-info col-sm-12 col-md-10 col-md-offset-1 offset-md-1 col'}>
          {categoryOrder.map(cId => (
            <CategoryRow
              key={cId}
              categoryId={cId}
              releaseId={id ?? ''}
              releaseName={release?.title ?? ''}
              isEditing={isEditing}
            />
          ))}
        </div>
      </Row>
      <Modal
        show={flag && hasEditModeSummitRows}
        onHide={() => {
          setFlag(false)
        }}
      >
        <Modal.Body>You have unsaved changes.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              setFlag(false)
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      {pageType === GQLPageType.Summit && (
        <PostDraftsModal
          posts={draftPostsToDisplay}
          releaseId={id ?? ''}
          showModal={showDraftsModal}
          setShowModal={setShowDraftsModal}
        />
      )}
    </div>
  )
}

export default Page
