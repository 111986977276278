import { asCommunity, getNormalizedTokens } from '~/utils'
import { GetSuggestionCommunityDocument, GQLCommunity, GQLGetSuggestionCommunityQuery } from '~/api/generated/graphql'
import { Suggestion } from '~/common/quill/QuillEditor'
import { ApolloCache } from '@apollo/client'

const NUM_SUGGESTIONS = 7

const CommunitySuggestionService = {
  getSuggestions: (
    search: string,
    cache: ApolloCache<object>,
    excludeIds?: Set<string>,
    showExcluded?: boolean
  ): Suggestion[] => {
    const prefixCache = window.prefixCache
    const names = getNormalizedTokens(search)
    const resultDict = new Map<string, number>()
    names.forEach(tok => {
      if (prefixCache?.has(tok)) {
        prefixCache?.get(tok)?.forEach((match: string) => {
          resultDict.set(match, (resultDict.get(match) || 0) + 1)
        })
      }
    })
    const filteredResults = Array.from(resultDict.keys())
      .filter(k => resultDict.get(k) === names.length)
      .filter(k => k.startsWith('Community'))
    const result: GQLGetSuggestionCommunityQuery['community'][] = []
    filteredResults.map(k => {
      const communityId = k.split(':')[1]
      result.push(
        cache.readQuery<GQLGetSuggestionCommunityQuery>({
          query: GetSuggestionCommunityDocument,
          variables: { communityId: communityId },
        })?.community
      )
    })

    let matchedCommunities: Suggestion[] = result
      ?.filter(c => c)
      .map((c: GQLCommunity) => {
        return {
          id: c.communityId ?? '',
          community: asCommunity(c),
          value: c.name,
          link: `/${c.companyId ? 'companies' : 'communities'}}/${c.communityId}`,
          photo: c.photo ?? undefined,
        }
      })

    if (excludeIds) {
      const matchedCommunitiesWithoutExcluded = matchedCommunities.filter(
        c => !excludeIds.has(c?.community?.communityId || '')
      )
      if (matchedCommunitiesWithoutExcluded.length >= NUM_SUGGESTIONS || !showExcluded) {
        matchedCommunities = matchedCommunitiesWithoutExcluded
      }
    }

    return matchedCommunities.slice(0, NUM_SUGGESTIONS)
  },
}

export default CommunitySuggestionService
